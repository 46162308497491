import { useState } from 'react';

 
const useRemoveNote = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [message, setMessage] = useState(null);

  const removeNote = async (notes, index, productId) => {
    setIsLoading(true);

    try {
      const noteToRemove = notes[index];

      const baseUrl = process.env.REACT_APP_BASE_URL
        ? process.env.REACT_APP_BASE_URL
        : 'http://localhost:8000/';

      const response = await fetch(
        `${baseUrl}/shopify-api/products/metafield/${productId}/update/`,
        {
          method: 'DELETE',
          headers: {
            'Content-Type': 'application/json',
          },
          credentials: 'include',  // Include this line
          body: JSON.stringify({ target: noteToRemove }),
        }
      );

      if (!response.ok) {
        throw new Error('Error removing note.');
      }

      setMessage("Borttagning av råvara lyckades!");
      setError(null);
    } catch (error) {
      setMessage("Borttagning av råvara misslyckades!");
      setError('An error occurred while removing the note.');
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  return { removeNote, isLoading, error, message };
};

export default useRemoveNote;
