import { styled } from '@mui/system';

export const Root = styled('div')({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
});

export const TagCatering = styled('div')({
  backgroundColor: 'red',
});

export const TagSystemet = styled('div')({
  backgroundColor: 'blue',
});

export const Container = styled('div')({
    padding: 35,
    width: '55vw',
    textAlign: 'center',
    boxShadow: '0px 1px 5px 0px rgba(0,0,0,0.20)',
    borderRadius: 10,
    marginTop: '5vh',
  });

export const InputField = styled('input')({
  marginBottom: 2, // adjust as needed
  width: '100%',
});

export const Quill = styled('div')({
    height: '1000px', // Increased the height
    marginBottom: 2, // adjust as needed
  });
  

  export const Title = styled('div')({
    fontSize: '2.1em',
    fontWeight: '700',
    letterSpacing: '-0.6px',
    marginTop: '1em', // Increase this value to create more margin under the title
    color: '#C00020',
  });

export const SubmitButton = styled('button')({
  marginTop: 2, // adjust as needed
  backgroundColor: '#C00020',
  color: 'white',
  border: 'none',
  borderRadius: '4px',
  padding: '10px 20px',
  cursor: 'pointer',
  fontWeight: "500",
  textTransform: "none",
});

export const Dropzone = styled('div')(({ theme }) => ({
  borderWidth: 2,
  borderColor: theme.palette.primary.main,
  borderStyle: 'dashed',
  borderRadius: theme.shape.borderRadius,
  padding: theme.spacing(2),
  marginTop: theme.spacing(2),
  textAlign: 'center',
  cursor: 'pointer',
}));
