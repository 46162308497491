import React, { useState } from "react";
import { useParams } from "react-router-dom";
import useSinglePost from "../../../Hooks/Posts/useSinglePost";
import './Style/SinglePostPage.css'; // Importing CSS module
import { useNavigate } from 'react-router-dom';
import ArrowBackIos from '@mui/icons-material/ArrowBackIos'
import DeleteIcon from '@mui/icons-material/Delete';
import useDeletePost from "Hooks/Posts/useDeletePost";
import LoadingComponent from "components/LoadingComponent";
import ErrorComponent from "components/ErrorComponent";
import CustomSnackbar from "components/SnackBar";
import { 
  Box, 
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider, 
} from "@mui/material";

const SinglePostPage = () => {
  const { postId } = useParams();
  const { post, isLoading, isError } = useSinglePost(postId);
  const navigate = useNavigate();
  const { deletePost, isDelLoading, isDelError } = useDeletePost();
  const [open, setOpen] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  
  const handleOpenDialog = () => {
    setOpenDialog(true);
  };

  const handleSnackbarClose = () => {
      setOpen(false);
  };

  const handleDelete = async () => {
    // Call API to delete post
    try {
      await deletePost(postId)
      // Navigate back to the posts list
      setOpen(true);
      navigate('/posts');
    } catch (isDelError) {
      alert("något gick fel att radera inlägg")
    }
  };
  
  
  if (isLoading || isDelLoading) {
    return <LoadingComponent />;
  }
  
  const isErrorAny = isError || isDelError;
  const severity = isErrorAny ? "error" : "success"
  
  if (isErrorAny || !post) {
    return <ErrorComponent error={isErrorAny}/>; // Styled h2
  }
  
  return (
    <Box className="container">
      <Box>
        <Box style={{ marginBottom: "20px" }}>
        <Button
          style={{
            textTransform: "none",
          }}
          variant="outlined"
          onClick={() => {
            if (typeof navigate === "function") {
              navigate(-1);
            } else {
              navigate("/posts");
            }
          }}
          startIcon={ <ArrowBackIos/>}
        >
            Gå tillbaka
        </Button>
        </Box>
  
        <Box className="paperContainer">
          <p className="subtitle">
          {post.author.first_name || ""} {post.author.last_name ? " " + post.author.last_name : ""}
          </p>
  
          <h1 className="title">{post.title}</h1>
  
          <p
            className="body"
            dangerouslySetInnerHTML={{ __html: post.content }}
          />
  
          {post.user_owns_post && (
            <Box className="deleteButtonContainer">
              <Button 
                variant="contained"
                className="deleteButton" 
                style={{
                  alignItems: "center",
                  display: "flex",
                  textTransform: "none",
                  backgroundColor: "#E00501",
                  fontWeight: "bold",
                }} 
                onClick={handleOpenDialog}
                endIcon={<DeleteIcon style={{ marginLeft: "5px" }} />}>
                Radera meddelande
              </Button>
            </Box>
          )}
        </Box>
      </Box>
      <CustomSnackbar
        open={open}
        handleClose={handleSnackbarClose}
        message={!isErrorAny ? "Radering lyckades!" : "Raderingen misslyckades!"}
        severity={severity}
       />
<Dialog
  open={openDialog}
  onClose={() => setOpenDialog(false)}
  sx={{ '& .MuiDialog-paper': { minWidth: '400px', padding: "10px" } }}
>
  <DialogTitle sx={{ fontSize: "20px" }}>{<strong>Ta bort inlägg?</strong>}</DialogTitle>
  <Divider />
  <DialogContent>
    <DialogContentText sx={{ color: "#393939", fontSize: "16px"}}>
      Detta kommer att radera <strong>{post.title}</strong>.
    </DialogContentText>
  </DialogContent>
  <DialogActions>
    <Button 
      variant="outlined"
      onClick={() => setOpenDialog(false)} 
      color="primary"
      autoFocus
      style={{
        fontWeight: "bold",
        textTransform: "none",
      }}
      >
      Avbryt
    </Button>
    <Button
      variant="contained" 
      onClick={() => {
        handleDelete();
        setOpenDialog(false);
      }} 
      color="primary" 
      style={{
        fontWeight: "bold",
        textTransform: "none",
        backgroundColor: "#E00601",
      }}
    >
      Ta bort
    </Button>
  </DialogActions>
</Dialog>
</Box>
  );  
}  

export default SinglePostPage;
