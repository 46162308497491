import { useQuery } from "react-query";


const fetchSinglePost = async (postId) => {
  const baseUrl = process.env.REACT_APP_BASE_URL
    ? process.env.REACT_APP_BASE_URL
    : "http://localhost:8000/";

  
  const response = await fetch(`${baseUrl}/store/posts/${postId}`, {
    headers: {
      'Content-Type':'application/json',
    },
    credentials: 'include',  // Include this line

  });

  if (!response.ok) {
    // Print the entire response body as text
    const responseText = await response.text();
    console.error(
      `HTTP error! status: ${response.status}, body: ${responseText}`
    );
    throw new Error(`HTTP error! status: ${response.status}`);
  }

  const data = await response.json();

  return data;
};

const useSinglePost = (postId) => {
  // We only call fetchSinglePost if postId is available
  const {
    data: post,
    isLoading,
    isError,
  } = useQuery(["post", postId], () => fetchSinglePost(postId), {
    enabled: !!postId,
  });

  return { post, isLoading, isError };
};

export default useSinglePost;
