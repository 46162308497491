import React from "react";
import { styled } from "@mui/system";
import { TableCell, TableHead, TableRow, Checkbox } from "@mui/material";
export class OrdersTableHeader extends React.Component {
  render() {
    const { selectAll, handleSelectAll, theme } = this.props;

    const StyledTableHeader = styled(TableHead)(({ theme }) => ({
      backgroundColor: theme.palette.primary.main,
    }));

    const StyledTableHeaderCell = styled(TableCell)(({ theme }) => ({
      color: "white",
      fontWeight: "bold",
      fontSize: "0.9rem",
    }));

    return (
      <StyledTableHeader>
        <TableRow sx={{ backgroundColor: theme.palette.green.main }}>
          <StyledTableHeaderCell sx={{ cursor: "pointer", width: "5%" }}>
            <Checkbox
              color="primary"
              checked={selectAll}
              onChange={handleSelectAll}
              sx={{
                color: "white", // border color
                "&.Mui-checked": {
                  color: "white", // Fill color when checked
                },
              }}
            />
          </StyledTableHeaderCell>

          <StyledTableHeaderCell sx={{ width: "12.5%" }}>
            Namn
          </StyledTableHeaderCell>

          <StyledTableHeaderCell
            // onClick={() => handleRequestSort("note_attributes")}
            sx={{ width: "12.5%" }}
          >
            Leverans
          </StyledTableHeaderCell>

          <StyledTableHeaderCell sx={{ width: "10%" }}>
            Pris
          </StyledTableHeaderCell>

          <StyledTableHeaderCell sx={{ width: "12.5%" }}>
            Finansiell status
          </StyledTableHeaderCell>

          <StyledTableHeaderCell sx={{ width: "12.5%" }}>
            Beställning
          </StyledTableHeaderCell>
          {/* Payment status hidden for now */}
          {/* <StyledTableHeaderCell>Betald</StyledTableHeaderCell> */}

          <StyledTableHeaderCell sx={{ width: "12.5%" }}>
            Anteckning
          </StyledTableHeaderCell>

          <StyledTableHeaderCell sx={{ width: "12.5%" }}>
            Status
          </StyledTableHeaderCell>

          <StyledTableHeaderCell sx={{ width: "8%" }}>
            Visa mer
          </StyledTableHeaderCell>
        </TableRow>
      </StyledTableHeader>
    );
  }
}
