import React, { useState, useEffect, useContext } from "react";
import { useNavigate, useParams } from "react-router-dom";

import {
  Typography,
  Button,
  Box,
  Grid,
  Paper,
  TextField,
  Select,
  MenuItem,
  InputLabel,
} from "@mui/material";

// icons and theme
import { useTheme } from "@mui/material/styles";
import ArrowBackIos from "@mui/icons-material/ArrowBackIos";
import GetAppIcon from "@mui/icons-material/GetApp";


// components and utilities
import translations from "translations.js";

import { OrderDetailsGrid } from "./components/OrderDetailsGrid.jsx";
import { OrderDetailsTable } from "./components/OrderDetailsTable.jsx";
import CustomSnackbar from "components/SnackBar.jsx";
import GeneratePDF from "../../../utilities/generatePdf.js";
import LoadingComponent from "components/LoadingComponent.jsx";
import ErrorComponent from "components/ErrorComponent.jsx";
// functions
import { getStatusFromTags, updateTag, getBookedFromTags } from "utilities/getStatusFromTags.js";

import GetOrder from "../../../Hooks/Catering/GetOrder.js";
import useUpdateOrder from "Hooks/Catering/useUpdateOrder.js";

import AuthContext from 'context/AuthContext';



function formatDate(dateString) {
  const date = new Date(dateString);
  const options = {
    year: "numeric",
    month: "long",
    day: "numeric",
    hour: "2-digit",
    minute: "2-digit",
  };
  return date.toLocaleString("sv-SE", options);
}


function ReadOnlyCateringOrder() {
  const theme = useTheme();
  const navigate = useNavigate();
  
  // GET ID FROM PARAMS
  const { id } = useParams();

  // HOOKS
  const { order, isLoading, isError } = GetOrder(id);
  console.log("🚀 ~ file: index.jsx:63 ~ ReadOnlyCateringOrder ~ order:", order)
  
  const { updateOrder, open, setOpen } = useUpdateOrder();
  
  // HOOK FOR PDF, STORE.NAME IS USED
  // const { data: store, isLoading: isStoreLoading, isError: isStoreError } = useGetMyStoreQuery();

  // TAGS
  const [tag, setTag] = useState("Mottagen");
  const [tagEdited, setTagEdited] = useState(false);

  // BOOKED
  const [booked, setBooked] = useState(false);

  // NOTES
  const [note, setNote] = useState("");
  const [noteEdited, setNoteEdited] = useState(false);

  const [popoverAnchorEl, setPopoverAnchorEl] = useState(null);


  let {user} = useContext(AuthContext)

  
  const handlePopoverOpen = (event) => {
    setPopoverAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setPopoverAnchorEl(null);
  };

  const isPopoverOpen = Boolean(popoverAnchorEl);


  const handleSnackbarClose = () => {
    setOpen(false);
  };  

  const handleSave = async () => {
    const updateData = {};
    if (noteEdited) updateData.note = note;
  
    // Construct the updated tags string
    const tagsArray = (order.tags || "").split(", "); // Change from const to let
    
    // UPDATE ORDER STATUS
    const statusIndex = tagsArray.findIndex((tag) => tag.startsWith("status:"));
    updateTag(tagsArray, tag, statusIndex, "status");

    // UPDATE BOOKED STATUS
    const bookedIndex = tagsArray.findIndex((tag) => tag.startsWith("booked:"));
    updateTag(tagsArray, booked, bookedIndex, "booked");
  
  
    const updatedByIndex = tagsArray.findIndex((tag) => tag.startsWith("updated_by:"));
    updateTag(tagsArray, `${user.firstName} ${user.lastName}`, updatedByIndex, "updated_by");
  
    const newTags = tagsArray.join(", ");
  
    if (tagEdited) updateData.tags = newTags;

    try {
      await updateOrder(id, updateData);
      setNoteEdited(false);
      setTagEdited(false);
    } catch (error) {
      console.error("Error updating order: ", error);
    }
  };
  

  useEffect(() => {
    if (order) {
      // Split the tags into an array
      const statusValue = getStatusFromTags(order.tags)
      setTag(statusValue);

      const bookedValue = getBookedFromTags(order.tags);
      setBooked(bookedValue);

      setNote(order.note || "");
    }
  }, [order]);


  const handleTagChange = async (event) => {
    let tagsArray = (order.tags || "").split(", "); // Handle if tags are empty
    const newStatus = event.target.value;
    const statusIndex = tagsArray.findIndex((tag) => tag.startsWith("status:"));
  
    if (statusIndex === -1 && newStatus) {
      tagsArray.push(`status: ${newStatus}`); // Append if no status tag is present
    } else {
      updateTag(tagsArray, newStatus, statusIndex,"status");
    }
  
    // Combine the tags back into a string
    const newTag = tagsArray.join(", ");
    setTag(newStatus);
    setTagEdited(newTag !== order.tags);
  };
  

  const handleBookedChange = async (event) => {
    const tagsArray = order.tags.split(", ");
    const isBooked = event.target.checked;
    const bookedIndex = tagsArray.findIndex((tag) => tag.startsWith("booked:"));

    updateTag(tagsArray, isBooked, bookedIndex, "booked")

    const newTag = tagsArray.join(", ");
    setBooked(isBooked);
    // setTagEdited(newTag !== order.tags);

    // Update the order automatically
    const updateData = { tags: newTag };
    try {
      await updateOrder(id, updateData);
    } catch (error) {
      console.error("Error updating order: ", error);
    }
  };

  const handleNoteChange = (event) => {
    const newNote = event.target.value;
    setNote(newNote);
    setNoteEdited(newNote.trim() !== (order.note || "").trim());
  };

  const getAllVendors = (order) => {
    let vendors = new Set(); // Using a Set to avoid duplicates
    // Checking if the order has line items
    if (order.hasOwnProperty("lineItems")) {
      // Iterating through each line item
      for (let item of order.lineItems) {
        // Checking if the line item has a vendor
        if (item.hasOwnProperty("vendor")) {
          vendors.add(item.vendor + ", ");
        }
      }
    }

    // Converting Set back to Array and returning
    return Array.from(vendors);
  };


  if (isLoading) {
    return <LoadingComponent />;
  }

  if (isError) return <ErrorComponent error={isError} />;


  return (
    <Box m={theme.spacing(3)}>
      <Grid item style={{ marginBottom: theme.spacing(2) }}>
        <Button
          variant="outlined"
          color="primary"
          startIcon={<ArrowBackIos />}
          sx={{
            textTransform: "none"
          }}
          onClick={() => {
            if (typeof navigate === "function") {
              navigate(-1);
            } else {
              navigate("/catering");
            }
          }}
        >
          Gå tillbaka
        </Button>
      </Grid>

      {order && (
        <Paper elevation={3} style={{ padding: "1.5rem" }} id="order-details">
          <Grid
            container
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            style={{ marginBottom: "20px" }}
          >
            <Grid item>
              <Typography
                variant="h3"
                gutterBottom
                style={{
                  fontWeight: "600",
                  fontSize: theme.typography.pxToRem(24),
                }}
              >
                Se hela ordern för id:{" "}
                <span style={{ fontWeight: "700" }}>{order.id}</span>
              </Typography>
            </Grid>

            <Grid item>
              <Button
                variant="outlined"
                color="primary"
                startIcon={<GetAppIcon />}
                onClick={() => GeneratePDF(order.id, order, user.shopName ? user.shopName : "Butiken kunde inte hittas!")} // add parameters that feels necessary
              >
                Ladda ner PDF
              </Button>
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid item xs={12}>

              <OrderDetailsGrid
                order={order}
                theme={theme}
                formatDate={formatDate}
                translations={translations}
                booked={booked}
                handleBookedChange={handleBookedChange}
                getAllVendors={getAllVendors}
              />

              <OrderDetailsTable
                order={order}
                theme={theme}
                handlePopoverOpen={handlePopoverOpen}
                handlePopoverClose={handlePopoverClose}
                isPopoverOpen={isPopoverOpen}
                popoverAnchorEl={popoverAnchorEl}
              />

              {/* USER THAT LAST UPDATED THE ORDER */}
              {/* <Typography style={{ paddingBottom: "1rem", paddingTop: "1rem" }}>
                {order && order.tags ? getUpdatedBy(order, order.tags) : null}
              </Typography> */}

              <Grid item xs={10}>
                <TextField
                  label="Anteckningar"
                  multiline
                  rows={4}
                  value={note}
                  onChange={handleNoteChange}
                  variant="outlined"
                  fullWidth
                  autoComplete="off"
                />
                <Grid
                  item
                  xs={10}
                >
                  <InputLabel id="order-tag-label">Order Status</InputLabel>
                  <Select
                    labelId="order-tag-label"
                    id="order-tag-select"
                    value={tag}
                    onChange={handleTagChange}
                    fullWidth
                    component={"div"}
                  >
                    <MenuItem value="" disabled>
                      <em>Välj status</em>
                    </MenuItem>
                    <MenuItem value="Mottagen">Mottagen</MenuItem>
                    <MenuItem value="Accepterad">Accepterad</MenuItem>
                    <MenuItem value="Färdig">Färdig</MenuItem>
                    <MenuItem value="Levererad">Levererad</MenuItem>

                    {/* Add more MenuItem components for other tags here */}
                  </Select>
                </Grid>

                {noteEdited || tagEdited ? (
                  <Grid item xs={12} style={{ paddingTop: "1rem" }}>
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={handleSave}
                      style={{
                        fontWeight: "bold",
                        textTransform: "none",
                      }}
                    >
                      Spara
                    </Button>
                  </Grid>
                ) : null}
              </Grid>
            </Grid>
          </Grid>
        </Paper>
      )}

      <CustomSnackbar
        open={open}
        handleClose={handleSnackbarClose}
        message="Uppdatering av order lyckades!"
        severity="success"
      />
    </Box>
  );
}

export default ReadOnlyCateringOrder;