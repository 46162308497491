import React from "react";
import { Box, Typography, Paper } from "@mui/material";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
} from "recharts";
import { Scatter, ReferenceDot } from "recharts";

const OrderChart = ({ theme, ordersPerDay }) => (
  <Box sx={{height: "100%" }}>
    <Paper
      sx={{
        flexGrow: 1,
        color: theme.palette.textColor.main,
        backgroundColor: theme.palette.background.third,
        borderRadius: 0,
        boxShadow: "0px 0px 20px rgba(0, 0, 0, 0.0)",
        p: 3,
        overflow: "hidden",
      }}
    >
      <Typography
        variant="h4"
        gutterBottom
        sx={{
          color: theme.palette.textColor.main,
          fontWeight: "700",
        }}
      >
        Leveranser kommande vecka
      </Typography>

      <Typography variant="subtitle1">
        Se antalet leveranser kommande vecka
      </Typography>
      <ResponsiveContainer width="100%" height={334}>
        <LineChart
          data={ordersPerDay}
          margin={{
            top: 15,
            right: 0,
            left: -40,
            bottom: 0,
          }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="dayStart" />
          <YAxis 
            domain={[0, "dataMax"]} 
            allowDecimals={false}  
          />
          <Tooltip
            formatter={(value, name, props) => {
              if (name === "numOrders") {
                name = "Beställningar";
              }
              return [Math.round(value), name];
            }}
          />
          <Line
            strokeWidth={1.7}
            type="monotone"
            dataKey="numOrders"
            stroke={theme.palette.green.light}
            activeDot={{ r: 8 }}
          />
          <Scatter dataKey="numOrders" fill="#8884d8">
            {ordersPerDay.map((entry, index) => (
              <ReferenceDot
                key={entry.dayStart}
                x={entry.dayStart}
                y={entry.numOrders}
                r={4}
                stroke="#8884d8"
                fill="#8884d8"
              />
            ))}
          </Scatter>
        </LineChart>
      </ResponsiveContainer>
    </Paper>
  </Box>
);

export default OrderChart;
