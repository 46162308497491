// authActions.js

export const SET_AUTHENTICATED = 'SET_AUTHENTICATED';
export const SET_USER = 'SET_USER';
export const SET_LOADING = 'SET_LOADING';
export const CHECK_AUTHENTICATION = 'CHECK_AUTHENTICATION';


export const setAuthenticated = (isAuthenticated) => ({
    type: SET_AUTHENTICATED,
    payload: isAuthenticated
});

export const setUser = (user) => ({
    type: SET_USER,
    payload: user
});

export const setLoading = (loading) => ({
    type: SET_LOADING,
    payload: loading
});


export const checkAuthentication = () => ({
    type: CHECK_AUTHENTICATION,
});

