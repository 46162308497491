import React, { useState, useEffect, useContext } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { isMobileOnly } from "react-device-detect";
import GeneratePDF from "../../../utilities/generatePdf.js";
import GetOrder from "../../../Hooks/Catering/GetOrder.js";

import useUpdateOrder from "Hooks/Catering/useUpdateOrder.js";
import {
  Typography,
  Button,
  Box,
  Grid,
  Paper,
  TextField,
  Select,
  MenuItem,
  InputLabel,
} from "@mui/material";

import OrderDetailsGrid from "./components/OrderDetailsGrid.jsx";
import OrderDetailsTable from "./components/OrderDetailsTable.jsx";
import {
  getStatusFromTags,
  updateTag,
  getBookedFromTags,
} from "utilities/getStatusFromTags.js";
import { useTheme } from "@mui/material/styles";
import translations from "translations.js";
import ArrowBackIos from "@mui/icons-material/ArrowBackIos";
import CustomSnackbar from "components/SnackBar.jsx";
import GetAppIcon from "@mui/icons-material/GetApp";
import AuthContext from "context/AuthContext.js";
import LoadingComponent from "components/LoadingComponent.jsx";
import "leaflet/dist/leaflet.css"; // Import Leaflet CSS
import ErrorComponent from "components/ErrorComponent.jsx";

// pdfMake.vfs = pdfFonts.pdfMake.vfs;

function formatDate(dateString) {
  const date = new Date(dateString);
  const options = {
    year: "numeric",
    month: "long",
    day: "numeric",
    hour: "2-digit",
    minute: "2-digit",
  };
  return date.toLocaleString("sv-SE", options);
}

function ReadOnlyCateringOrder() {
  const theme = useTheme();
  const { id } = useParams();
  const navigate = useNavigate();
  const { order, isLoading, isError } = GetOrder(id);
  const [tag, setTag] = useState("Mottagen");
  const [tagEdited, setTagEdited] = useState(false);
  const [note, setNote] = useState("");
  const [noteEdited, setNoteEdited] = useState(false);
  const { updateOrder, open, setOpen } = useUpdateOrder();

  const [popoverAnchorEl, setPopoverAnchorEl] = useState(null);

  const [booked, setBooked] = useState(false);

  let { user } = useContext(AuthContext);

  const handlePopoverOpen = (event) => {
    setPopoverAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setPopoverAnchorEl(null);
  };

  const isPopoverOpen = Boolean(popoverAnchorEl);

  const handleClose = () => {
    setOpen(false);
  };

  const handleSave = async () => {
    const updateData = {};
    if (noteEdited) updateData.note = note;

    // Construct the updated tags string
    const tagsArray = (order.tags || "").split(", "); // Change from const to let

    // UPDATE ORDER STATUS
    const statusIndex = tagsArray.findIndex((tag) => tag.startsWith("status:"));
    updateTag(tagsArray, tag, statusIndex, "status");

    // UPDATE BOOKED STATUS
    const bookedIndex = tagsArray.findIndex((tag) => tag.startsWith("booked:"));
    updateTag(tagsArray, booked, bookedIndex, "booked");

    const updatedByIndex = tagsArray.findIndex((tag) =>
      tag.startsWith("updated_by:")
    );
    updateTag(
      tagsArray,
      `${user.firstName} ${user.lastName}`,
      updatedByIndex,
      "updated_by"
    );

    const newTags = tagsArray.join(", ");

    if (tagEdited) updateData.tags = newTags;

    try {
      await updateOrder(id, updateData);
      setNoteEdited(false);
      setTagEdited(false);
    } catch (error) {
      console.error("Error updating order: ", error);
    }
  };

  useEffect(() => {
    if (order) {
      // Split the tags into an array
      const statusValue = getStatusFromTags(order.tags);
      setTag(statusValue);

      const bookedValue = getBookedFromTags(order.tags);
      setBooked(bookedValue);

      setNote(order.note || "");
    }
  }, [order]);

  const handleTagChange = async (event) => {
    let tagsArray = (order.tags || "").split(", "); // Handle if tags are empty
    const newStatus = event.target.value;
    const statusIndex = tagsArray.findIndex((tag) => tag.startsWith("status:"));

    if (statusIndex === -1 && newStatus) {
      tagsArray.push(`status: ${newStatus}`); // Append if no status tag is present
    } else {
      updateTag(tagsArray, newStatus, statusIndex, "status");
    }

    // Combine the tags back into a string
    const newTag = tagsArray.join(", ");
    setTag(newStatus);
    setTagEdited(newTag !== order.tags);
  };

  const handleBookedChange = async (event) => {
    const isBooked = event.target.checked;
    setBooked(isBooked);

    // Find the index of the "booked" note
    const bookedIndex = order.note_attributes?.findIndex(
      (note) => note.name === "booked"
    );
    let newNotes = [...order.note_attributes];
    if (bookedIndex > -1) {
      // If the "booked" note is found, replace it
      newNotes[bookedIndex] = {
        name: "booked",
        value: isBooked ? "True" : "False",
      };
    } else {
      // If the "booked" note is not found, add it
      newNotes.push({ name: "booked", value: isBooked ? "True" : "False" });
    }

    // Update the order notes
    const updateData = { note_attributes: newNotes };
    await updateOrder(order.id, updateData);
  };

  const handleNoteChange = (event) => {
    const newNote = event.target.value;
    setNote(newNote);
    setNoteEdited(newNote.trim() !== (order.note || "").trim());
  };

  const handleTag = (e) => {
    handleTagChange(e);
    handleSave();
  }

  const handleBooked = (e) => {
    handleBookedChange(e);
    handleSave();
  }
  if (isLoading) {
    return <LoadingComponent />;
  }
  if(isError) {
    return <ErrorComponent />;
  }

  return (
    <Box m={theme.spacing(1)}>
      <Grid item style={{ marginBottom: theme.spacing(2) }}>
        <Button
          variant="outlined"
          color="primary"
          startIcon={<ArrowBackIos />}
          onClick={() => {
            if (typeof navigate === "function") {
              navigate(-1);
            } else {
              navigate("/catering");
            }
          }}
          sx={{
            textTransform: "none"
          }}
        >
          Gå tillbaka
        </Button>
      </Grid>

      {order && (
        <Paper elevation={3} style={{ padding: "1.5rem" }} id="order-details">
          <Grid
            container
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            style={{ marginBottom: "20px" }}
          >
            <Grid item>
              <Typography
                variant="h3"
                gutterBottom
                style={{
                  fontWeight: "600",
                  fontSize: theme.typography.pxToRem(24),
                }}
              >
                Se hela ordern för id:{" "}
                <span style={{ fontWeight: "700" }}>{order.id}</span>
              </Typography>
            </Grid>

            <Grid item>
              <Button
                variant="outlined"
                color="primary"
                startIcon={<GetAppIcon />}
                onClick={() => GeneratePDF(order.id, order, localStorage.getItem("shopName"))} // add parameters that feels necessary
              >
                Ladda ner PDF
              </Button>
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <OrderDetailsGrid
                order={order}
                translations={translations}
                booked={booked}
                handleBookedChange={handleBooked}
                theme={theme}
                formatDate={formatDate}
              />
              <OrderDetailsTable
                theme={theme}
                order={order}
                handlePopoverClose={handlePopoverClose}
                isPopoverOpen={isPopoverOpen}
                popoverAnchorEl={popoverAnchorEl}
                handlePopoverOpen={handlePopoverOpen}
              />

              {/* <Typography style={{ paddingBottom: "1rem", paddingTop: "1rem" }}>
                {order && order.tags ? getUpdatedBy(order, order.tags) : null}
              </Typography> */}
              <Grid item xs={12}>
                <TextField
                  label="Anteckningar"
                  multiline
                  rows={4}
                  value={note}
                  onChange={handleNoteChange}
                  variant="outlined"
                  fullWidth
                  autoComplete="off"
                />
                <Grid
                  item
                  xs={12}
                  style={{
                    paddingBottom: "16px", // You can adjust the padding value as needed
                  }}
                >
                  {noteEdited ? (
                  <Grid item xs={12} style={{ paddingTop: "1rem" }}>
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={handleSave}
                      sx={{
                        textTransform: "none",
                        fontWeight: "bold",
                        marginBottom: "10px",
                      }}
                    >
                      Spara
                    </Button>
                  </Grid>
                ) : null}
                  <InputLabel id="order-tag-label">Order Status</InputLabel>
                  <Select
                    labelId="order-tag-label"
                    id="order-tag-select"
                    value={tag}
                    onChange={handleTag}
                    fullWidth
                  >
                    <MenuItem value="" disabled>
                      <em>Välj status</em>
                    </MenuItem>
                    <MenuItem value="Mottagen">Mottagen</MenuItem>
                    <MenuItem value="Accepterad">Accepterad</MenuItem>
                    <MenuItem value="Färdig">Färdig</MenuItem>
                    <MenuItem value="Levererad">Levererad</MenuItem>

                    {/* Add more MenuItem components for other tags here */}
                  </Select>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Paper>
      )}

      <CustomSnackbar
        open={open}
        handleClose={handleClose}
        message="Uppdatering av order lyckades!"
        severity="success"
        isMobile={isMobileOnly}
      />
    </Box>
  );
}

export default ReadOnlyCateringOrder;
