import React, { useState, useContext } from "react";
import {
  Box,
  Button,
  TextField,
  Typography,
  Paper,
  IconButton,
} from "@mui/material"; // Added Snackbar and Alert
import { styled } from "@mui/system";
import { makeStyles } from "@mui/styles";
import InputAdornment from "@mui/material/InputAdornment";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import VisibilityOffOutlinedIcon from "@mui/icons-material/VisibilityOffOutlined";
import { isMobileOnly } from "react-device-detect";
import CustomSnackbar from "components/SnackBar";
import AuthContext from "context/AuthContext";
import LoadingScreen from "pages/Login/LoadingScreen";

// Define your components with styles
const StyledDiv = styled("div")(({ theme }) => ({
  position: "relative", // Set to relative
  minHeight: "100vh", // Set minHeight to 100vh
  maxHeight: "100vh", // Set minHeight to 100vh
  display: "flex",
  alignItems: isMobileOnly ? "none" : "center",
  justifyContent: "center",
  overflow: "hidden",
  zIndex: 0,
  backgroundColor: "#F8F9F9",
  background: isMobileOnly ? `url("data:image/svg+xml,%3Csvg class='border shadow-md' viewBox='0 0 451.935 803.44' xmlns='http://www.w3.org/2000/svg' preserveAspectRatio='none'%3E%3Crect class='bg' id='bg' x='0' y='0' width='451.935' height='803.44' fill='%23FBFCFC'%3E%3C/rect%3E%3Cg transform='rotate(39 225.9675 401.72)'%3E%3Cpath d='M -267.81 553.44 S -143.91 388.44 0.00 553.44 99.81 456.44 267.81 553.44 391.72 520.44 535.63 553.44 659.53 391.44 803.44 553.44 927.35 335.44 1071.25 553.44 h 110 V 1403.44 H -267.81 Z' fill='%2341666A'%3E%3C/path%3E%3Cpath d='M -267.81 275.00 S -143.91 145.00 0.00 275.00 17.81 118.00 267.81 275.00 330.63 147.50 535.63 275.00 582.44 147.50 803.44 275.00 927.35 147.50 1071.25 275.00 h 110 V -600 H -267.81 Z' fill='%23FBFCFC'%3E%3C/path%3E%3C/g%3E%3C/svg%3E")` : null,
}));

const useStyles = makeStyles({
  backgroundSlider: {
    position: "absolute",
    top: isMobileOnly ? 700 : 500,
    left: 0,
    width: "200%",
    height: "100%",
    backgroundSize: "cover",
    animation: "$slideBackground 40s linear infinite", // Disable animation for mobile
    zIndex: -1,
  },
  "@keyframes slideBackground": {
    "0%": {
      transform: "translateX(0)",
    },
    "100%": {
      transform: "translateX(-50%)",
    },
  },
  textField: {
    "& label.Mui-focused": {
      color: "#41666A", // set the color for the label when focused
    },
    "& .MuiOutlinedInput-root": {
      "&.Mui-focused fieldset": {
        borderColor: "#41666A", // set the color for the border when focused
      },
    },
  },
  customUnderline: {
    position: "relative",

    "&::after": {
      content: '""',
      position: "absolute",
      bottom: "-8px",
      left: 0,
      right: 0,
      height: "2.5px",
      backgroundColor: "#ACD3BD",
      borderRadius: "20px",
    },
  },
});

const PaperWrapper = styled("div")(({ theme }) => ({
  margin: isMobileOnly ? "5vh auto" : "15vh auto",
  maxWidth: isMobileOnly ? "100%" : "500px",
  width: isMobileOnly ? "100%" : "70%",
}));
const StyledPaper = styled(Paper)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  padding: theme.spacing(6),
  borderRadius: isMobileOnly ? "0px" : "15px",
  boxShadow: isMobileOnly ? "none" : "0px 4px 20px rgba(65, 102, 106, 0.2)",
  zIndex: 1,
  backgroundColor: "rgba(248, 249, 249, 0.5)",
  backdropFilter: "blur(10px)"
}));

const StyledForm = styled("form")(({ theme }) => ({
  width: "100%",
  marginTop: theme.spacing(1),
}));

const SubmitButton = styled(Button)(({ theme }) => ({
  backgroundColor: "#41666A",
  fontWeight: "bold",
  fontSize: "14px",
  textTransform: "none",
  color: "#fff",
  height: "40px", // increase height here
  "&:hover": {
    backgroundColor: theme.palette.primary.dark,
  },
}));

const RequestAccountButton = styled(Button)(({ theme }) => ({
  backgroundColor: theme.palette.primary.main,
  color: "#fff",
  fontWeight: "bold",
  fontSize: "14px",
  textTransform: "none",
  height: "40px", // increase height here
  "&:hover": {
    backgroundColor: theme.palette.primary.dark,
  },
}));

const LoginPage = () => {
  const classes = useStyles();
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  let { loginUser } = useContext(AuthContext);

  const [open, setOpen] = useState(false); // Add this state to control Snackbar open/close

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };

  const handleShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleLogin = (e) => {
    e.preventDefault();
    setIsLoading(true);
    loginUser(
      e,
      () => setIsLoading(false),
      () => {
        setOpen(true);
        setIsLoading(false);
      }
    );
  };

  if (isLoading) {
    return <LoadingScreen />;
  }
  return (
    <StyledDiv>
      {!isMobileOnly ? (
        <div className={classes.backgroundSlider}>
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320">
            <path
              fill="#41666A"
              fillOpacity="1"
              d="M0,256L60,245.3C120,235,240,213,360,170.7C480,128,600,64,720,64C840,64,960,128,1080,144C1200,160,1320,128,1380,112L1440,96L1440,320L1380,320C1320,320,1200,320,1080,320C960,320,840,320,720,320C600,320,480,320,360,320C240,320,120,320,60,320L0,320Z"
            ></path>
          </svg>
        </div>
      ) : null}
      <PaperWrapper>
        <StyledPaper elevation={0}>
          <Typography
            variant="h1"
            fontWeight="bold"
            sx={{
              color: "#41666A",
              marginBottom: "20px",
              letterSpacing: "-2px",
            }}
          >
            Shopflow
          </Typography>
          <Typography
            variant="h3"
            fontWeight="bold"
            className={classes.customUnderline}
            sx={{
              color: "#41666A",
              letterSpacing: "-1px",
              alignSelf: "flex-start",
            }}
          >
            Logga in
          </Typography>

          <StyledForm onSubmit={handleLogin}>
            <TextField
              className={classes.textField}
              variant="outlined"
              margin="normal"
              required
              fullWidth
              id="username"
              label="Användarnamn"
              name="username"
              autoComplete="username"
              autoFocus
              value={username}
              onChange={(e) => setUsername(e.target.value.toLowerCase())}
              InputLabelProps={{
                required: false, // This will hide the asterisk
              }}
            />
            <TextField
              className={classes.textField}
              variant="outlined"
              margin="normal"
              required
              fullWidth
              name="password"
              label="Lösenord"
              type={showPassword ? "text" : "password"}
              id="password"
              autoComplete="current-password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              InputLabelProps={{
                required: false, // This will hide the asterisk
              }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleShowPassword}
                      disableFocusRipple={true}
                    >
                      {showPassword ? (
                        <VisibilityOutlinedIcon />
                      ) : (
                        <VisibilityOffOutlinedIcon />
                      )}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
            <Box sx={{ mt: 2 }}>
              <SubmitButton
                type="submit"
                fullWidth
                variant="contained"
                disabled={isLoading}
              >
                {isLoading ? "Laddar" : "Logga in"}
              </SubmitButton>
            </Box>
            <Box sx={{ mt: 2 }}>
              <RequestAccountButton
                fullWidth
                variant="contained"
                onClick={() =>
                  (window.location.href = "mailto:info@shopflow.se")
                }
              >
                Efterfråga ett konto
              </RequestAccountButton>
            </Box>
          </StyledForm>
        </StyledPaper>
      </PaperWrapper>
      <CustomSnackbar
            open={open}
            autoHideDuration={6000}
            handleClose={handleClose}
            severity={"error"}
            isLogin={true}
            message={"Användarnamnet eller lösenorder är fel!"}
          />
    </StyledDiv>
  );
};

export default LoginPage;
