import React, { useState, useEffect, useContext } from "react";
import {
  Button,
  Box,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Checkbox,
} from "@mui/material"
import { Container, StyledDataGrid } from "./Style/Styles";
import { Link } from "react-router-dom";


// HOOKS
import GetAllOrders from "Hooks/Catering/GetAllOrders.js";
import useUpdateOrder from "Hooks/Catering/useUpdateOrder";
import AuthContext from "context/AuthContext.js";

// COMPONENTS
import LoadingComponent from "components/LoadingComponent.jsx";
import ErrorComponent from "components/ErrorComponent.jsx";

// UTILS 
import { getDeliveryDate } from "utilities/getNoteAttributes";
import { getBookedFromTags } from "utilities/getStatusFromTags";
import { parseDate } from "utilities/dateUtility.js";
import generatePDF from "utilities/generatePdf.js";
import { getTotalPrice } from "utilities/getTotalPrice";
// ICONS
import DownloadIcon from "@mui/icons-material/Download";

const OrdersTable = () => {
  // HOOKS
  const { orders, isLoading, isError } = GetAllOrders(); 
  const { updateOrder } = useUpdateOrder()
  let {user} = useContext(AuthContext)

  // USESTATES
  const [open, setOpen] = useState(false);
  const [lineItems, setLineItems] = useState([]);
  const [localOrders, setLocalOrders] = useState([]);
  const [selectedOrderId, setSelectedOrderId] = useState(null);
  const [selectedRows, setSelectedRows] = useState([]);

  // This will update localOrders whenever orders is updated
  useEffect(() => {
    setLocalOrders(orders);
  }, [orders]);

  const handleOpen = (items, orderId) => {
    setLineItems(items || []);
    setSelectedOrderId(orderId);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setSelectedOrderId(null);
  };

  const handleBookedChange = (event, orderId) => {
    const isBooked = event.target.checked;
    const orderIndex = localOrders.findIndex((order) => order.id === orderId);
    if (orderIndex !== -1) {
      // Find the index of the "booked" tag
      const tagsArray = localOrders[orderIndex].tags.split(", ");
      const bookedIndex = tagsArray.findIndex((tag) =>
        tag.startsWith("booked:")
      );
      if (bookedIndex > -1) {
        // If the "booked" tag is found, replace it
        tagsArray[bookedIndex] = `booked: ${isBooked ? "True" : "False"}`;
      } else {
        // If the "booked" tag is not found, add it
        tagsArray.push(`booked: ${isBooked ? "True" : "False"}`);
      }
      // Combine the tags back into a string
      const newTags = tagsArray.join(", ");

      const updatedOrder = { ...localOrders[orderIndex], tags: newTags };
      updateOrder(orderId, { tags: updatedOrder.tags });

      const updatedOrders = [...localOrders];
      updatedOrders[orderIndex] = updatedOrder;
      setLocalOrders(updatedOrders);
    }
  };

  const handleRowSelectionChange = (newSelection) => {
    setSelectedRows(newSelection);
  };

  const areAnyCellsSelected = () => {
    return selectedRows.length > 0;
  };

  const handleDownload = () => {
    selectedRows.forEach((id) => {
      const order = localOrders.find(order => order.id === id);
      generatePDF(order.id, order, user.shopName);  // I'm assuming `generatePDF` takes in the order as an argument
    });
  };

  const columns = [
    { field: "id", headerName: "ID", type: 'number', flex: 1 },
    { field: "name", headerName: "Namn", type: 'string', flex: 1 },
    { 
      field: "createdAt", 
      headerName: "Lagd", 
      type: 'date', 
      flex: 1, 
      valueGetter: (params) => params.value,
      valueFormatter: (params) => {
      const date = params.value;
      return `${date.getDate()}/${date.getMonth() + 1}/${date.getFullYear()}`;
    },
    },
    { 
      field: "deliveryDate", 
      headerName: "Upphämtad", 
      flex: 1,
      valueGetter: (params) => params.value,
      valueFormatter: (params) => {
        const date = params.value;
        return `${date.getDate()}/${date.getMonth() + 1}/${date.getFullYear()}`;
      }
    },
    { field: "totalPriceExclTax", headerName: "Ex moms", type: 'number', flex: 0.8 },
    { field: "taxAmount", headerName: "Moms", type: 'number', flex: 0.8 },
    { field: "totalPriceInclTax", headerName: "Inkl moms", type: 'number', flex: 0.8 },
    {
      field: "booked",
      headerName: "Bokfört",
      flex: 0.8,
      renderCell: (params) => (
        <Checkbox
          checked={params.row.booked}
          onChange={(event) => handleBookedChange(event, params.row.id)}
        />
      ),
    },
    {
      field: "lineItems",
      headerName: "Beställning",
      flex: 1.2,
      renderCell: (params) => (
        <Button
          variant="outlined"
          color="primary"
          onClick={() => handleOpen(params.row.lineItems, params.row.id)}
          style={{
            textTransform: "none",
            fontWeight: "bold"
          }}
        >
          Beställning
        </Button>
      ),
    },
  ];

  const rows =
    localOrders && localOrders.length > 0
      ? localOrders.map((order) => {
          
          const deliveryDate = getDeliveryDate(order)
            ? getDeliveryDate(order)
            : "N/A";

          // Calculate the total price excluding tax (VAT)
          const totalPriceExclTax = getTotalPrice(order) / 1.12; // Assuming VAT is 12%

          // Calculate the tax (VAT) amount
          const taxAmount = getTotalPrice(order) - totalPriceExclTax;

          const booked = getBookedFromTags(order.tags);

          return {
            id: order.id,
            name: `${order.billing_address?.first_name} ${order.billing_address?.last_name}`,
            totalPriceExclTax: totalPriceExclTax.toFixed(2),
            taxAmount: taxAmount.toFixed(2),
            totalPriceInclTax: getTotalPrice(order),
            createdAt: new Date(order.created_at),
            deliveryDate: new Date(parseDate(deliveryDate)),
            lineItems: order.lineItems,
            booked: booked, // Set the booked value based on the booked attribute
          };
        })
      : [];

  if (isLoading) return <LoadingComponent />;
  if (isError) return <ErrorComponent error={isError} />;

  return (
    
    <Container>
      {areAnyCellsSelected() && (
            <Box sx={{ position: "fixed", bottom: 20, right: 20, zIndex: 999, }}>
              <Button
                variant="contained"
                color="primary"
                onClick={handleDownload}
                endIcon={<DownloadIcon />}
                style={{
                  textTransform: "none",
                  fontWeight: "bold",
                }}
              >
                Ladda ner
              </Button>
            </Box>
          )}
      <React.Fragment>
        <StyledDataGrid
          rows={rows}
          columns={columns}
          pageSize={5}
          rowsPerPageOptions={[5]}
          checkboxSelection
          disableSelectionOnClick
          disableRowSelectionOnClick
          onRowSelectionModelChange={handleRowSelectionChange}
        />
        <Dialog open={open} onClose={handleClose} sx={{ '& .MuiDialog-paper': { minWidth: '400px', padding: "10px" } }}>
          <DialogTitle sx={{ fontSize: "20px" }}><strong>Beställning</strong></DialogTitle>
          <DialogContent>
            {lineItems.map((item) => (
              <Box key={item.id}>
                {item.title} <strong>: {item.quantity}st </strong>
              </Box>
            ))}
          </DialogContent>
          <DialogActions>
            <Button 
              variant="outlined"
              onClick={handleClose}
              style={{
                fontWeight: "bold",
                textTransform: "none",
                margin: "5px",
              }}
            >
              Stäng
            </Button>
            <Button
              variant="contained" 
              component={Link} 
              to={`/catering/${selectedOrderId}/edit`}
              style={{
                fontWeight: "bold",
                textTransform: "none",
                margin: "5px",
              }}
            >
              Mer info
            </Button>
          </DialogActions>
        </Dialog>
      </React.Fragment>
    </Container>
  );
};

export default OrdersTable;
