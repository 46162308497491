import { styled } from "@mui/system";
import { TableCell, TableRow } from "@mui/material";

export const StyledTableHeaderCell = styled(TableCell)(({ theme }) => ({
  fontWeight: "bold",
  backgroundColor: theme.palette.green.main,
  color: "white",
  fontSize: "0.9rem",
  "&:first-of-type": {
    borderTopLeftRadius: "5px",
  },
  "&:last-child": {
    borderTopRightRadius: "5px",
  },
}));

export const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: "white",
    borderRadius: "0",
  },
  "&:hover": {
    backgroundColor: theme.palette.action.selected,
    borderRadius: "0",
  },
}));
