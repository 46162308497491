import React, { useState, useEffect, useRef } from 'react';
import {
    Box,
    Table,
    TableHead,
    TableRow,
    TableCell,
    TableBody,
    Typography,
    Tooltip,
    Badge,
    Paper,
} from '@mui/material';
import GetUpcomingOrders from 'Hooks/Catering/useGetUpcommingOrders';
import { getDeliveryTimeRange, getDeliveryDate } from 'utilities/getNoteAttributes';
import Header from "components/Header";

import LoadingComponent from "components/LoadingComponent.jsx";
import ErrorComponent from 'components/ErrorComponent';



const getSwedishDay = (dateString) => {
    let [day, month, year] = dateString.split('/').map(Number); // Split the dateString into day, month, and year
    const date = new Date(year, month - 1, day); // Construct a new Date object
    const options = { weekday: 'long' };
    return new Intl.DateTimeFormat('sv-SE', options).format(date); // Format the date to get the weekday in Swedish
  };

  const organizeDeliveries = (orders) => {
    const deliveries = {};

    orders.forEach(order => {
        console.log(order)
        let deliveryDateStr = getDeliveryDate(order);
        let deliveryTimeRange = getDeliveryTimeRange(order);
        let deliveryDay = getSwedishDay(deliveryDateStr); // Get the Swedish day
        
        let [day, month, year] = deliveryDateStr.split('/').map(Number);
        let deliveryDate = new Date(year, month - 1, day);
        
        let currentDate = new Date();
        currentDate.setHours(0, 0, 0, 0);
        
        let threeDaysFromNow = new Date(currentDate);
        threeDaysFromNow.setDate(currentDate.getDate() + 3);

        if (deliveryDate >= currentDate && deliveryDate <= threeDaysFromNow) {
            const key = `${deliveryDay} ${deliveryTimeRange}`;
            if (!deliveries[key]) {
                deliveries[key] = [];
            }

            order.lineItems.forEach(item => {
                const existingItem = deliveries[key].find(e => e.name === item.name && e.vendor === item.vendor);
                if(existingItem) {
                    existingItem.totalQuantity += item.quantity;
                    existingItem.orders.push({ id: order.id, quantity: item.quantity, note: order.note });
                } else {
                    deliveries[key].push({
                        ...item,
                        totalQuantity: item.quantity,
                        orders: [{ id: order.id, quantity: item.quantity, note: order.note }], // Store the note in the order array
                        vendor: item.vendor, // Assume each line_item has a vendor property
                    });
                }
            });
            
        }
    });

    return deliveries;
};

const DeliverySchedulePage = () => {
    const { upcomingOrders: orders, isLoading, error } = GetUpcomingOrders();
    const [deliverySchedule, setDeliverySchedule] = useState({});

    const lastColorIndex = useRef(0);
    const colorsMap = useRef(new Map()); // This map stores the color for each order ID
    

    console.log(orders)
    
    useEffect(() => {
        if (orders) {
            const deliveries = organizeDeliveries(orders);
            setDeliverySchedule(deliveries);
        }
    }, [orders]);

    const colors = [
        //'#FF5733', '#33FF57', '#3357FF', '#FF33A6', '#FF8C33', '#33FFA8', '#8C33FF'
        '#E3655B', '#7F98FF', '#87CEFA', '#FFC1C1', '#5B8C5A', '#ff9f43', '#1D4E89',
        '#BF4E30', '#832161', '#86BBD8', '#DB93B0', '#5B4B49', '#f368e0',
        '#BDBF09', '#3C3C3B'
        // Add more colors as needed
    ];


    // Generate colors by iterating over the hue.
    // const generateColors = (count) => {
    //     let colors = [];
    //     for (let i = 0; i < count; i++) {
    //         let hue = i * (360 / count) % 360;
    //         let saturation = 80 + i % 2 * 10; // Alternating between 80% and 90% saturation
    //         let lightness = 50 + i % 5 * 10; // Alternating between 50%, 60%, 70%, 80%, and 90% lightness
    //         colors.push(`hsl(${hue}, ${saturation}%, ${lightness}%)`);
    //     }
    //     return colors;
    // };
    
    // const gen_colors = generateColors(100); // or any other number you prefer
    

    const getColorForOrderId = (orderId) => {
        // If the order already has an associated color, use that.
        if(colorsMap.current.has(orderId)) {
            return colorsMap.current.get(orderId);
        }
    
        // If not, assign a new color to the order.
        let colorIndex = lastColorIndex.current % colors.length;
        lastColorIndex.current += 1; // Move to the next color
    
        let color = colors[colorIndex];
        colorsMap.current.set(orderId, color); // Store the color for this order ID
    
        return color;
    };
    


    const dayMapping = {
        'måndag': 'Monday',
        'tisdag': 'Tuesday',
        'onsdag': 'Wednesday',
        'torsdag': 'Thursday',
        'fredag': 'Friday',
        'lördag': 'Saturday',
        'söndag': 'Sunday',
    };


    function getFullDate(day, time) {
        const today = new Date();
        const dayIndex = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"].indexOf(day);
        let daysToAdd = (dayIndex - today.getDay() + 7) % 7;
        let newDate = new Date(today);
        newDate.setDate(today.getDate() + daysToAdd);
        newDate.setHours(time.split(":")[0], time.split(":")[1], 0, 0);
        return newDate;
      }
      
      const sortedTimeSlots = Object.entries(deliverySchedule).sort((a, b) => {
        const [dayTimeA] = a[0].split(' - ');
        const [dayTimeB] = b[0].split(' - ');
      
        // Splitting Day and Time
        const [dayA, timeA] = dayTimeA.split(' ');
        const [dayB, timeB] = dayTimeB.split(' ');
      
        // Convert the Swedish day to English day
        const englishDayA = dayMapping[dayA.toLowerCase()];
        const englishDayB = dayMapping[dayB.toLowerCase()];
      
        // Use the getFullDate function to get the correct date objects
        const dateA = getFullDate(englishDayA, timeA).getTime();
        const dateB = getFullDate(englishDayB, timeB).getTime();
      
        // Log with separators for better readability
        console.log("datezzzzz: ", `${englishDayA} ${timeA}`);
        console.log("date: ", dateA, dateB);
      
        // Return the comparison
        return dateA - dateB;
      });


      const hasTimeSlotPassed = (day, timeRange) => {
        // Check if day is provided and has a valid mapping
        if (!day || !dayMapping[day.toLowerCase()]) {
            console.warn(`Invalid day provided: ${day}`);
            return false;
        }
        
        // Check if timeRange is provided and results in valid startTime and endTime
        const [startTime, endTime] = timeRange ? timeRange.split(' - ') : [];
        if (!startTime || !endTime) {
            console.warn(`Invalid timeRange provided: ${timeRange}`);
            return false;
        }
    
        const currentDate = new Date();
        const slotEndDate = getFullDate(dayMapping[day.toLowerCase()], endTime);
    
        return slotEndDate < currentDate;
    };
    
      


    

    if (isLoading){
        return <LoadingComponent />;
      }
      

    if (error) {
        return <ErrorComponent error={error} />;
      }

    return (
        <Box p={3} mx={5} my={3} component={Paper} elevation={1} sx={{ overflow: 'hidden', borderRadius: 2, boxShadow: '0px 2px 4px 0px rgba(0,0,0,0.1)' }}>
        
        <Header
          title={"Ordrar"}
          subtitle="Se antal produkter som ska levereras närmaste 3 dagarna"
          style={{ color: "#000000" }}
        />  
        <br /> 

            {sortedTimeSlots.map(([timeSlot, consolidatedItems]) => {
                const [day, timeRange] = timeSlot.split(' ');
                const isPast = hasTimeSlotPassed(day, timeRange); // Check if the time slot has passed
                
                return (
                    <Box 
                        key={timeSlot} 
                        mb={3} 
                        p={2} 
                        component={Paper} 
                        elevation={1} 
                        sx={{ 
                            borderRadius: 1, 
                            boxShadow: '0px 2px 4px 0px rgba(0,0,0,0.1)'
                        }}
                    >
                        <Typography 
                            variant="h6" 
                            mb={1} 
                            sx={{ 
                                color: isPast ? '#aaa' : '#2C2C2C',  // Lighter color if past
                                fontWeight: 'medium' 
                            }}
                        >
                            {timeSlot}
                        </Typography>
                    <Table sx={{ minWidth: 650 }} size="medium"> {/* Changed size to "medium" to make overall table font larger */}
                        <TableHead>
                            <TableRow>
                            <TableCell sx={{ width: '40%', fontWeight: 'bold', color: isPast ? '#aaa' : '#2C2C2C', fontSize: '1rem' }}>Produkt</TableCell>
                            <TableCell sx={{ width: '20%', fontWeight: 'bold', color: isPast ? '#aaa' : '#2C2C2C', fontSize: '1rem' }}>Total mängd</TableCell>
                            <TableCell sx={{ width: '15%', fontWeight: 'bold', color: isPast ? '#aaa' : '#2C2C2C', fontSize: '1rem' }}>Ordrar</TableCell>
                            <TableCell sx={{ width: '15%', fontWeight: 'bold', color: isPast ? '#aaa' : '#2C2C2C', fontSize: '1rem' }}>Leverantör</TableCell>
                            </TableRow> 
                        </TableHead>
                        <TableBody>
                            {consolidatedItems.map(item => (
                                <TableRow key={item.name} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                    <TableCell component="th" scope="row" sx={{ color: isPast ? '#aaa' : '#333', fontSize: '0.9rem' }}> {/* Increased font size */}
                                        {item.name}
                                    </TableCell>
                                    <TableCell sx={{ color: isPast ? '#aaa' : '#333', fontSize: '0.9rem' }}>{item.totalQuantity}</TableCell> {/* Increased font size */}
                                    <TableCell sx={{ fontSize: '0.9rem' }}> {/* Increased font size */}
                                    {item.orders.map((order, index) => (
                                            <Tooltip
                                                key={order.id}
                                                title={
                                                    <>
                                                        <Typography variant="subtitle2" color="inherit">Order {order.id}</Typography>
                                                        <ul>
                                                            {orders.find(o => o.id === order.id).lineItems.map(lineItem => (
                                                                <li key={lineItem.id}>
                                                                    <Typography variant="body2" color="inherit">
                                                                        {lineItem.title} - {lineItem.quantity}
                                                                    </Typography>
                                                                </li>
                                                            ))}
                                                        </ul>
                                                        <Typography variant="subtitle2" color="inherit"><b>{order.note}</b></Typography>
                                                    </>
                                                }
                                            >
                                                <Badge
                                                    overlap="circular"
                                                    anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                                                    badgeContent={order.quantity}
                                                    sx={{
                                                        '.MuiBadge-badge': {
                                                            backgroundColor: "#e0e0e0", // Soft grey background
                                                            color: 'black', // Black text color for better readability
                                                        },
                                                    }}
                                                >
                                                    <Box
                                                        display="inline-block"
                                                        mr={1}
                                                        bgcolor={getColorForOrderId(order.id)}
                                                        borderRadius="50%"
                                                        p={0.5}
                                                        sx={{ 
                                                            width: 24, 
                                                            height: 24,
                                                            opacity: isPast ? 0.5 : 1 
                                                        }}
                                                    />
                                                </Badge>
                                            </Tooltip>
                                        ))}
                               </TableCell>
                                    <TableCell sx={{ color: isPast ? '#aaa' : '#333', fontSize: '0.9rem' }}>{item.vendor}</TableCell> {/* Increased font size */}
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                    </Box>
                );
            })}
        </Box>
    );
};

export default DeliverySchedulePage;