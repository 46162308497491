import { useState, useContext } from 'react';
import axios from 'axios';
import AuthContext from 'context/AuthContext';  // Ensure the path to AuthContext is correct.

const useCreatePost = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const { updateToken, logoutUser } = useContext(AuthContext);

  const createPost = async (postData, retryCount = 0) => {
    setIsLoading(true);

    const baseUrl = process.env.REACT_APP_BASE_URL
      ? process.env.REACT_APP_BASE_URL
      : 'http://localhost:8000/';

    const config = {
      headers: {
        'Content-Type':'application/json',
      },
      withCredentials: true, // Use withCredentials instead of credentials for axios

    };

    try {
      await axios.post(`${baseUrl}/store/store-posts/add/`, postData, config);

      setError(null);
    } catch (err) {
      // If we get a 401 (unauthorized) or 403 (forbidden) and haven't retried yet
      if ((err.response?.status === 401 || err.response?.status === 403) && retryCount < 1) {
        await updateToken();
        return createPost(postData, retryCount + 1);  // Recursive retry after refreshing token
      } else if ((err.response?.status === 401 || err.response?.status === 403) && retryCount >= 1) {
        logoutUser();
        setError('Authentication failed. Logging out.');
      } else {
        // Use the error message from the server, if available, else use a default message.
        console.error('Axios Error:', err.response ? err.response.data : err.message);
        setError('An error occurred while creating the post.');
      }
    } finally {
      setIsLoading(false);
    }
  };

  return { createPost, isLoading, error };
};

export default useCreatePost;
