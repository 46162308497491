import React, { useMemo, useContext } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Link as RouterLink } from "react-router-dom";
import moment from "moment";
import "moment/locale/sv"; // Import the Swedish locale
import {
  Box,
  Button,
  Container,
  Toolbar,
  Typography,
  Grid,
} from "@mui/material";

// ICONS
import NewReleasesIcon from "@mui/icons-material/NewReleases";
import HealthAndSafetyIcon from "@mui/icons-material/HealthAndSafety";
import ReceiptLongIcon from "@mui/icons-material/ReceiptLong";
import LibraryAddCheckIcon from "@mui/icons-material/LibraryAddCheck";

// HOOKS
import useStorePosts from "../../Hooks/Posts/useStorePosts.js";
import GetUpcomingOrders from "../../Hooks/Catering/useGetUpcommingOrders";
import GetPastOrders from "Hooks/Catering/useGetPastOrders.js";
import { isMobileOnly } from "react-device-detect";

// COMPONENTS
import UpcomingOrders from "./components/UpcomingOrders";
import StatsBox from "./components/StatsBox.jsx";
import ErrorComponent from "components/ErrorComponent";
import OrderChart from "./components/OrderChart.jsx";
import PostPreview from "./components/PostPreview";
import Header from "components/Header";
import ErrorOrders from "./components/ErrorOrders.jsx";
import OrderTable from "./components/OrderTable.jsx";

// UTILS AND STYLING/THEME
import { getStatusFromTags } from "../../utilities/getStatusFromTags";
import { mainStyle } from "./Style/Styles.jsx";
import { useTheme } from "@mui/material/styles";
import { styled } from "@mui/material/styles";
import { isThisWeek } from "utilities/dateUtility.js";
import { getDeliveryDate } from "utilities/getNoteAttributes.js";

import AuthContext from "context/AuthContext";
import LoadingComponent from "components/LoadingComponent.jsx";

const Main = styled("main", { shouldForwardProp: (prop) => prop !== "open" })(
  ({ theme, open }) => mainStyle(theme, open)
);

const getGreeting = () => {
  const currentHour = new Date().getHours();
  if (currentHour < 12) {
    return "Godmorgon";
  } else if (currentHour < 18) {
    return "Goddag";
  } else {
    return "Godkväll";
  }
};

function calculateOrderStatusCount(orders, status) {
  return orders.filter((order) => getStatusFromTags(order.tags) === status)
    .length;
}

function HomePage() {
  const navigate = useNavigate();
  const location = useLocation();
  const theme = useTheme();
  let { user } = useContext(AuthContext);

  if (isMobileOnly) {
    navigate("/mobile/landing");
  }

  const {
    posts,
    isLoading: isPostLoading,
    isError: isPostError,
  } = useStorePosts();

  const {
    upcomingOrders: cateringOrder,
    errorOrders,
    isLoading,
    isError,
  } = GetUpcomingOrders();

  const {
    pastOrders,
  } = GetPastOrders();

  // used to calc 'förflutna' orders
  function ordersNotDelivered(orders) {
    return orders?.filter((order) => {
      return getStatusFromTags(order?.tags) !== "Levererad";
    });
  }

  // used to calc 'kommande' orders
  function ordersToDeliver(orders) {
    return orders?.filter((order) => {
      const date = getDeliveryDate(order);
      return isThisWeek(date);
    });
  }
  // filter the errored orders depending on their status, dont want to show them if they are Levererade
  const filteredErrorOrders = errorOrders
    ? errorOrders.filter((order) => {
        const status = getStatusFromTags(order.tags);
        return status !== "Levererad";
      })
    : [];

  // This means that it will only recompute the value if `cateringOrder` changes.
  // By doing so, it prevents unnecessary recalculations and can improve performance.
  const ordersPerDay = useMemo(() => {
    if (cateringOrder) {
      return getOrdersPerDay(cateringOrder);
    } else {
      return []; // If `cateringOrder` is falsy, return an empty array.
    }
  }, [cateringOrder]); // The computation will only re-run if `cateringOrder` changes.

  if (isPostLoading | isLoading) {
    return <LoadingComponent />;
  }

  if (isError) return <ErrorComponent error={isError} />;

  const colorMap = {
    Accepterad: theme.palette.status.accepterad,
    Färdig: theme.palette.status.fardig,
    Levererad: theme.palette.status.levererad,
    Mottagen: theme.palette.status.mottagen,
  };

  // Creating a list for getting all the mottagna ordrar
  // Uses in the bottom of the page

  const unHandledOrders = cateringOrder
    ? [...cateringOrder] // Spread `cateringOrder` into a new array to avoid mutating the original array.
        .filter((order) => {
          // Filter the orders based on a specific condition:
          const tagsArray = order.tags ? order.tags.split(", ") : []; // Split the tags string into an array if it exists.
          const statusTag = tagsArray.find((tag) => tag.startsWith("status:")); // Find the tag that starts with "status:".
          const status = statusTag ? statusTag.split(":")[1].trim() : "Mottagen"; 
          return !status || status === "Mottagen"; // Include the order if status is falsy or equal to "Mottagen".
        })
        .sort((a, b) => new Date(b.created_at) - new Date(a.created_at)) // Sort the orders by creation date in descending order.
    : []; // If `cateringOrder` is falsy, return an empty array.

  function getOrdersPerDay(orders) {
    moment.locale("sv"); // Set the locale to Swedish
    let ordersPerDay = [];

    // Loop through the next 7 days
    for (let day = 0; day < 7; day++) {
      // Calculate the start and end date of the current day
      let dayStartDate = moment().add(day, "days").startOf("day");
      let dayEndDate = moment(dayStartDate).endOf("day");

      // Find orders that are due this day by filtering them based on the delivery date
      let ordersThisDay = orders.filter((order) => {
        // Extract delivery date from the order's note attributes
        const leveransDatum =
          order.note_attributes?.find((attr) => attr.name === "Leveransdatum")
            ?.value || "01/01/1970";
        let deliveryDate = moment(leveransDatum, "DD/MM/YYYY");

        // Check if the delivery date is within the current day
        return (
          deliveryDate.isSameOrAfter(dayStartDate) &&
          deliveryDate.isBefore(dayEndDate)
        );
      });

      let dayStartFormatted = dayStartDate.format("ddd");

      // Add this day's order count to the result array
      ordersPerDay.push({
        dayStart: dayStartFormatted,
        numOrders: ordersThisDay.length,
      });
    }

    return ordersPerDay;
  }

  const isValidPosts = Array.isArray(posts) && posts.length;

  const sortedPosts = isValidPosts
    ? [...posts].sort((a, b) => new Date(b.date) - new Date(a.date))
    : [];

  const newestPosts = isValidPosts ? sortedPosts.reverse().slice(0, 3) : [];

  const undeliveredOrders = ordersNotDelivered(pastOrders);
  const ordersToDeliverThisWeek = ordersToDeliver(cateringOrder);

  return (
    <>
      <Toolbar />

      <Container maxWidth="lg">
        <Box sx={{ display: "flex" }}>
          <Main open={location.pathname !== "/"}>
            <Header
              title={`${getGreeting()} ${
                user?.shopName ? user?.shopName : "My App"
              }!`}
              subtitle="Hoppas din dag går bra!"
              style={{ color: theme.palette.textColor.main }}
            />

            <Grid container spacing={2} sx={{ mt: 4, marginBottom: 4 }}>
              <StatsBox
                label="Mottagna"
                value={calculateOrderStatusCount(cateringOrder, "Mottagen")}
                unit=" st"
                color={colorMap["Mottagen"]}
                icon={ReceiptLongIcon}
              />
              <StatsBox
                label="Accepterade"
                value={calculateOrderStatusCount(cateringOrder, "Accepterad")}
                unit=" st"
                color={colorMap["Accepterad"]}
                icon={HealthAndSafetyIcon}
              />
              <StatsBox
                label="Färdiga"
                value={calculateOrderStatusCount(cateringOrder, "Färdig")}
                unit=" st"
                color={colorMap["Färdig"]}
                icon={LibraryAddCheckIcon}
              />
              <StatsBox
                label="Felaktiga beställningar"
                value={filteredErrorOrders.length}
                unit=" st"
                color={"#D0342C"}
                icon={NewReleasesIcon}
                bgColor={
                  filteredErrorOrders.length === 0 ? "#e7e7e7" : "#ffffff"
                }
                opacity={filteredErrorOrders.length === 0 ? 0.5 : 1}
              />
            </Grid>
            <Box sx={{ mt: 4, width: "100%" }}>
              {filteredErrorOrders.length > 0 && (
                <ErrorOrders orders={filteredErrorOrders} />
              )}
            </Box>
            <Grid
              container
              spacing={3}
              justifyContent="center"
              alignItems="center"
            >
              <Grid item xs={12} lg={12}>
                <Grid container spacing={3}>
                  {/* PastOrders Component */}
                  <Grid item xs={12} lg={7}>
                  {undeliveredOrders?.length > 0 ? (
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          backgroundColor: theme.palette.background.third,
                          color: theme.palette.textColor.main,
                          borderRadius: 0,
                          pl: 3,
                          pr: 3,
                          pb: 0,
                          pt: 3,
                          height: "100%",
                        }}
                      >
                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                          }}
                        >
                          <Typography
                            variant="h4"
                            gutterBottom
                            sx={{
                              color: theme.palette.textColor.main,
                              fontWeight: "700",
                              letterSpacing: "-0.01px",
                            }}
                          >
                            Ordrar med förflutet leveransdatum
                          </Typography>
                        </Box>
                        <Typography variant="subtitle1">
                          Är en order levererad? Ändra status till{" "}
                          <b>Levererad</b>
                        </Typography>
                        <Box sx={{ mt: 2 }}>
                          {undeliveredOrders ? (
                            <OrderTable orders={undeliveredOrders} />
                          ) : (
                            <p>Laddar...</p>
                          )}
                        </Box>
                      </Box>
                    ) : (
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          backgroundColor: theme.palette.background.third,
                          color: theme.palette.textColor.main,
                          borderRadius: 0,
                          pl: 2,
                          pr: 2,
                          pb: 0,
                          pt: 3,
                        }}
                      >
                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                          }}
                        >
                          <Typography
                            variant="h4"
                            gutterBottom
                            sx={{
                              color: theme.palette.textColor.main,
                              fontWeight: "700",
                            }}
                          >
                            Leveranser kommande vecka
                          </Typography>
                          <Button
                            variant="contained"
                            sx={{
                              backgroundColor: "#79A8A9",
                              "&:hover": {
                                backgroundColor: "#79A8A9",
                                color: "#ffffff",
                                marginBottom: 0,
                              },
                              fontWeight: "bold",
                              textTransform: "none",
                              color: "white",
                            }}
                            component={RouterLink}
                            to="/catering"
                          >
                            Alla ordrar
                          </Button>
                        </Box>
                        <Typography variant="subtitle1">
                          Hantera veckans kommande leveranser eller <strong>se mer</strong> av en order
                        </Typography>
                        <Box sx={{ mt: 2 }}>
                          {ordersToDeliverThisWeek ? (
                            <OrderTable orders={ordersToDeliverThisWeek} />
                          ) : (
                            <p>Laddar...</p>
                          )}
                        </Box>
                      </Box>
                    )}
                  </Grid>

                  {/* OrdersChart Component */}
                  <Grid item xs={12} lg={pastOrders ? 5 : 12}>
                    <OrderChart {...{ theme, ordersPerDay }} />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>

            <Box sx={{ mt: 4, width: "100%" }}>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  alignItems: "center",
                  mb: 2,
                }}
              >
                <Typography variant="h3" fontWeight={600} color="textColor">
                  Senaste meddelande
                </Typography>
                <Button
                  variant="outlined"
                  color="primary"
                  component={RouterLink}
                  to={"/posts"}
                  size="small"
                  sx={{
                    fontWeight: "bold",
                    textTransform: "none",
                  }}
                >
                  Se alla meddelanden
                </Button>
              </Box>

              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                  }}
                >
                  {isPostError ? (
                    <Typography>
                      Ett fel inträffade vid hämtning av meddelanden
                    </Typography>
                  ) : (
                    newestPosts.map((post) => (
                      <Box
                        key={post?.id}
                        sx={{ flex: "1 0 300px", maxWidth: "33%", m: 1 }}
                      >
                        <PostPreview post={post} />
                      </Box>
                    ))
                  )}
                </Box>
              </Box>
            </Box>

            <Box id="latestOrders" sx={{ mt: 4, width: "100%" }}>
              {unHandledOrders.length > 0 ? (
                <UpcomingOrders orders={unHandledOrders} />
              ) : (
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "200px", // adjust height as needed
                    border: "1px solid #62999F",
                    borderRadius: "5px",
                    padding: "2em",
                    backgroundColor: "#FBFBFB", // adjust color as needed
                    boxShadow: "0px 4px 20px rgba(0, 0, 0, 0.1)", // custom shadow
                    marginTop: "50px",
                    // boxShadow: 3, // or use predefined shadow
                  }}
                >
                  <Typography variant="h5" color="textSecondary">
                    Det finns ingen ordrar att acceptera!
                  </Typography>
                </Box>
              )}
            </Box>
          </Main>
        </Box>
      </Container>
    </>
  );
}
export default HomePage;