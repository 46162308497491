import { useQuery } from 'react-query';
import { useContext } from 'react';
import AuthContext from 'context/AuthContext';


const fetchOrders = async (updateToken, logoutUser, retryCount = 0) => {
  const baseUrl = process.env.REACT_APP_BASE_URL ? process.env.REACT_APP_BASE_URL : 'http://localhost:8000';
    
  const response = await fetch(`${baseUrl}/shopify-api/orders/`, {
    headers: {
      'Content-Type':'application/json',
    },
    credentials: 'include',  // Include this line
  });

  if ((response.status === 401 || response.status === 403) && retryCount < 1) {
    // If unauthorized and haven't retried yet, refresh token and try again
    await updateToken();
    return fetchOrders(updateToken, logoutUser, retryCount + 1);
} else if ((response.status === 401 || response.status === 403) && retryCount >= 1) {
    // If unauthorized and this was a retry, log the user out
    logoutUser();
    throw new Error('Authentication failed. Logging out.');
}


  const data = await response.json();

  const modifyOrders = (orders) =>
  orders.map((order) => {
    // Extract refund line item IDs
    const refundedLineItemIds = order.refunds?.flatMap(refund => refund.refund_line_items.map(item => item.line_item_id)) || [];
    
    // Filter out line items that are refunded
    const lineItems = order.line_items
      .filter(item => !refundedLineItemIds.includes(item.id))

    return {
      ...order,
      lineItems,
    };
  });

  return modifyOrders(data);
}

export const GetAllOrders = () => {  
  const { updateToken, logoutUser } = useContext(AuthContext);

  const { data: orders, isLoading, isError } = useQuery(['orders'], () => fetchOrders(updateToken, logoutUser, 0), {
    retry: 1,
  });

  return { orders, isLoading, isError };
};

export default GetAllOrders;
