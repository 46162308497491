import React from "react";
import {
  Box,
  Typography,
  Card,
  CardHeader,
  CardContent,
  Divider,
  Grid,
  Button,
} from "@mui/material";
import { Link } from "react-router-dom";

import { useTheme } from "@mui/material/styles";
import {getStatusFromTags} from "utilities/getStatusFromTags"
import { getDeliveryDate } from "utilities/getNoteAttributes";
import LoadingComponent from "components/LoadingComponent";

const UpcomingOrders = ({ orders }) => {
  const calculateDaysLeft = (orderDate) => {
    const currentDate = new Date();
    const date = new Date(orderDate);
    const difference = date - currentDate;
    const daysLeft = Math.ceil(difference / (1000 * 60 * 60 * 24));
    return daysLeft;
  };


  const theme = useTheme();

  const getTitle = (orderDate) => {
    const daysLeft = calculateDaysLeft(orderDate);
    if (daysLeft === 0) {
      return "Idag";
    } else if (daysLeft === -1) {
      return "1 dag sedan";
    } else if (daysLeft < -1) {
      const daysAgo = Math.abs(daysLeft);
      return `${daysAgo} dagar sedan`;
    } else {
      return `${daysLeft} dagar kvar`;
    }
  };


  const getButtonColor = (status) => {
    switch (status) {
      case "Mottagen":
        return theme.palette.status["mottagen"]; // Greenish gray color for "Mottagen"
      case "Accepterad":
        return theme.palette.status["accepterad"]; // Bluish gray color for "Accepterad"
      case "Färdig":
        return theme.palette.status["fardig"]; // Purplish gray color for "Färdig"
      case "Leverad":
        return theme.palette.status["levererad"]; // Reddish gray color for "Leverad"
      default:
        return "#636e72"; // Default color (adjust as needed)
    }
  };

  const getButtonHoverColor = (status) => {
    switch (status) {
      case "Mottagen":
        return "#99b3cc"; // Light blue color for "Mottagen" hover
      case "Accepterad":
        return "#3498db"; // Light green color for "Accepterad" hover
      case "Färdig":
        return "#50e47c"; // Light green color for "Färdig" hover
      case "Leverad":
        return "#ea995c"; // Light orange color for "Leverad" hover
      default:
        return "#95a5a6"; // Default hover color (adjust as needed)
    }
  };

  if (!orders.length) {
    return (
      <LoadingComponent />
    );
  }

  return (
    <Box sx={{ mt: 4, width: "100%" }}>
      <Box sx={{ mb: 4 }}>
        <Typography
          variant="h3"
          gutterBottom
          sx={{
            fontWeight: "510",
            color: theme.palette.textColor.main,
          }}
        >
          Ohanterade beställningar
        </Typography>
      </Box>
      <Grid container spacing={2}>
      {orders.map((order) => (
        <Grid item xs={12} md={4} key={order.id}>
          <Card
            sx={{
              width: "100%",
              maxWidth: 600,
              boxShadow: 2,
              backgroundColor: (theme) => theme.palette.background.main,
            }}
          >
              <CardHeader
                title={getTitle(order.created_at)}
                subheader={`Leveransdatum: ${getDeliveryDate(order)}`}
                titleTypographyProps={{ variant: "h5", fontWeight: "600" }}
              />
              <Divider />
              <CardContent>
                <Typography variant="body2" gutterBottom>
                  Adress:{" "}
                  {order.shipping_address && order.shipping_address.address1
                    ? order.shipping_address.address1
                    : "Hämtas i butik"}
                </Typography>

                <Typography variant="body2">
                  Status: {getStatusFromTags(order.tags)}
                </Typography>
                <Button
                  variant="contained"
                  color="secondary"
                  fontWeight={700}
                  sx={{
                    mt: 2,
                    fontWeight: 550,
                    color: "white",
                    backgroundColor: getButtonColor(order.tags),
                    "&:hover": {
                      backgroundColor: getButtonHoverColor(order.tags),
                    },
                    textTransform: "none",
                  }}
                  component={Link}
                  to={`/catering/${order.id}/edit`}
                >
                  Mer info
                </Button>
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default UpcomingOrders;
