import { useState } from 'react';
 
const useSaveNote = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [message, setMessage] = useState(null);
  
  const saveNote = async (notes, productId) => {
    setIsLoading(true);

    let isInvalid = false;
    try {
      if (notes) {
        const hasInvalidNoteLength = notes.some(note => {
          const splitNote = note.split(" ");
          return splitNote.length !== 3;
        });
        
        if (hasInvalidNoteLength) {
          isInvalid = true;
          console.log("isinvalid =========== " + isInvalid)
          setMessage(<span>Du måste skriva råvaran på formen <i>kvanitet enhet namn </i>! <br /> Exempelvis: <b>0.1 kg lax</b> </span>)
          throw new Error('Cant save the note.');
        }
      }

      const baseUrl = process.env.REACT_APP_BASE_URL
        ? process.env.REACT_APP_BASE_URL
        : 'http://localhost:8000/';


      const note = notes.join('\n');
      
      const response = await fetch(
        `${baseUrl}/shopify-api/products/metafield/${productId}/update/`,
        {
          method: 'PUT',
          headers: {
            'Content-Type': 'application/json',
          },
          credentials: 'include',  // Include this line
          body: JSON.stringify({
            key: 'note',
            namespace: 'custom',
            value: note,
          }),
        }
      );

      if (!response.ok) {
        throw new Error('Error saving note.');
      }

      setMessage("Uppdatering av produkt lyckades!");
      setError(null);
    } catch (error) {
      
      if(!isInvalid){
        setMessage("Uppdatering av produkt misslyckades!");
      }
      setError('An error occurred while saving the note.');
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  return { saveNote, isLoading, error, message };
};

export default useSaveNote;
