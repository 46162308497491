import { CssBaseline, ThemeProvider } from "@mui/material";
import { createTheme } from "@mui/material/styles";
import { useMemo, useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  BrowserRouter,
  Navigate,
  Route,
  Routes,
  useLocation,
} from "react-router-dom";
import { themeSettings } from "theme";
import { svSE } from "@mui/x-data-grid";
import { QueryClient, QueryClientProvider } from "react-query";
import { ReactQueryDevtools } from "react-query/devtools";

import AddUserPage from "./pages/Users/AddUser/index.jsx";
import HomePage from "./pages/Home/HomePage.jsx";
import Layout from "./pages/Layout/Layout";

import EditCateringOrder from "./pages/Orders/OrderEdit/index.jsx";

import CreateOrders from "./pages/Orders/OrderGenerator/index.jsx";

import CateringTable from "./pages/Orders/OrdersTable/index.jsx";
import Booked from "./pages/Orders/OrdersBooked/index.jsx";
import ProductList from "./pages/Products_Folder/Products/index.jsx";
import ProductDetails from "./pages/Products_Folder/ProductDetails/index.jsx";

import SinglePostPage from "./pages/Posts/DetailPosts/index.jsx";

import StandByOrders from "./pages/StandBy/index.jsx";

import AllPostsPage from "./pages/Posts/AllPosts/index.jsx";

import OrderSummaryView from "./pages/Statistics/Product/index.jsx";

import CommoditiesStats from "./pages/Statistics/Ingridients/index.jsx";

import ThemeTester from "./pages/ThemeTester.js";

import NewPostForm from "./pages/Posts/AddPost";

import CateringOrdersCalendar from "pages/Orders/OrdersCalendar/index.jsx";
import EmployeePage from "./pages/Users/Employee/index.jsx";
import CateringChart from "./pages/Statistics/Order/index.jsx";

import SearchPage from "./pages/Search/index.jsx";

import MyComponent from "./pages/Mobile/NewProduct/index.jsx";

import withAuthentication from "./components/ProtectedRoute";

import NotFoundPage from "./notFound.js";

import { checkAuthentication } from 'actions/authActions'; // Import the checkAuthentication action




// NEW BACKEND

import AuthRoutes from './authRoutes';



const AuthenticatedHomePage = withAuthentication(HomePage);
const AuthenticatedEditCateringOrder = withAuthentication(EditCateringOrder);
const AuthenticatedCateringOrdersCalendar = withAuthentication(
  CateringOrdersCalendar
);
const AuthenticatedEmployeePage = withAuthentication(EmployeePage);
const AuthenticatedCateringChart = withAuthentication(CateringChart);
const AuthenticatedCateringTable = withAuthentication(CateringTable);
const AuthenticatedProductList = withAuthentication(ProductList);
const AuthenticatedProductDetails = withAuthentication(ProductDetails);
const AuthenticatedOrderSummaryView = withAuthentication(OrderSummaryView);
const AuthenticatedNewPostForm = withAuthentication(NewPostForm);
const AuthenticatedAllPostsPage = withAuthentication(AllPostsPage);
const AuthenticatedSearchPage = withAuthentication(SearchPage);

const AuthenticatedSinglePostPage = withAuthentication(SinglePostPage);

const AuthenticatedBookedOrder = withAuthentication(Booked);

const AuthenticatedMyComponent = withAuthentication(MyComponent);

const AuthenticatedCreateOrders = withAuthentication(CreateOrders);

const AuthenticatedCommoditiesStats = withAuthentication(CommoditiesStats);

const AuthenticatedStandByOrders = withAuthentication(StandByOrders);

function useScrollToTop() {
  const { pathname } = useLocation();
  var storeName = localStorage.getItem('shopName');
  var firstName = localStorage.getItem('first_name');
  
  // Set default values if not found
  storeName = storeName || "Ej inloggad"; 
  firstName = firstName || "Inget first_name";

  useEffect(() => {
    window.scrollTo(0, 0);
    if (window.gtag) {
      window.gtag('config', 'G-NZPYW0P2FT', {
        'page_path': pathname,
        'user_properties': { // Use 'user_properties' to set user scoped custom dimensions
          'shop_name': storeName,
          'first_name': firstName
        }
      });
    }
  }, [pathname, storeName, firstName]); // We've added storeName and firstName as dependencies to the useEffect
}

// component that doesnt render anything.
// Makes us scroll to top within the app when switching pages
function ScrollToTop() {
  useScrollToTop();
  return null;
}

function App() {
  const mode = useSelector((state) => state.global.mode);
  const dispatch = useDispatch();
  const [initialized, setInitialized] = useState(false);

  useEffect(() => {
    const checkAuth = async () => {
        try {
            const response = await fetch(`${process.env.REACT_APP_BASE_URL || 'http://localhost:8000'}/api/check-auth/`, {
                credentials: 'include',
            });

            const data = await response.json();

            if (data.isAuthenticated) {
                dispatch(checkAuthentication(data.user));
            }
        } catch (error) {
            console.error("Error checking authentication", error);
        } finally {
            setInitialized(true);
        }
    };

    checkAuth();
}, [dispatch]);

  const queryClient = new QueryClient();
  const theme = useMemo(() => createTheme(themeSettings(mode), svSE), [mode]);

  if (!initialized) {
    return null;
  }

  return (
    <div className="app">
      <QueryClientProvider client={queryClient}>
        <BrowserRouter>
          <ScrollToTop />
          <ThemeProvider theme={theme}>
            <CssBaseline />


            <Routes>

            <Route path="*" element={<AuthRoutes />} />
              <Route path="*" element={<NotFoundPage />} /> {/* Not Found outside the layout */}

              <Route path="old" element={<Navigate to="/home" replace />} />

              <Route path="old/*" element={<Layout />}>
                <Route path="" element={<Navigate to="/home" replace />} />
                <Route path="home" element={<AuthenticatedHomePage />} />
                <Route path="catering/:id/edit" element={<AuthenticatedEditCateringOrder />} />
                <Route path="calanader" element={<AuthenticatedCateringOrdersCalendar />} />
                <Route path="catering-stats" element={<AuthenticatedCateringChart />} />
                <Route path="catering" element={<AuthenticatedCateringTable />} />
                <Route path="products" element={<AuthenticatedProductList />} />
                <Route path="count" element={<AuthenticatedOrderSummaryView />} />
                <Route path="product/:id" element={<AuthenticatedProductDetails />} />
                <Route path="commodities-stats" element={<AuthenticatedCommoditiesStats />} />
                <Route path="posts/:postId" element={<AuthenticatedSinglePostPage />} />
                <Route path="theme" element={<ThemeTester />} />
                <Route path="add-post" element={<AuthenticatedNewPostForm />} />
                <Route path="posts" element={<AuthenticatedAllPostsPage />} />              
                <Route path="users" element={<AuthenticatedEmployeePage />} />
                <Route path="add-user" element={<AddUserPage />} />
                <Route path="search" element={<AuthenticatedSearchPage />} />
                <Route path="add-product" element={<AuthenticatedMyComponent />} />
                <Route path="generate-orders" element={<AuthenticatedCreateOrders />} />
                <Route path="bokforing" element={<AuthenticatedBookedOrder />} />
                <Route path="stand" element={<AuthenticatedStandByOrders />} />
              </Route>




            </Routes>
          </ThemeProvider>
        </BrowserRouter>
        <ReactQueryDevtools initialIsOpen={false} />
      </QueryClientProvider>
    </div>
  );
}

export default App;
