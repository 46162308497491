import {
  Button,
  Typography,
  Box,
  useTheme,
  TextField,
  Slider,
  Switch,
  FormControlLabel,
  Checkbox,
  Snackbar,
  Select,
  MenuItem,
} from "@mui/material";
import { Link } from "react-router-dom";
import { useState } from "react";
import Alert from "@mui/material/Alert";

function ThemeTester() {
  const theme = useTheme();
  const [showSnackbar, setShowSnackbar] = useState(false);

  const handleClick = () => {
    setShowSnackbar(true);
    setTimeout(() => {
      setShowSnackbar(false);
    }, 3000);
  };

  return (
    <Box sx={{ padding: "1rem" }}>
      <Typography variant="h1">Heading 1</Typography>
      <Typography variant="h2">Heading 2</Typography>
      <Typography variant="h3">Heading 3</Typography>
      <Typography variant="h4">Heading 4</Typography>
      <Typography variant="h5">Heading 5</Typography>
      <Typography variant="h6">Heading 6</Typography>
      <Box sx={{ display: "flex", gap: "1rem", flexWrap: "wrap" }}>
        {Object.entries(theme.palette.primary).map(([key, value]) => (
          <Box
            key={key}
            sx={{
              backgroundColor: value,
              color: theme.palette.neutral[0],
              padding: "1rem",
            }}
          >
            Primary {key}: {value}
          </Box>
        ))}
        {Object.entries(theme.palette.secondary).map(([key, value]) => (
          <Box
            key={key}
            sx={{
              backgroundColor: value,
              color: theme.palette.neutral[0],
              padding: "1rem",
            }}
          >
            Secondary {key}: {value}
          </Box>
        ))}
        {Object.entries(theme.palette.neutral).map(([key, value]) => (
          <Box
            key={key}
            sx={{
              backgroundColor: value,
              color: theme.palette.neutral[1000],
              padding: "1rem",
            }}
          >
            Neutral {key}: {value}
          </Box>
        ))}
      </Box>
      <Button
        onClick={handleClick}
        variant="contained"
        sx={{ margin: "1rem 0", backgroundColor: theme.palette.primary[500] }}
      >
        Show Snackbar
      </Button>
      <Snackbar
        open={showSnackbar}
        autoHideDuration={3000}
        onClose={() => setShowSnackbar(false)}
      >
        <Alert
          onClose={() => setShowSnackbar(false)}
          severity="info"
          sx={{ width: "100%" }}
        >
          This is a snackbar — check it out!
        </Alert>
      </Snackbar>
      <Button
        component={Link}
        to="/"
        variant="outlined"
        sx={{ margin: "1rem 0", borderColor: theme.palette.primary[500] }}
      >
        Outlined Button
      </Button>
      <TextField
        label="Test Input"
        variant="outlined"
        sx={{ margin: "1rem 0", width: "100%" }}
      />
      <Select defaultValue={10} sx={{ margin: "1rem 0", width: "100%" }}>
        <MenuItem value={10}>Option 1</MenuItem>
        <MenuItem value={20}>Option 2</MenuItem>
        <MenuItem value={30}>Option 3</MenuItem>
      </Select>
      <Slider
        defaultValue={30}
        aria-label="Default"
        valueLabelDisplay="auto"
        sx={{ margin: "1rem 0", width: "100%" }}
      />
      <FormControlLabel
        control={
          <Switch defaultChecked sx={{ margin: "1rem 0" }} color="secondary" />
        }
        label="Switch Test"
      />
      <FormControlLabel
        control={
          <Checkbox
            defaultChecked
            sx={{ margin: "1rem 0" }}
            color="secondary"
          />
        }
        label="Checkbox Test"
      />
    </Box>
  );
}

export default ThemeTester;
