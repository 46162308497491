import { useQuery } from 'react-query';
import { useContext } from 'react';
import AuthContext from 'context/AuthContext';  // Make sure to provide the correct path to your AuthContext

const fetchOrdersAndProductsBetweenDates = async (updateToken, logoutUser, startDate, endDate, retryCount = 0) => {
  const baseUrl = process.env.REACT_APP_BASE_URL ? `${process.env.REACT_APP_BASE_URL}` : 'http://localhost:9000';

  const response = await fetch(
    `${baseUrl}/shopify-api/orders/commodities/?startDate=${startDate.toISOString()}&endDate=${endDate.toISOString()}`,
    {
      headers: {
        'Content-Type':'application/json',
      },
      credentials: 'include',
    }
  );

  if ((response.status === 401 || response.status === 403) && retryCount < 1) {
    await updateToken();
    return fetchOrdersAndProductsBetweenDates(updateToken, logoutUser, startDate, endDate, retryCount + 1);
  } else if ((response.status === 401 || response.status === 403) && retryCount >= 1) {
    logoutUser();
    throw new Error('Authentication failed. Logging out.');
  }

  if (!response.ok) {
    throw new Error(`HTTP error! status: ${response.status}`);
  }

  return await response.json();
};

export const useOrdersAndProductsBetweenDates = (startDate, endDate) => {
  const { updateToken, logoutUser } = useContext(AuthContext);
  
  const { data, isLoading, isError } = useQuery(
    ["ordersAndProductsBetween", startDate, endDate],
    () => fetchOrdersAndProductsBetweenDates(updateToken, logoutUser, startDate, endDate, 0),
    {
      enabled: !!startDate && !!endDate,
      retry: 1
    }
  );
    
  return {
    orders: data,
    isLoading, 
    isError 
  };
};

export default useOrdersAndProductsBetweenDates;
