import React, { useState } from "react";
import useShopifyProducts from "../../../Hooks/Products/useShopifyProducts";
import ProductStats from "components/ProductStats";
import TextField from "@mui/material/TextField";
import {
  Card,
  CardContent,
  CardMedia,
  Grid,
  Typography,
  Container,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Switch,
  FormControlLabel,
  Divider,
  Skeleton,
  Box,
  useTheme,
  Select, MenuItem, InputLabel, FormControl,
  Chip

} from "@mui/material";
import { Link } from "react-router-dom";
import { Link as RouterLink } from "react-router-dom";
import Header from "components/Header";
import translations from "translations";
import LazyLoad from "react-lazyload";
import LoadingComponent from "components/LoadingComponent";
import ErrorComponent from "components/ErrorComponent";
import HighlightOffIcon from '@mui/icons-material/HighlightOff';

const ProductList = () => {
  const theme = useTheme();
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [isTableView, setIsTableView] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");

  const { products, isLoading, isError } = useShopifyProducts();
  const [selectedTags, setSelectedTags] = useState([]);
  console.log(products)
  let filteredProducts = [];
  
  if (products?.length) {
    filteredProducts = products.filter((product) =>
      product.title.toLowerCase().includes(searchTerm.toLowerCase())
    );
  } else {
    filteredProducts = [];
  }

  const handleTagChange = (event) => {
    setSelectedTags(event.target.value);
  };

  const handleTagDelete = (tagToDelete) => {
    setSelectedTags((tags) => tags.filter((tag) => tag !== tagToDelete));
  };
  

  const getUniqueTags = (products) => {
    const tags = products.reduce((acc, product) => {
      if (product.tags && product.tags.length > 0) {
        return acc.concat(product.tags);
      }
      return acc;
    }, []);
    return [...new Set(tags)];
  };
  
  if (products?.length) {
    filteredProducts = products.filter((product) =>
      product.title.toLowerCase().includes(searchTerm.toLowerCase()) &&
      (selectedTags.length === 0 ||
        selectedTags.some((tag) => product.tags.includes(tag)))
    );
  } else {
    filteredProducts = [];
  }
  

  if (isLoading) {
    return <LoadingComponent />;
  }

  if (isError) {
    return <ErrorComponent error={isError} />;
  }

  if (selectedProduct) {
    return (
      <Box>
        <ProductStats productId={selectedProduct} />
        <Button
          variant="contained"
          color="primary"
          onClick={() => setSelectedProduct(null)}
          sx={{ marginTop: "1rem" }}
        >
          Tillbaka till produkter
        </Button>
      </Box>
    );
  }

  const toggleView = () => {
    setIsTableView(!isTableView);
  };

  return (
    <Container>
      <Box mt={4} mb={4}>
        <Header
          title={`Produkter`}
          subtitle="Se alla produkter som finns på hemsidan!"
        />
      </Box>
      <Divider
        sx={{ marginTop: theme.spacing(2), marginBottom: theme.spacing(2) }}
      />
      <ProductStats products={products} />
      <TextField
        style={{
          marginTop: "20px",
          marginBottom: "20px",
        }}
        fullWidth
        variant="outlined"
        label="Sök efter produkter"
        value={searchTerm}
        onChange={(event) => setSearchTerm(event.target.value)}
        autoComplete="off"
      />

      <FormControl 
        fullWidth 
        variant="outlined" 
        sx={{ 
          margin: '10px 0', // Reduced margin
          '.MuiOutlinedInput-inputMultiline': { // Style to decrease the height of the input
            padding: '8px 12px'
          },
          '.MuiSelect-select': { // Padding to the Select to avoid cutting off of text
            paddingRight: '22px'
          }
        }}
      >
        <InputLabel id="tag-multiselect-label">Filtrera på kategorier</InputLabel>
        <Select
          labelId="tag-multiselect-label"
          id="tag-multiselect"
          multiple
          value={selectedTags}
          onChange={handleTagChange}
          label="Filtrera på kategorier"
          renderValue={(selected) => (
            <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}> 
              {selected.map((value) => (
                <Chip
                  key={value}
                  label={value}
                  clickable
                  deleteIcon={
                    <HighlightOffIcon
                      style={{color: "#ffffff"}}
                      onMouseDown={(event) => event.stopPropagation()}
                    />
                  }
                  onDelete={(e) =>handleTagDelete(value)}
                  onClick={() => console.log("clicked chip")}

                  sx={{
                    backgroundColor: '#79A8A9',
                    color: 'primary.contrastText',
                    borderRadius: '4px',
                    border: '1px solid transparent',
                    ':hover, :focus': {
                      backgroundColor: 'primary.dark',
                      borderColor: 'primary.light',
                    }
                  }}
                />
              ))}
            </Box>
          )}
        >
          {getUniqueTags(products).map((tag) => (
            <MenuItem key={tag} value={tag}>
              {tag}
            </MenuItem>
          ))}
        </Select>
      </FormControl>

      <FormControlLabel
        control={<Switch checked={isTableView} onChange={toggleView} />}
        label="Visa som lista"
      />


      {isTableView ? (
        <TableContainer component={Paper}>
          <Table aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell
                  style={{
                    fontSize: "15px",
                    fontWeight: "700",
                    color: "#2c3e50",
                  }}
                >
                  Bild
                </TableCell>
                <TableCell
                  style={{
                    fontSize: "15px",
                    fontWeight: "600",
                    color: "#2c3e50",
                  }}
                >
                  Namn
                </TableCell>
                <TableCell
                  style={{
                    fontSize: "15px",
                    fontWeight: "600",
                    color: "#2c3e50",
                  }}
                >
                  ID
                </TableCell>
                <TableCell
                  style={{
                    fontSize: "15px",
                    fontWeight: "600",
                    color: "#2c3e50",
                  }}
                >
                  Status
                </TableCell>
                <TableCell
                  style={{
                    fontSize: "15px",
                    fontWeight: "600",
                    color: "#2c3e50",
                  }}
                >
                  Pris
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {filteredProducts.map((product) => (
                <TableRow
                  key={product.id}
                  sx={{
                    "&:hover": {
                      backgroundColor: "#f1f6f6",
                    },
                    cursor: "pointer",
                  }}
                >
                  <TableCell>
                    <RouterLink to={`/product/${product.id}`}>
                      <LazyLoad once>
                        <img
                          src={
                            product.images?.[0]?.src ||
                            "https://via.placeholder.com/150"
                          }
                          alt={product.title}
                          style={{
                            width: "100px",
                            height: "60px",
                            objectFit: "cover",
                          }}
                        />
                      </LazyLoad>
                    </RouterLink>
                  </TableCell>
                  <TableCell>
                    <RouterLink
                      to={`/product/${product.id}`}
                      style={{ textDecoration: "none" }}
                    >
                      <Typography
                        style={{
                          fontSize: "15px",
                          fontWeight: "500",
                          color: "#2c3e50",
                        }}
                      >
                        {product.title}
                      </Typography>
                    </RouterLink>
                  </TableCell>
                  <TableCell>
                    <RouterLink
                      to={`/product/${product.id}`}
                      style={{ textDecoration: "none" }}
                    >
                      <Typography
                        style={{ fontSize: "14px", color: "#2c3e50" }}
                      >
                        {product.id}
                      </Typography>
                    </RouterLink>
                  </TableCell>
                  <TableCell>
                    <RouterLink
                      to={`/product/${product.id}`}
                      style={{ textDecoration: "none" }}
                    >
                      <Typography
                        style={{ fontSize: "14px", color: "#2c3e50" }}
                      >
                        {translations.productStatus[product.status]}
                      </Typography>
                    </RouterLink>
                  </TableCell>

                  <TableCell>
                    <RouterLink
                      to={`/product/${product.id}`}
                      style={{ textDecoration: "none" }}
                    >
                      <Typography
                        style={{ fontSize: "14px", color: "#2c3e50" }}
                      >
                        {product.variants?.[0]?.price || "N/A"}kr
                      </Typography>
                    </RouterLink>
                  </TableCell>
                  <TableCell>
                    <RouterLink
                      to={`/product/${product.id}`}
                      style={{ textDecoration: "none" }}
                    >
                      <Typography
                        style={{ fontSize: "14px", color: "#2c3e50" }}
                      >
                        {product.order_count}
                      </Typography>
                    </RouterLink>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      ) : (
        <Grid container spacing={4}>
          {isLoading
            ? Array.from(new Array(6)).map((_, index) => (
                <Grid item key={index} xs={12} sm={6} md={4}>
                  <Card>
                    <Skeleton variant="rectangular" width="100%" height={140} />
                    <CardContent>
                      <Typography variant="h4" component="div">
                        <Skeleton />
                      </Typography>
                      <Typography
                        variant="body1"
                        color="text.secondary"
                        sx={{ marginTop: 1.1 }}
                      >
                        <Skeleton />
                      </Typography>
                      <Typography
                        variant="body1"
                        color="text.secondary"
                        sx={{ marginTop: 0.8 }}
                      >
                        <Skeleton />
                      </Typography>
                      <Typography
                        variant="body1"
                        color="text.secondary"
                        sx={{ marginTop: 0.8 }}
                      >
                        <Skeleton />
                      </Typography>
                      <Typography
                        variant="body1"
                        color="text.secondary"
                        sx={{ marginTop: 0.8 }}
                      >
                        <Skeleton />
                      </Typography>
                    </CardContent>
                    <Button
                      variant="contained"
                      color="primary"
                      sx={{ margin: "1rem", visibility: "hidden" }}
                    >
                      Se mer
                    </Button>
                  </Card>
                </Grid>
              ))
            : filteredProducts.map((product) => (
                <Grid item key={product.id} xs={12} sm={6} md={4}>
                  <Card>
                    <CardMedia
                      component="img"
                      height="200"
                      image={
                        product.images?.[0]?.src ||
                        "https://via.placeholder.com/150"
                      }
                      alt={product.title}
                    />
                    <CardContent>
                      <Typography variant="h4" component="div">
                        {product.title}
                      </Typography>
                      <Typography
                        variant="body1"
                        color="text.secondary"
                        sx={{ marginTop: 0.3 }}
                      >
                        {product.id}
                      </Typography>
                      <Typography
                        variant="body1"
                        color="text.secondary"
                        sx={{ marginTop: 0.8 }}
                      >
                        Pris: <b>{product.variants?.[0]?.price || "N/A"}kr</b>
                      </Typography>
                      <Typography
                        variant="body1"
                        color="text.secondary"
                        sx={{ marginTop: 0.8 }}
                      >
                        Status:{" "}
                        <b>{translations.productStatus[product.status]}</b>
                      </Typography>
                    </CardContent>
                    <Button
                      component={Link}
                      to={`/product/${product.id}`}
                      variant="contained"
                      color="primary"
                      sx={{
                        marginLeft: "1rem",
                        marginBottom: "1.5rem",
                        marginTop: "0.5rem",
                        color: "white",
                        backgroundColor: "#79A8A9",
                        textTransform: "none",
                        fontWeight: "bold",
                      }}
                    >
                      Se mer
                    </Button>
                  </Card>
                </Grid>
              ))}
        </Grid>
      )}
    </Container>
  );
};

export default ProductList;
