import React from 'react';
import { Card, CardContent, Typography, Box, Chip, Button } from '@mui/material'; // Add other required imports here
import { Link } from 'react-router-dom'; // If you're using react-router
import  getTagColor  from '../../../utilities/getTagColor'; // Example utility functions, adjust paths accordingly
import  stripHtml  from '../../../utilities/stripHtml'; // Example utility functions, adjust paths accordingly


function PostPreview({ post }) {
    if (!post) {
      return null; // or return a loading indicator, or an error message, etc.
    }

    const previewTextLength = 150; // set your desired length here

    const strippedContent = stripHtml(post.content);
    const displayText = strippedContent.length > previewTextLength 
                    ? strippedContent.substring(0, previewTextLength) + "..." 
                    : strippedContent;

    let postDate = "";
    if (
      post.created_at
    ) {
      postDate = new Date(
        post.created_at
      ).toLocaleDateString();
    }

    return (
      <Card sx={{ width: "100%", display: "flex", flexDirection: "column" }}>
          <CardContent
            sx={{
              flex: "1 0 auto",
              minHeight: "150px",
              height: "165px",
              overflow: "hidden",
            }}
          >
            <Typography
              variant="h6"
              component="h2"
              sx={{ fontWeight: "bold", marginBottom: "6px" }}
              align="left"
            >
              {post.title}
            </Typography>
            <Typography variant="overline" color="text.secondary">
              {postDate}
            </Typography>
            <Typography variant="body2" align="left">
              {displayText}
            </Typography>
          </CardContent>
        {post.Tags && post.Tags.length > 0 && post.Tags[0] !== "" && (
          <Box sx={{ display: "flex", flexWrap: "wrap", mt: 1, ml: 1, mr: 1 }}>
            {post.tags.map((tag, index) => (
              <Chip
                key={`${tag}-${index}`}
                label={String(tag)} // Convert the value to a string
                sx={{
                  marginTop: 0.5,
                  marginBottom: 0.5,
                  marginLeft: 0.5,
                  marginRight: 0.5,
                }}
                style={{
                  backgroundColor: getTagColor(tag),
                  color: "white",
                }}
              />
            ))}
          </Box>
        )}
        <Button
          variant="contained"
          sx={{
            backgroundColor: "#C00020", // The color you want
            "&:hover": {
              backgroundColor: "#A3001B", // You may want to darken this color on hover for a nice effect
              color: "#ffffff",
              marginBottom: 0,
            },
            color: "white", // text color
            mt: 2,
            textTransform: "none",
            fontWeight: "bold",
          }}
          component={Link}
          to={`/posts/${post?.id}`}
        >
          Läs mer
        </Button>
      </Card>
    );
  }


  export default PostPreview;
