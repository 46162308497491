import React, { useState, useEffect } from "react";
import { Select, MenuItem, InputBase } from "@mui/material";

// HOOKS
import useUpdateOrder from "Hooks/Catering/useUpdateOrder.js";
import GetUpcomingOrders from "Hooks/Catering/useGetUpcommingOrders";

// UTILS AND STYLING/THEME
import { getStatusFromTags } from "utilities/getStatusFromTags";
import { styled } from "@mui/system";

//COMPONENTS
import CustomSnackbar from "components/SnackBar.jsx";
import GetPastOrders from "Hooks/Catering/useGetPastOrders";

const BootstrapInput = styled(InputBase)(({ theme }) => ({
  "&.MuiInputBase-root": {
    "label + &": {
      marginTop: theme.spacing(3),
    },
  },
  "& .MuiInputBase-input": {
    borderRadius: 100,
    position: "relative",
    border: "1px solid",
    width: "85px", // Set the desired width here
    fontSize: 12,
    padding: "10px 26px 10px 12px",
    transition: theme.transitions.create(["border-color", "box-shadow"]),
  },
}));

function OrderDropdown({ id, ordersTags, theme, disableSnackbar}) {
  // HOOKS USAGE

  // define seperate refetches because now we have two different hooks
  const { refetch: refetchUpcoming } = GetUpcomingOrders();
  const { refetch: refetchPast } = GetPastOrders();

  const { updateOrder, open, setOpen } = useUpdateOrder(refetchUpcoming, refetchPast);

  // STATES
  const [selectedTag, setSelectedTag] = useState(getStatusFromTags(ordersTags));
  const [tagEdited, setTagEdited] = useState(false);

  // CONSTS
  const colorMap = {
    Accepterad: theme.palette.status.accepterad,
    Färdig: theme.palette.status.fardig,
    Levererad: theme.palette.status.levererad,
    Mottagen: theme.palette.status.mottagen,
  };
  const color = colorMap[selectedTag];

  // HANDLERS AND EFFECTS

  const handleSnackbarClose = () => {
    setOpen(false);
  };

  const handleChange = (event) => {
    setSelectedTag(event.target.value);
    setTagEdited(true);
  };

  useEffect(() => {
    setSelectedTag(getStatusFromTags(ordersTags));
  }, [ordersTags]);

  useEffect(() => {
    if (tagEdited) {
      const tagsArray = ordersTags ? ordersTags.split(", ") : [];
      const statusIndex = tagsArray.findIndex((tag) =>
        tag.startsWith("status:")
      );

      if (statusIndex > -1) {
        tagsArray[statusIndex] = `status: ${selectedTag}`;
      } else {
        tagsArray.push(`status: ${selectedTag}`);
      }

      const newTags = tagsArray.join(", ");

      const updateData = { tags: newTags };
      updateOrder(id, updateData)
        .then(() => {
          setTagEdited(false);
        })
        .catch((error) => console.error("Error updating order: ", error));
    }
  }, [id, selectedTag, tagEdited, updateOrder, refetchUpcoming, refetchPast, ordersTags]);

  return (
    <React.Fragment>
      <Select
        value={selectedTag}
        onChange={handleChange}
        sx={{ color: color, fontSize: 12 }}
        input={<BootstrapInput />}
      >
        <MenuItem value="" disabled>
          <em>Välj status</em>
        </MenuItem>
        <MenuItem value="Mottagen">Mottagen</MenuItem>
        <MenuItem value="Accepterad">Accepterad</MenuItem>
        <MenuItem value="Färdig">Färdig</MenuItem>
        <MenuItem value="Levererad">Levererad</MenuItem>
      </Select>

      {!disableSnackbar &&
      <CustomSnackbar
        open={open}
        handleClose={handleSnackbarClose}
        message="Uppdatering av order lyckades!"
        severity="success"
      />}
    </React.Fragment>
  );
}

export default OrderDropdown;
