import { useQuery } from 'react-query';
import { useContext } from 'react';
import AuthContext from 'context/AuthContext';  // Make sure to provide the correct path to your AuthContext


const fetchOrder = async (updateToken, logoutUser, id, retryCount = 0) => {
  const baseUrl = process.env.REACT_APP_BASE_URL ? process.env.REACT_APP_BASE_URL : 'http://localhost:8000';
  
  const response = await fetch(`${baseUrl}/shopify-api/orders/${id}`, {
    headers: {
      'Content-Type':'application/json',
    },
    credentials: 'include',
  });

  if ((response.status === 401 || response.status === 403) && retryCount < 1) {
    // If unauthorized and haven't retried yet, refresh token and try again
    await updateToken();
    return fetchOrder(updateToken, logoutUser, id, retryCount + 1);
  } else if ((response.status === 401 || response.status === 403) && retryCount >= 1) {
    // If unauthorized and this was a retry, log the user out
    logoutUser();
    throw new Error('Authentication failed. Logging out.');
  }

  if (!response.ok) {
    throw new Error(`HTTP error! status: ${response.status}`);
  }

  const data = await response.json();

  const modifyOrder = (order) =>
{
    const refundedLineItemIds = order.refunds?.flatMap(refund => refund.refund_line_items.map(item => item.line_item_id)) || [];
    
    // Filter out line items that are refunded
    const lineItems = order.line_items
      .filter(item => !refundedLineItemIds.includes(item.id))

    return {
      ...order,
      lineItems,
    };
  };

  return modifyOrder(data);
};

export const GetOrder = (id) => {
  const { updateToken, logoutUser } = useContext(AuthContext); // <-- Extract context values
  const { data: order, isLoading, isError } = useQuery(['order', id], () => fetchOrder(updateToken, logoutUser, id, 0));

  return { order, isLoading, isError };
};


export default GetOrder;
