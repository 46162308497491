import { useState, useContext } from 'react';
import AuthContext from 'context/AuthContext';  // Make sure to provide the correct path to your AuthContext

const sanitizeString = (str) => {
  return str.replace(/[^a-zåäö0-9 ,.!? \n]/gi, "");
};

const validateData = (data) => {
  if (data.note) {
    data.note = sanitizeString(data.note);
  }
  return data;
};

const useUpdateOrder = (refetchUpcoming, refetchPast) => { 
  const [open, setOpen] = useState(false);
  const { updateToken, logoutUser } = useContext(AuthContext);

  const updateOrder = async (id, rawData, retryCount = 0) => {
    try {
      const data = validateData(rawData);

      if (!data.shipping_address) {
        data.shipping_address = {
          "first_name": "Default First Name",
          "last_name": "Default Last Name",
          "address1": "Default Address",
          "phone": "0000000000",
          "city": "Default City",
          "zip": "00000",
          "province": "",
          "country": "Sweden",
          "address2": null,
          "company": null,
          "latitude": null,
          "longitude": null,
          "name": "Default Name",
          "country_code": "se",
          "province_code": null
        };
      }

      const baseUrl = process.env.REACT_APP_BASE_URL ? process.env.REACT_APP_BASE_URL : 'http://localhost:8000';

      const response = await fetch(`${baseUrl}/shopify-api/orders/${id}/update/`, {
        method: "PUT",
        headers: {
          'Content-Type':'application/json',
        },
        credentials: 'include',
        body: JSON.stringify(data),
      });

      if ((response.status === 401 || response.status === 403) && retryCount < 1) {
        await updateToken();
        return updateOrder(id, rawData, retryCount + 1);
      } else if ((response.status === 401 || response.status === 403) && retryCount >= 1) {
        logoutUser();
        throw new Error('Authentication failed. Logging out.');
      }

      if (!response.ok) {
        throw new Error(await response.text()); 
      } else {
        setOpen(true);
        if (refetchUpcoming) {
          refetchUpcoming();
        }
        if (refetchPast) {
          refetchPast();
        }
      }

      return await response.json();

    } catch (error) {
      console.error(error);
      throw error;
    }
  };

  return { updateOrder, open, setOpen };
};

export default useUpdateOrder;
