// The order details shown in the grid in edit order.
import * as React from 'react';
import { Typography, Grid, ListItem, ListItemText } from "@mui/material";
import { getTotalPrice } from 'utilities/getTotalPrice';
import data from "data/data.json" 

import { getDeliveryDay } from 'utilities/getNoteAttributes';



function getDeliveryCost(order) {
  var shopName = localStorage.getItem("shopName");
  const shopData = data.find(entry => entry.store === shopName);

  if (!shopData){
    return 0;
  }

  if (order.financial_status !== "paid"){
    return shopData.price;
  }
  return 0;
}
export class OrderDetailsGrid extends React.Component {
    render () {
    
        const { order, theme, formatDate, translations, booked, handleBookedChange, getAllVendors } = this.props;

        return (
                  <Grid container spacing={0.5}>
                    <Grid item xs={4}>
                      <ListItem>
                        <ListItemText variant="h5" primary={<Typography style={{ fontSize: '1rem', fontWeight: "600",color: theme.palette.green.main }}>Email</Typography>} secondary={<Typography style={{ fontSize: '0.8rem' }}>{order.customer?.email}</Typography>} />
                      </ListItem>
                    </Grid>
                    <Grid item xs={4}>
                      <ListItem>
                        <ListItemText primary={<Typography style={{ fontSize: '1rem', fontWeight: "600",color: theme.palette.green.main }}>Telefon</Typography>} secondary={<Typography style={{ fontSize: '0.8rem' }}>{order.billing_address?.phone  ? order.billing_address.phone  : "Ej angivet"}</Typography>} />
                      </ListItem>
                    </Grid>
                    <Grid item xs={4}>
                      <ListItem>
                        <ListItemText variant="h5" primary={<Typography style={{ fontSize: '1rem' , fontWeight: "600",color: theme.palette.green.main}}>Skapad</Typography>} secondary={<Typography style={{ fontSize: '0.8rem' }}>{formatDate(order.created_at)}</Typography>} />
                      </ListItem>
                    </Grid>
                    <Grid item xs={4}>
                      <ListItem>
                        <ListItemText variant="h5" primary={<Typography style={{ fontSize: '1rem', fontWeight: "600",color: theme.palette.green.main }}>Finansiell status</Typography>} secondary={<Typography style={{ fontSize: '0.8rem' }}>{translations.financialStatus[order.financial_status]}</Typography>} />
                      </ListItem>
                    </Grid>
                    <Grid item xs={4}>
                      <ListItem>
                        <ListItemText variant="h5" primary={<Typography style={{ fontSize: '1rem', fontWeight: "600",color: theme.palette.green.main }}>Totalt pris</Typography>} secondary={<Typography style={{ fontSize: '0.8rem' }}>{getTotalPrice(order) + " kr"}</Typography>} />
                      </ListItem>
                    </Grid>
                    <Grid item xs={4}>
                      <ListItem>
                          <ListItemText 
                            primary={<Typography variant="h5" style={{ fontSize: '1rem', fontWeight: "600", color: theme.palette.green.main }}>Leverantörer</Typography>} 
                            secondary={
                              <Typography variant="h5" style={{ fontSize: '0.8rem' }}>
                              {getAllVendors(order)}
                            </Typography>
                            } 
                          />
                      </ListItem>
                    </Grid>
                    <Grid item xs={4}>
                    <ListItem>
                      <ListItemText 
                        primary={<Typography variant="h5" style={{ fontSize: '1rem', fontWeight: "600", color: theme.palette.green.main }}>Leveransdatum</Typography>} 
                        secondary={
                          <Typography variant="h5" style={{ fontSize: '0.8rem' }}>
                            {(order.note_attributes?.find(attr => attr.name === 'Leveransdatum')?.value) || 'Saknas'}
                          </Typography>
                        } 
                      />
                    </ListItem>
                  </Grid>
                  <Grid item xs={4}>
                    <ListItem>
                      <ListItemText 
                        primary={<Typography variant="h5" style={{ fontSize: '1rem', fontWeight: "600", color: theme.palette.green.main }}>Leveransdag</Typography>} 
                        secondary={
                          <Typography variant="h5" style={{ fontSize: '0.8rem' }}>
                            {getDeliveryDay(order) || 'Saknas'}
                          </Typography>
                        } 
                      />
                    </ListItem>
                  </Grid>
                  <Grid item xs={4}>
                    <ListItem>
                      <ListItemText 
                        primary={<Typography variant="h5" style={{ fontSize: '1rem', fontWeight: "600", color: theme.palette.green.main }}>Leveranstid</Typography>} 
                        secondary={
                          <Typography style={{ fontSize: '0.8rem' }}>
                            {(order.note_attributes?.find(attr => attr.name === 'Leveranstid')?.value) || 'Saknas'}
                          </Typography>
                        } 
                      />
                    </ListItem>
                    </Grid>
                    <Grid item xs={4}>
                     <ListItem>
                      <ListItemText
                        disableTypography
                        primary={<Typography variant="h5" style={{ fontSize: '1rem', fontWeight: "600", color: theme.palette.green.main }}>Tilläggsavgift</Typography>}
                        secondary={
                          <Typography style={{ fontSize: '0.8rem' }}>
                            {getDeliveryCost(order)}kr
                          </Typography>
                        }
                        />
                      </ListItem>
                    </Grid>
                    <Grid item xs={4}>
                    <ListItem>
                        <ListItemText 
                          primary={<Typography variant="h5" style={{ fontSize: '1rem', fontWeight: "600", color: theme.palette.green.main }}>Bokförd</Typography>} 
                          secondary={
                            <input 
                                type="checkbox" 
                                checked={booked} 
                                onChange={handleBookedChange} 
                                component={'div'}
                            />
                        } 
                        
                        />
                      </ListItem>
                    </Grid>       
                    
                </Grid>
        );
    }
    
}
