import React from 'react';
import GetUpcomingOrders from "../../Hooks/Catering/useGetUpcommingOrders";
import { Paper } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import Timeline from '@mui/lab/Timeline';
import TimelineItem from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineOppositeContent from '@mui/lab/TimelineOppositeContent';
import TimelineDot from '@mui/lab/TimelineDot';
import Typography from '@mui/material/Typography';

function StandByOrders() {
  const storeId = localStorage.getItem("storeId");
  const { orders: cateringOrders, isLoading, isError } = GetUpcomingOrders(storeId);

  const theme = useTheme();
  
  const colorMap = {
    Accepterad: theme.palette.status.accepterad,
    Färdig: theme.palette.status.fardig,
    Levererad: theme.palette.status.levererad,
    Mottagen: theme.palette.status.mottagen,
  };

  function extractStatusFromTags(tags) {
    if (tags.includes("status:")) {
      const status = tags.split("status:")[1].split(",")[0].trim();
      return status === "mottagen" ? "Mottagen" : status;
    }
    return "Mottagen"; // return "Mottagen" if no status tag found
  }

  if (isLoading) {
    return <div>Loading...</div>
  }

  if (isError) {
    return <div>Error!</div>
  }

  let deliveryDates = {}
  cateringOrders.forEach((order) => {
    const deliveryDate = order.note_attributes.find(item => item.name === "Leveransdatum").value;
    const deliveryTime = order.note_attributes.find(item => item.name === "Leveranstid").value;

    if (!deliveryDates[deliveryDate]) {
      deliveryDates[deliveryDate] = {}
    }

    if (!deliveryDates[deliveryDate][deliveryTime]) {
      deliveryDates[deliveryDate][deliveryTime] = []
    }

    deliveryDates[deliveryDate][deliveryTime].push(order)
  });

  // Convert to array
  let deliveryDatesArray = Object.entries(deliveryDates);

  // Sort array
  deliveryDatesArray.sort((a, b) => new Date(a[0]) - new Date(b[0]));
  deliveryDatesArray.reverse();

  return (
    <Timeline position="right">
      {deliveryDatesArray.map(([date, timeOrders], dateIndex) => {
        return Object.entries(timeOrders).map(([time, orders], timeIndex) => {
          // Determine middle index
          const middleIndex = Math.floor(orders.length / 2);

          return (
            <Paper elevation={2} sx={{ p: 2, my: 2, maxWidth: '80%', mx: 'auto' }}>
              {(timeIndex === 0) && 
                <Typography variant="h6" component="h1" align="center">
                  Delivery Date: {date}
                </Typography>
              }
              
              <Typography variant="h6" component="h2" align="center">
                Delivery Time: {time}
              </Typography>

              {orders.map((order, orderIndex) => (
                <TimelineItem key={`order-${order.id}`}>
                  {orderIndex === middleIndex && (
                    <TimelineOppositeContent
                      sx={{ m: 'auto 0' }}
                      variant="body2"
                      color="text.secondary"
                    >
                      {time}
                    </TimelineOppositeContent>
                  )}
                  <TimelineSeparator>
                    <TimelineConnector />
                    <TimelineDot style={{backgroundColor: colorMap[extractStatusFromTags(order.tags)], color: 'white'}}/>
                    <TimelineConnector />
                  </TimelineSeparator>
                  <TimelineContent sx={{ py: '12px', px: 2 }}>
                    <Typography variant="h6" component="span">
                      Order #{order.name}
                    </Typography>
                  </TimelineContent>
                </TimelineItem>
              ))}
            </Paper>
          )
        })
      })}
    </Timeline>
  );
}

export default StandByOrders;
