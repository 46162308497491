import React, { useState, useEffect } from "react";
import {
  Box,
  Drawer,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Typography,
  useTheme,
} from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import FlexBetween from "./FlexBetween";

import FormatListBulletedRoundedIcon from '@mui/icons-material/FormatListBulletedRounded';
import CalendarMonthOutlinedIcon from "@mui/icons-material/CalendarMonthOutlined";
import CakeOutlinedIcon from "@mui/icons-material/CakeOutlined";
import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";
import ChatOutlinedIcon from "@mui/icons-material/ChatOutlined";
import MapsUgcOutlinedIcon from "@mui/icons-material/MapsUgcOutlined";
import SearchOutlinedIcon from "@mui/icons-material/SearchOutlined";
import RightArrow from "@mui/icons-material/Start";
import LeftArrow from "@mui/icons-material/Start";
import InsertChartOutlinedIcon from '@mui/icons-material/InsertChartOutlined';
import EggOutlinedIcon from '@mui/icons-material/EggOutlined';
import AddCircleOutlineOutlinedIcon from "@mui/icons-material/AddCircleOutlineOutlined";
import LunchDiningOutlinedIcon from '@mui/icons-material/LunchDiningOutlined';
import { useMediaQuery } from "@mui/material";

import HourglassEmptyIcon from '@mui/icons-material/HourglassEmpty';


const navItems = [
  {
    text: "Hem",
    icon: <HomeOutlinedIcon />,
    path: "home",
  },
  {
    text: "Ordrar",
    icon: null,
  },
  {
    text: "Just nu",
    icon: <HourglassEmptyIcon />,
    path: "RightNow",
  },
  {
    text: "Beställningar",
    icon: <FormatListBulletedRoundedIcon />,
    path: "catering",
  },
  {
    text: "Kalender",
    icon: <CalendarMonthOutlinedIcon />,
    path: "calanader",
  },
  {
    text: "Sök",
    icon: <SearchOutlinedIcon />,
    path: "search",
  },

  {
    text: "Produkter",
    icon: null,
  },
  {
    text: "Alla produkter",
    icon: <CakeOutlinedIcon />,
    path: "products",
  },

  {
    text: "Lägg till",
    icon: <AddCircleOutlineOutlinedIcon />,
    path: "add-product",
  },

  {
    text: "Planering",
    icon: null,
  },

  {
    text: "Råvaror",
    icon: <EggOutlinedIcon />,
    path: "commodities-stats",
  },

  {
    text: "Ordrar",
    icon: <InsertChartOutlinedIcon />,
    path: "catering-stats",
  },

  {
    text: "Produkter",
    icon: <LunchDiningOutlinedIcon />,
    path: "count",
  },

  {
    text: "Meddelande",
    icon: null,
  },

  {
    text: "Alla",
    icon: <ChatOutlinedIcon />,
    path: "posts",
  },

  {
    text: "Lägg till",
    icon: <MapsUgcOutlinedIcon />,
    path: "add-post",
  },
];

const getInitialSidebarState = () => {
  const savedState = localStorage.getItem("openSideBar");
  return savedState === "true"; // Convert the string to a boolean value
};

const Sidebar = ({ drawerWidth, isNonMobile }) => {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up(1100));
  const { pathname } = useLocation();
  const [active, setActive] = useState("");
  const navigate = useNavigate();
  const [isIconOnly, setIsIconOnly] = useState(() => {
    if (matches) return getInitialSidebarState();
    return true;
  });
  


  useEffect(() => {
    setActive(pathname.substring(1));
  }, [pathname]);

  // Set up an effect that listens for changes to isIconOnly.
  useEffect(() => {
    localStorage.setItem("openSideBar", isIconOnly.toString());
  }, [isIconOnly]);

  useEffect(() => {
    if (!matches) setIsIconOnly(true);
  }, [matches]);
  

  return (
    <Box component="nav">
      <Drawer
        open={true}
        variant="persistent"
        anchor="left"
        sx={{
          width: isIconOnly ? theme.spacing(9) : drawerWidth,
          overflowY: isIconOnly ? "hidden" : "auto",
          overflowX: "hidden",
          "& .MuiDrawer-paper": {
            color: theme.palette.textColor.main,
            background: theme.palette.background.main,
            boxSizing: "border-box",
            width: isIconOnly ? theme.spacing(9) : drawerWidth,
          },
        }}
      >
        <Box width="100%">
          <Box m="1.5rem 0rem 2rem 1.2rem" alignItems="center">
            <FlexBetween color={theme.palette.green.main}>
              <Box
                display="flex"
                alignItems="center"
                justifyContent={isIconOnly ? "center" : "flex-start"}
                width={isIconOnly ? theme.spacing(0) : "100%"} // Modify this line
              >
                {isIconOnly ? (
                  <Box>
                    {/* <img src="https://lh3.googleusercontent.com/68rDQNT3fBWOUxlkwlCy9LvRQ3KAKaQgtg3VAtbkEgNQ5ZVLKz1GXvR8mNvabdfJKdt1=s83" width={"30px"}></img> */}
                  </Box>
                ) : (
                  <>
                    <svg width="150" height="50" viewBox="0 0 529 92" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M141.637 32.739C135.587 30.8633 130.628 29.1694 130.628 24.0111C130.628 19.3506 134.696 16.3743 140.249 16.3743C145.237 16.3743 148.367 18.6902 151.612 23.6858C151.794 23.9729 152.177 24.0686 152.464 23.8867C154.417 22.6809 161.301 18.4319 163.301 17.2069C163.608 17.0251 163.694 16.6231 163.493 16.3264C158.371 8.53646 151.498 3.99072 140.938 3.99072C126.262 3.99072 116.44 12.2209 116.44 24.7098C116.44 35.2176 123.285 41.4668 133.988 44.2421C142.518 46.4241 149.861 48.5965 149.861 55.5443C149.861 60.3006 145.888 63.4778 139.445 63.4778C133.576 63.4778 127.985 60.5781 124.395 53.8696C124.223 53.5442 123.821 53.4293 123.505 53.6303L112.601 60.5685C112.314 60.7504 112.218 61.1332 112.39 61.4299C118.45 71.6506 127.832 75.871 138.66 75.871C154.427 75.871 164.048 67.3441 164.048 54.75C164.048 38.8829 149.765 35.3133 141.637 32.739Z" fill="#2B545A"/>
                    <path d="M199.155 21.8293C191.419 21.8293 186.365 24.6141 183.588 30.1552V2.66054C183.588 2.40215 183.387 2.20117 183.129 2.20117H170.655C170.396 2.20117 170.195 2.41172 170.195 2.66054V74.1102C170.195 74.3686 170.406 74.5695 170.655 74.5695H183.129C183.387 74.5695 183.588 74.359 183.588 74.1102V46.3093C183.588 37.3901 188.643 33.5238 194.291 33.5238C200.945 33.5238 205.406 37.3997 205.406 46.3093V74.1102C205.406 74.3686 205.617 74.5695 205.866 74.5695H218.33C218.589 74.5695 218.79 74.359 218.79 74.1102V43.046C218.79 30.0595 210.26 21.8293 199.155 21.8293Z" fill="#2B545A"/>
                    <path d="M251.722 21.8291C235.753 21.8291 224.246 34.0309 224.246 48.9026C224.246 63.7744 235.763 75.8709 251.722 75.8709C267.68 75.8709 279.187 63.7744 279.187 48.9026C279.187 34.0309 267.671 21.8291 251.722 21.8291ZM251.722 63.6596C243.192 63.6596 237.639 56.9223 237.639 48.8931C237.639 40.8638 243.192 34.0213 251.722 34.0213C260.251 34.0213 265.794 40.8543 265.794 48.8931C265.794 56.9319 260.242 63.6596 251.722 63.6596Z" fill="#2B545A"/>
                    <path d="M312.599 63.7648C303.772 63.7648 298.124 57.0276 298.124 48.9888C298.124 40.95 303.772 34.0213 312.599 34.0213C321.425 34.0213 326.882 40.8543 326.882 48.8931C326.882 56.9319 321.129 63.7648 312.599 63.7648ZM314.791 21.9344C305.763 21.9344 301.101 25.705 298.325 31.3513V23.4847C298.325 23.2263 298.114 23.0253 297.865 23.0253H285.592C285.334 23.0253 285.133 23.2359 285.133 23.4847V80.809C285.133 80.9334 285.181 81.0483 285.267 81.1344L294.907 90.7714C296.247 92.1112 298.526 91.1638 298.526 89.2689V66.9325C301.302 72.2821 305.964 75.8518 314.791 75.8518C328.969 75.8518 340.275 63.851 340.275 48.9888C340.275 34.1266 328.969 21.9248 314.791 21.9248V21.9344Z" fill="#2B545A"/>
                    <path d="M377.158 2.66054V74.1102C377.158 74.3686 377.369 74.5695 377.617 74.5695H390.091C390.35 74.5695 390.551 74.359 390.551 74.1102V2.66054C390.551 2.40215 390.34 2.20117 390.091 2.20117H377.617C377.359 2.20117 377.158 2.41172 377.158 2.66054ZM362.185 23.0255V19.7526C362.185 15.6853 364.062 13.6086 368.035 13.6086H371.041C371.299 13.6086 371.5 13.3981 371.5 13.1493V2.66054C371.5 2.40215 371.289 2.20117 371.041 2.20117H365.957C355.541 2.20117 348.792 9.13943 348.792 19.5516V23.0255H342.014C341.756 23.0255 341.555 23.2361 341.555 23.4849V33.9545C341.555 34.2128 341.765 34.4138 342.014 34.4138H348.792V74.1102C348.792 74.3686 349.003 74.5695 349.252 74.5695H361.726C361.984 74.5695 362.185 74.359 362.185 74.1102V34.4138H372.228C372.486 34.4138 372.687 34.2033 372.687 33.9545V23.4849C372.687 23.2265 372.477 23.0255 372.228 23.0255H362.185Z" fill="#2B545A"/>
                    <path d="M423.962 21.8291C407.994 21.8291 396.496 34.0309 396.496 48.9026C396.496 63.7744 407.994 75.8709 423.962 75.8709C439.93 75.8709 451.428 63.7744 451.428 48.9026C451.428 34.0309 439.93 21.8291 423.962 21.8291ZM423.962 63.6596C415.432 63.6596 409.889 56.9223 409.889 48.8931C409.889 40.8638 415.432 34.0213 423.962 34.0213C432.492 34.0213 438.044 40.8543 438.044 48.8931C438.044 56.9319 432.492 63.6596 423.962 63.6596Z" fill="#2B545A"/>
                    <path d="M527.375 36.7679L515.696 74.2536C515.638 74.445 515.456 74.579 515.255 74.579H501.451C501.25 74.579 501.068 74.445 501.01 74.2441L492.681 45.2278C491.887 42.7587 491.293 40.0791 490.891 37.4952C490.489 40.0791 490.001 42.7587 489.302 45.2278L481.663 74.2345C481.605 74.4354 481.423 74.579 481.222 74.579H466.537C466.336 74.579 466.154 74.445 466.096 74.2536L450.319 23.6283C450.224 23.3316 450.444 23.035 450.76 23.035H465.426C465.637 23.035 465.828 23.1881 465.876 23.3891L472.443 52.2713L474.148 59.3436C474.54 56.9606 475.095 54.3959 475.718 52.1661L483.156 23.3699C483.204 23.169 483.395 23.0254 483.606 23.0254H498.263C498.473 23.0254 498.655 23.1594 498.703 23.3603L506.649 52.1661C507.271 54.4054 507.827 56.9702 508.219 59.3436L509.923 52.2713L514.04 35.7343C514.719 32.9973 514.719 30.1359 514.049 27.4085L513.111 23.5996C513.044 23.3125 513.255 23.0254 513.561 23.0254H527.155C527.385 23.0254 527.576 23.1881 527.605 23.4178L528.112 26.7864C528.61 30.1263 528.352 33.5428 527.347 36.7679H527.375Z" fill="#2B545A"/>
                    <path d="M75.0145 18.9965H12.9219C12.9219 8.50774 21.4326 0 31.9249 0H75.0145C80.2607 0 84.5208 4.24907 84.5208 9.503C84.5208 14.7569 80.2703 19.006 75.0145 19.006V18.9965Z" fill="url(#paint0_linear_747_13)"/>
                    <path d="M9.50454 56.865H71.6164C71.6164 67.3538 63.1056 75.8615 52.6133 75.8615H9.51413C4.26794 75.8615 0.0078125 71.6124 0.0078125 66.3585C0.0078125 61.1141 4.25837 56.8555 9.51413 56.8555L9.50454 56.865Z" fill="url(#paint1_linear_747_13)"/>
                    <path d="M84.5121 42.6725C84.5408 49.9648 78.8926 56.2045 71.6073 56.8648C71.5786 51.6588 67.328 47.4288 62.101 47.4288H14.2726C9.888 47.4288 5.9725 45.4478 3.36855 42.3376C2.25804 41.036 1.41559 39.5623 0.831621 37.9354C-2.30843 29.3989 3.8185 19.7428 12.9132 18.9963C12.9515 24.212 17.1925 28.4228 22.4195 28.4228H70.2574C74.642 28.4228 78.5575 30.4038 81.1615 33.514C82.272 34.8155 83.1144 36.2989 83.6984 37.9258C84.225 39.4187 84.5121 41.0169 84.5121 42.6725Z" fill="#2B545A"/>
                    <defs>
                    <linearGradient id="paint0_linear_747_13" x1="106.463" y1="58.6545" x2="41.6503" y2="6.22684" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#9BC2BD"/>
                    <stop offset="1" stopColor="#ACD3BC"/>
                    </linearGradient>
                    <linearGradient id="paint1_linear_747_13" x1="80.0218" y1="99.6143" x2="15.2091" y2="47.1866" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#9BC2BD"/>
                    <stop offset="1" stopColor="#ACD3BC"/>
                    </linearGradient>
                    </defs>
                    </svg>
                  </>
                )}
              </Box>
              <IconButton
                onClick={() => setIsIconOnly((prevState) => !prevState)}
                sx={{
                  mr: isIconOnly ? "20rem" : "0rem", // Modify this line
                  ml: isIconOnly ? "0rem" : "auto",
                }}
              >
                {isIconOnly ? (
                  <RightArrow />
                ) : (
                  <LeftArrow
                    sx={{
                      transform: "rotate(180deg)",
                    }}
                  />
                )}
              </IconButton>
            </FlexBetween>
          </Box>
          <List>
            {navItems.map(({ text, icon, path }) => {
              if (!icon) {
                return (
                  <Typography
                    key={text}
                    sx={{
                      m: "2.25rem 0 1rem 3rem",
                      fontSize: "16px",
                      fontWeight: "600",
                    }}
                  >
                    {!isIconOnly && text}
                  </Typography>
                );
              }
              return (
                <ListItem key={`${text}-${path}`} disablePadding>
                  <ListItemButton
                    onClick={() => {
                      setActive(path);
                      navigate(`/${path}`);
                    }}
                    sx={{
                      backgroundColor:
                        active === path
                          ? theme.palette.green.main
                          : "transparent",
                      color:
                        active === path
                          ? "white"
                          : theme.palette.textColor.main,
                      "&:hover": {
                        backgroundColor:
                          active === path ? "#A3001B" : "#CECECE",
                      },
                    }}
                  >
                    <ListItemIcon
                      sx={{
                        ml: isIconOnly ? "0.6rem" : "2rem",
                        color:
                          active === path
                            ? "white"
                            : theme.palette.textColor.main,
                      }}
                    >
                      {icon}
                    </ListItemIcon>
                    {!isIconOnly && (
                      <ListItemText
                        primary={text}
                        sx={{ ml: "0rem" }} // adjust the margin left here
                      />
                    )}
                  </ListItemButton>
                </ListItem>
              );
            })}
          </List>
        </Box>
      </Drawer>
    </Box>
  );
};

export default Sidebar;
