import React from "react";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
  Scatter,
  ReferenceDot,
} from "recharts";

import { Box, Typography } from "@mui/material";
import { useTheme } from "@mui/material/styles";

function OrdersChart({ orders }) {
  const theme = useTheme(); // Get the theme from the context
  return (
    <div>
      <Box m={2}>
        <Typography
          variant="h3"
          component="h3"
          fontWeight="bold"
          gutterBottom
          sx={{ color: "#363636" }}
        >
          Översikt
        </Typography>
        <Typography
          variant="h5"
          gutterBottom
          sx={{ color: "#363636" }}
        >
          Antalet beställningar en vecka fram
        </Typography>
      </Box>
      <ResponsiveContainer width="100%" height={300}>
        <LineChart
          data={orders}
          margin={{
            top: 5,
            right: 20,
            left: 20,
            bottom: 5,
          }}
        >
          <CartesianGrid strokeDasharray="1 1" />
          <XAxis dataKey="dayStart" />
          <YAxis width={10} domain={[0, "dataMax"]} allowDecimals={false} />
          <Tooltip
            formatter={(value, name, props) => {
              if (name === "numOrders") {
                name = "Beställningar";
              }
              return [Math.round(value), name];
            }}
          />
          <Line
            type="monotone"
            dataKey="numOrders"
            stroke={theme.palette.green.light}
            strokeWidth={1.7}
            activeDot={{ r: 8 }}
          />
          <Scatter dataKey="numOrders" fill="#8884d8">
            {orders.map((entry, index) => (
              <ReferenceDot
                key={entry.dayStart}
                x={entry.dayStart}
                y={entry.numOrders}
                r={4}
                stroke="#8884d8"
                fill="#8884d8"
              />
            ))}
          </Scatter>
        </LineChart>
      </ResponsiveContainer>
    </div>
  );
}

export default OrdersChart;
