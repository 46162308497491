import React from 'react';
import PropTypes from 'prop-types';

const ErrorComponent = ({ error }) => {
  if (!error) return null;

  let userFriendlyMessage;
  // Set user friendly error messages based on error type or message
  switch (error.message) {
    case 'Network Error':
      userFriendlyMessage = "Det ser ut som något är fel med nätverket, prova igen. Fungerar inte det kontkata shopflow.";
      break;
    default:
      userFriendlyMessage = "Något gick fel, prova igen. Fungerar inte det, kontakta shopflow.";
      break;
  }

  // Function to refresh the page
  const refreshPage = () => {
    window.location.reload();
  }

  const errorContainerStyle = {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#ffdddd',
    color: '#a80000',
    padding: '20px',
    borderRadius: '10px',
    marginBottom: '20px',
    maxWidth: '80%',
    marginLeft: 'auto',
    marginRight: 'auto',
    marginTop: '50px',  // adding space above the error message

  };

  const errorButtonStyle = {
    marginTop: '20px',
    padding: '10px 20px',
    backgroundColor: '#a80000',
    color: '#fff',
    border: 'none',
    borderRadius: '5px',
    cursor: 'pointer',
    fontSize: '16px',
  };

  return (
    <div style={errorContainerStyle}>
      <h2>Oops! Något gick fel.</h2>
      <p>{userFriendlyMessage}</p>
      {/* Retry button */}
      <button style={errorButtonStyle} onClick={refreshPage}>Försök igen</button>
    </div>
  );
};

ErrorComponent.propTypes = {
  error: PropTypes.shape({
    message: PropTypes.string,
  }),
};

export default ErrorComponent;
