import { Snackbar, IconButton, Alert } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

// use these two where you want to utilize the component:

// const handleSnackbarClose = () => {
//   setOpenSnackbar(false);
// };

// const [openSnackbar, setOpenSnackbar] = useState(false);

// to render the component:

// <CustomSnackbar
// open={openSnackbar}
// handleClose={handleSnackbarClose}
// message="Uppdatering av order lyckades!"
// severity="success"
// />

const CustomSnackbar = ({
  message,
  open,
  handleClose,
  severity,
  autoHideDuration = 5000,
  isMobile,
  isLogin
}) => {

  const backgroundColors = {
    success: "#085A35",
    error: "#4D0204",
  };

  return (
    <Snackbar
      anchorOrigin={{vertical: isMobile ? "bottom" : "top" , horizontal: isLogin ? "center" : "right" }}
      open={open}
      onClose={handleClose}
      autoHideDuration={autoHideDuration}
      key={`${severity}-${message}`}
      ContentProps={{
        sx: {
          fontWeight: "bold",
        },
      }}
    >
      <Alert
        severity={severity}
        action={
          <IconButton
            size="small"
            aria-label="close"
            color="inherit"
            onClick={handleClose}
          >
            <CloseIcon fontSize="small" />
          </IconButton>
        }
        sx={{ 
          fontWeight: "bold",
          backgroundColor: backgroundColors[severity],
          color: "#ffffff"
        }}
      >
        {message}
      </Alert>
    </Snackbar>
  );
};

export default CustomSnackbar;
