import * as React from "react";
import { Button, Grid } from "@mui/material";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";

export class CalendarNavigation extends React.Component {
  render() {
    const { label, toolbar } = this.props;
    return (
      <Grid
        container
        justifyContent="space-between"
        alignItems="center"
        className="mb-3"
      >
        <Grid item>
          <Button
            sx={{
              backgroundColor: "#C00020",
              color: "white",
              marginRight: "3px",
              marginBottom: "15px",
              opacity: 0.9,
              "&:hover": { backgroundColor: "#A3001B" },
              textTransform: "none",
              fontWeight: "bold",
            }}
            onClick={() => toolbar.onNavigate("TODAY")}
          >
            Idag
          </Button>
          <Button
            sx={{
              backgroundColor: "#C00020",
              color: "white",
              marginRight: "3px",
              marginBottom: "15px",
              opacity: 0.9,
              "&:hover": { backgroundColor: "#A3001B" },
              textTransform: "none",
              fontWeight: "bold",
            }}
            onClick={() => toolbar.onNavigate("PREV")}
          >
            <ChevronLeftIcon />
          </Button>
          <Button
            sx={{
              backgroundColor: "#C00020",
              color: "white",
              marginRight: "3px",
              marginBottom: "15px",
              opacity: 0.9,
              "&:hover": { backgroundColor: "#A3001B" },
              textTransform: "none",
              fontWeight: "bold",
            }}
            onClick={() => toolbar.onNavigate("NEXT")}
          >
            <ChevronRightIcon />
          </Button>
        </Grid>
        <Grid item sx={{ marginBottom: "15px" }}>
          {" "}
          {/* Add marginBottom here */}
          {label()}
        </Grid>
        <Grid item>
          <Button
            sx={{
              backgroundColor: "#C00020",
              color: "white",
              marginRight: "3px",
              marginBottom: "15px",
              opacity: 0.9,
              "&:hover": { backgroundColor: "#A3001B" },
              textTransform: "none",
              fontWeight: "bold",
            }}
            onClick={() => toolbar.onView("month")}
          >
            Månad
          </Button>
          <Button
            sx={{
              backgroundColor: "#C00020",
              color: "white",
              marginRight: "3px",
              marginBottom: "15px",
              opacity: 0.9,
              "&:hover": { backgroundColor: "#A3001B" },
              textTransform: "none",
              fontWeight: "bold",
            }}
            onClick={() => toolbar.onView("week")}
          >
            Vecka
          </Button>
          <Button
            sx={{
              backgroundColor: "#C00020",
              color: "white",
              marginRight: "3px",
              marginBottom: "15px",
              opacity: 0.9,
              "&:hover": { backgroundColor: "#A3001B" },
              textTransform: "none",
              fontWeight: "bold",
            }}
            onClick={() => toolbar.onView("day")}
          >
            Dag
          </Button>
          <Button
            sx={{
              backgroundColor: "#C00020",
              color: "white",
              marginRight: "3px",
              marginBottom: "15px",
              opacity: 0.9,
              "&:hover": { backgroundColor: "#A3001B" },
              textTransform: "none",
              fontWeight: "bold",
            }}
            onClick={() => toolbar.onView("agenda")}
          >
            Agenda
          </Button>
        </Grid>
      </Grid>
    );
  }
}
