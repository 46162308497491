import React, { useState, useEffect, useContext } from "react";
import {
  Table,
  TableBody,
  TableContainer,
  Paper,
  Box,
  Grid,
  Button,
} from "@mui/material";

//components
import { TableFilterSort } from "./components/tableFilterSort.jsx";
import { OrdersTableHeader } from "./components/ordersTableHeader.jsx";
import OrdersTableRow from "./components/ordersTableRow.jsx";
import OrderDropdown from "./components/OrdersDropdown.jsx";

// icons
import DownloadIcon from "@mui/icons-material/Download";

// hooks
import useUpdateOrder from "Hooks/Catering/useUpdateOrder.js";
import GetUpcommingOrders from "../../../Hooks/Catering/useGetUpcommingOrders.js";

// utilities and theme
import { useTheme } from "@mui/material/styles";
import GeneratePDF from "utilities/generatePdf.js";
import { parseDate } from "utilities/dateUtility.js";
import { getDeliveryDate } from "utilities/getNoteAttributes.js";
import LoadingComponent from "components/LoadingComponent.jsx";
import ErrorComponent from "components/ErrorComponent.jsx";

import AuthContext from 'context/AuthContext';


const getAddressUrl = (address) => {
  return `https://www.google.com/maps/search/?api=1&query=${encodeURIComponent(
    address
  )}`;
};

function isTodayOrFuture(date) {
  const today = new Date();
  today.setHours(0, 0, 0, 0); // set time to 00:00:00.000
  return date >= today;
}

function CurrentCateringOrders() {
  const theme = useTheme();

  // HOOKS USAGE

  let {user} = useContext(AuthContext)

  const {
    upcomingOrders: cateringOrders,
    isLoading,
    isError,
  } = GetUpcommingOrders();

  const { updateOrder } = useUpdateOrder();

  // const [addressPopoverAnchorEl, setAddressPopoverAnchorEl] = useState(null);
  // const [selectedAddress, setSelectedAddress] = useState(null);

  const [selectedStatusTag, setSelectedStatusTag] = useState("Alla");
  const [selectedTypeDate, setSelectedTypeDate] = useState("Leveransdatum");

  const [displayedOrders, setDisplayedOrders] = useState([]);

  // CHECK AND SELECT
  const [selectAll, setSelectAll] = useState(false);
  const [selectedOrders, setSelectedOrders] = useState([]);

  // PAGINATION
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(25);

  // FILTER AND SORT
  const [sortedAndFilteredOrders, setSortedAndFilteredOrders] = useState([]);

  useEffect(() => {
    if (!isLoading) {
      let orders = (cateringOrders ? [...cateringOrders] : []).filter(
        (order) => {
          const date = parseDate(getDeliveryDate(order));
          return isTodayOrFuture(date);
        }
      );

      if (selectedStatusTag) {
        
        const filterValue =
        selectedStatusTag === "Alla" ? "" : selectedStatusTag;
        orders = orders.filter(
          (order) =>
          order.tags.includes(filterValue) ||
          (filterValue === "status: Mottagen" && !order.tags.includes("status"))
          );
        }
        
      // No need to check for orderBy as we always want to sort by date


      orders = orders.sort((a, b) => {
        let dateA;
        let dateB;

        if (selectedTypeDate === "Leveransdatum" || selectedTypeDate === "") {
        dateA = parseDate(getDeliveryDate(a));
        dateB = parseDate(getDeliveryDate(b));
      } else if (selectedTypeDate === "Senaste") {
        dateA = new Date(a.created_at);
        dateB = new Date(b.created_at);
      }

        let comparisonResult;
        if (dateA < dateB) {
          comparisonResult = -1;
        } else if (dateA > dateB) {
          comparisonResult = 1;
        } else {
          comparisonResult = 0;
        }
        return comparisonResult;
      });

      setSortedAndFilteredOrders(orders);
    }
  }, [cateringOrders, isLoading, selectedStatusTag, selectedTypeDate]);

  useEffect(() => {
    setDisplayedOrders(
      sortedAndFilteredOrders.slice(
        (page - 1) * rowsPerPage,
        page * rowsPerPage
      )
    );
  }, [sortedAndFilteredOrders, page, rowsPerPage]);

  const areAnyCellsSelected = () => {
    return selectedOrders.length > 0;
  };

  const handleDownload = () => {
    // You can find the selected orders either by using the `selectedOrders` state if it contains full order objects
    // or by filtering the `displayedOrders` (or your original orders array if displayedOrders doesn't have all the orders)
    const ordersToDownload = displayedOrders.filter((order) =>
      selectedOrders.includes(order.id)
    );

    // Now we call createPdf for each selected order
    ordersToDownload.forEach((order) => {
      GeneratePDF(order.id, order, user.shopName);
    });
  };

  // hide the adress logic
  // const handleAddressPopoverOpen = (event, address) => {
  //   setSelectedAddress(address);
  //   setAddressPopoverAnchorEl(event.currentTarget);
  // };

  // const handleAddressPopoverClose = () => {
  //   setAddressPopoverAnchorEl(null);
  // };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    setSelectedOrders([]);
    setSelectAll(false); // Clear the "select all" state when the page changes
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(1);
  };

  const getVendorsLabel = (order) => {
    let vendors = new Set(); // Using a Set to avoid duplicates

    // Checking if the order has line items
    if (order.hasOwnProperty("lineItems")) {
      // Iterating through each line item
      for (let item of order.lineItems) {
        // Checking if the line item has a vendor
        if (item.hasOwnProperty("vendor")) {
          vendors.add(item.vendor);
        }
      }
    }

    // Converting Set back to Array
    let vendorsArray = Array.from(vendors);

    // Building the label for the Chip component
    let label = vendorsArray[0];
    if (vendorsArray.length > 1) {
      label += " + " + (vendorsArray.length - 1).toString();
    }

    return label;
  };

  // logic for checkboxes
  const isSelected = (id) => {
    const index = selectedOrders.indexOf(id);
    const result = index !== -1;
    return result;
  };

  const handleClick = (event, id) => {
    const selectedIndex = selectedOrders.indexOf(id);
    if (selectedIndex === -1) {
      setSelectedOrders((prevSelected) => [...prevSelected, id]); // If not already selected, add it
    } else {
      setSelectedOrders((prevSelected) =>
        prevSelected.filter((item) => item !== id)
      ); // If already selected, remove it
    }
  };

  const handleSelectAll = (event) => {
    const checked = event.target.checked;
    setSelectAll(checked);

    // Select or deselect all orders
    const pageOrdersIds = displayedOrders.map((order) => order.id);
    if (checked) {
      setSelectedOrders(pageOrdersIds);
    } else {
      setSelectedOrders([]);
    }
  };

  const isErrorAny = isError;

  
  if (isLoading) {
    return (<LoadingComponent/>);
  }

  if (isErrorAny) {
    return <ErrorComponent error={isErrorAny} />;
  }

  return (
    <Box sx={{ flexGrow: 1, p: 4 }}>
      <Grid container spacing={4}>
        <TableFilterSort
          rowsPerPage={rowsPerPage}
          handleChangeRowsPerPage={handleChangeRowsPerPage}
          selectedStatusTag={selectedStatusTag}
          setSelectedStatusTag={setSelectedStatusTag}
          sortedAndFilteredOrders={sortedAndFilteredOrders}
          page={page}
          handleChangePage={handleChangePage}
          selectedTypeDate={selectedTypeDate}
          setSelectedTypeDate={setSelectedTypeDate}
        />
        <Grid item xs={12}>
          <TableContainer component={Paper}>
            <Table style={{ tableLayout: "fixed" }}>
              <OrdersTableHeader
                selectAll={selectAll}
                handleSelectAll={handleSelectAll}
                theme={theme}
              />
              <TableBody>
                {displayedOrders.map((orders) => (
                  <OrdersTableRow
                    key={orders.id} // You need to ensure orders.id is unique for each item in your array
                    orders={orders}
                    theme={theme}
                    handleClick={handleClick}
                    isSelected={isSelected}
                    getVendorsLabel={getVendorsLabel}
                    updateOrder={updateOrder}
                    getAddressUrl={getAddressUrl}
                    OrderDropdown={OrderDropdown}
                  />
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          {areAnyCellsSelected() && (
            <Box sx={{ position: "fixed", bottom: 20, right: 20 }}>
              <Button
                variant="contained"
                color="primary"
                onClick={handleDownload}
                endIcon={<DownloadIcon />}
                style={{
                  textTransform: "none",
                  fontWeight: "bold",
                }}
              >
                Ladda ner
              </Button>
            </Box>
          )}
        </Grid>
      </Grid>
    </Box>
  );
}

export default CurrentCateringOrders;

/*



      {/* <Dialog open={emailDialogOpen} onClose={handleEmailDialogClose}>
  <DialogTitle>{"Send Email"}</DialogTitle>
  <DialogContent>
  <DialogContentText>
    Skriv ett email och klicka skicka för att sk
  </DialogContentText>
    <TextField
      autoFocus
      margin="dense"
      label="Email Subject"
      type="text"
      fullWidth
      value={emailSubject}
      onChange={(event) => setEmailSubject(event.target.value)}
    />
    <TextField
      autoFocus
      margin="dense"
      label="Email Content"
      type="text"
      fullWidth
      multiline
      rows={4}
      value={emailContent}
      onChange={(event) => setEmailContent(event.target.value)}
    />
      <Typography>
    To: {emailRecipient}
  </Typography>
  </DialogContent>
  <DialogActions>
    <Button onClick={handleEmailDialogClose}>Cancel</Button>
    <Button onClick={handleSendEmail}>Send</Button>
  </DialogActions>
</Dialog> */

//<LocationOnIcon fontSize="small" sx={{ mr: 1 }} />
