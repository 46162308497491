import { useState } from 'react';


const useDeletePost = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [open, setOpen] = useState(false);
  
  const deletePost = async (postId) => {
    setIsLoading(true);

    try {
      const baseUrl = process.env.REACT_APP_BASE_URL
        ? process.env.REACT_APP_BASE_URL
        : 'http://localhost:8000';

      const response = await fetch(`${baseUrl}/store/posts/${postId}`, {
        method: 'DELETE',
        headers: {
          'Content-Type':'application/json',
        },
        credentials: 'include',  // Include this line
      });

      if (response.ok) {
        // Success
        setError(null);
        setOpen(true);
      } else {
        // Error handling
        setOpen(true);
        setError('An error occurred while deleting the post.');
      }
    } catch (error) {
      // Error handling
      setError('An error occurred while deleting the post.');
    } finally {
      setIsLoading(false);
    }
  };

  return { deletePost, isLoading, error, open, setOpen };
};

export default useDeletePost;
