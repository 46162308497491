  // Function to parse the date in "dd/mm/yyyy" format
  export function parseDate(dateString) {
    if (!dateString) {
      return;
    }
    const parts = dateString.split("/");
    const day = parseInt(parts[0], 10);
    const month = parseInt(parts[1], 10) - 1; // months are zero-indexed in JavaScript
    const year = parseInt(parts[2], 10);
    return new Date(year, month, day);
  }


export function isThisWeek(dateStr) {
  let now = new Date();
  now.setHours(0, 0, 0, 0);

  let oneWeekFromNow = new Date();
  oneWeekFromNow.setDate(now.getDate() + 7); // Changes here
  oneWeekFromNow.setHours(0, 0, 0, 0);

  const parts = dateStr.split("/");
  if (parts.length !== 3) {
    console.error(`Unexpected date format: ${dateStr}`);
    return false;
  }

  const day = parseInt(parts[0], 10);
  const month = parseInt(parts[1], 10) - 1;
  const year = parseInt(parts[2], 10);

  let date = new Date(year, month, day);
  date.setHours(0, 0, 0, 0);

  if (isNaN(date.getTime())) {
    console.error(`Invalid date: ${dateStr}`);
    return false;
  }

  return date >= now && date <= oneWeekFromNow; // Changes here
}