import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { useQuery } from 'react-query';
import axios from 'axios';


const authHeader = () => {
  const token = localStorage.getItem("token");
  return token ? { Authorization: `Bearer ${token}` } : {};
};

const endpoints = (build) => ({
  // User related endpoints
  addUser: build.mutation({
    query: (userData) => ({
      url: "/auth/add-user",
      method: "POST",
      body: userData,
    }),
    invalidatesTags: ["User"],
  }),
  

  login: build.mutation({
    query: (credentials) => ({
      url: "/auth/login",
      method: "POST",
      body: credentials,
    }),
    invalidatesTags: ["User"],
  }),


  logout: build.mutation({
    query: () => ({
      url: "/logout",
      method: "POST",
    }),
    invalidatesTags: ["User"],
  }),
  


  // Store related endpoints


  // getMyStore: build.query({
  //   query: () => ({
  //     url: "/stores/my-store",
  //     method: "GET",
  //     headers: authHeader(), // Include the Authorization header
  //   }),
  //   providesTags: ["Store"],
  // }),

  // CateringOrder related endpoints

// Add this to the endpoints function in your Redux Toolkit Query file
createCateringOrder: build.mutation({
  query: (cateringOrderData) => ({
    url: "/auth/createCateringOrder",
    method: "POST",
    body: cateringOrderData,
    headers: authHeader(),
  }),
  invalidatesTags: ["CateringOrder"],
}),


  updateCateringOrder: build.mutation({
    query: ({ id, orderData }) => ({
      url: `/catering/catering/${id}`,
      method: "PUT",
      body: orderData,
    }),
    invalidatesTags: ["CateringOrder"],
  }),
  getCateringOrder: build.query({
    query: ({ id, token }) => ({
      url: `/catering/catering/${id}`,
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }),
    providesTags: ["CateringOrder"],
  }),
  getCateringOrders: build.query({
    query: (storeId) => {
      const token = localStorage.getItem("token");
      return {
        url: "/catering/cateringOrders",
        method: "GET",
        headers: {
          storeId: storeId,
          Authorization: `Bearer ${token}`, // Add the Authorization header
        },
      };
    },
    providesTags: ["CateringOrder"],
  }),
  getUsersByStore: build.query({
    query: (storeId) => ({
      url: `/stores/store/${storeId}`,
      method: "GET",
      headers: authHeader(),
    }),
    providesTags: ["User"],
  }),
});

export const api = createApi({
  baseQuery: fetchBaseQuery({ baseUrl: process.env.REACT_APP_BASE_URL }),
  reducerPath: "adminApi",
  tagTypes: ["User", "Store", "CateringOrder"],
  endpoints,
});


export const useGetCateringOrdersQuerys = (storeId) => {
  return useQuery(["cateringOrders", storeId], async () => {
    const { data } = await axios.get(`/api/all-orders/${storeId}`);
    return data;
  });
};

export const {
  useAddUserMutation,
  useLoginMutation,
  useLogoutMutation,
  useGetMyStoreQuery,
  useCreateCateringOrderMutation,
  useUpdateCateringOrderMutation,
  useGetCateringOrderQuery,
  useGetCateringOrdersQuery,
  useGetUsersByStoreQuery,
} = api;
