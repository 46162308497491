import React, { useCallback, useState } from "react";
import {
  TextField,
  Checkbox,
  FormControlLabel,
  Button,
  Paper,
  Grid,
  Typography,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  IconButton,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import Box from "@mui/material/Box";
import { useDropzone } from "react-dropzone";
import { makeStyles } from "@mui/styles";
import ReactQuill from "react-quill";

// HOOKS
import useShopifyCollections from "../../../Hooks/Products/useShopifyCollections"; // Make sure to correct the import path according to your project structure
import useCreateShopifyCollection from "../../../Hooks/Products/useNewCollection"; // Make sure to correct the import path according to your project structure
import useCreateProduct from "Hooks/Products/useCreateProduct";
// ICONS
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";

// COMPONENTS
import CustomSnackbar from "components/SnackBar";

// UTILS AND STYLING
import "../../Products_Folder/ProductDetails/Style/customQuill.css"

const useStyles = makeStyles((theme) => ({
  dropzone: {
    borderWidth: 2,
    borderColor: theme.palette.primary.main,
    borderStyle: "dashed",
    borderRadius: theme.shape.borderRadius,
    padding: theme.spacing(2),
    marginTop: theme.spacing(1),
    textAlign: "center",
    cursor: "pointer",
  },
  dropzoneText: {
    marginBottom: theme.spacing(2),
  },
}));

const CreateProductForm = () => {
  const classes = useStyles();
  const { collections, isLoading, isError } = useShopifyCollections();
  const [title, setTitle] = useState("");
  const [body_html, setBodyHtml] = useState("");
  const [quillKey, setQuillKey] = useState(0);
  const [vendor, setVendor] = useState("");
  const [tags, setTags] = useState("");
  const [price, setPrice] = useState("");
  const { createProduct, isCreateLoading, error } = useCreateProduct();
  const [open, setOpen] = useState(false);
  const [newCategory, setNewCategory] = useState("");
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [status, setStatus] = useState("draft");

  const [variants, setVariants] = useState([
    { option1: "", price: "", sku: "" },
  ]); // new state hook for variants
  const createCollectionMutation = useCreateShopifyCollection();

  // Combine all error flags
  const isErrorAny = isError || error; 
  // used for the snackbar
  const severity = isErrorAny ? "error" : "success";

  const handleSnackbarClose = () => {
    setOpenSnackbar(false);
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleAddCategory = () => {
    createCollectionMutation.mutate({ title: newCategory }); // passing the new collection data
    // Add code here to add the category
    // (this will depend on how your application is structured)
    handleClose();
  };

  const addVariant = () => {
    setVariants([...variants, { option1: "", price: "", sku: "" }]);
  };

  const removeVariant = (index) => {
    setVariants(variants.filter((variant, i) => i !== index));
  };

  const handlePrice = (index, field, value) => {
    setVariants([...variants, { option1: "", price: "", sku: "" }]);
    handleVariantChange(index, field, value)
    handleVariantChange(index, "option1", "Standard")
  }
  const handleVariantChange = (index, field, value) => {
    const newVariants = [...variants];
    newVariants[index][field] = value;
    setVariants(newVariants);
  };

  const [image, setImage] = useState(null);

  const onDrop = useCallback((acceptedFiles) => {
    setImage(acceptedFiles[0]);
  }, []);
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: "image/*",
  });

  const handleSubmit = async (event) => {
    event.preventDefault();

    const formData = new FormData();
    formData.append("title", title);
    formData.append("body_html", body_html);
    formData.append("vendor", vendor);
    formData.append("product_type", "");
    formData.append("tags", tags);
    formData.append("price", price);
    formData.append("status", status);
    formData.append("requires_shipping", "false");
    if (image) {
      formData.append("image", image, image.name);
    }
    formData.append("variants", JSON.stringify(variants));


    // for (let pair of formData.entries()) {
    //   console.log(pair[0] + ', ' + pair[1]);
    // }
    
    try {
      await createProduct(formData);
      setOpenSnackbar(true);
      // reset form input
      setTitle("");
      setBodyHtml("");
      setVendor("");
      setTags("");
      setPrice("");
      setImage(null);
      setStatus("draft");
      setVariants([{ option1: "", price: "", sku: "" }]); 
      // Reset React Quill    
      setQuillKey(prevKey => prevKey + 1);
    } catch (error) {
      console.error("Error:", error);
    }
  };

  return (
    <Grid container justifyContent="center">
      <Grid item xs={12} sm={10} md={8}>
        <Paper elevation={3} sx={{ p: 4, mt: 4, }}>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <Typography
              variant="h2"
              gutterBottom
              style={{ fontWeight: "500", color: "#444444" }}
            >
              Skapa Produkt
            </Typography>
            <IconButton
              aria-label="info"
              onClick={() =>
                window.open(
                  "https://res.cloudinary.com/djmmh5kbn/image/upload/v1689078695/preview_auvls7.pdf",
                  "_blank"
                )
              }
            >
              <InfoOutlinedIcon />
            </IconButton>
          </Box>

          <Box component="form" onSubmit={handleSubmit} noValidate>
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              id="title"
              label="Titel"
              value={title}
              onChange={(e) => setTitle(e.target.value)}
              sx={{
                mb: "15px",
              }}
              autoComplete="off"
            />
            <ReactQuill
                key={quillKey}
                className="my-quill-editor"
                required
                margin="normal"
                placeholder="Beskrivning *"
                onChange={(value) => setBodyHtml(value)}
                modules={{
                  toolbar: [
                    ['bold', 'italic', 'underline'],
                  ],
                }}
              />
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              id="vendor"
              label="Leverantör"
              value={vendor}
              onChange={(e) => setVendor(e.target.value)}
              autoComplete="off"
            />
            {!isLoading && !isError && (
              <FormControl
                fullWidth
                variant="outlined"
                margin="normal"
                required
              >
                <InputLabel id="tags-label">Kategori</InputLabel>
                <Select
                  labelId="tags-label"
                  id="tags"
                  value={tags}
                  onChange={(e) => {
                    if (e.target.value === "add-category") {
                      handleClickOpen();
                    } else {
                      setTags(e.target.value);
                    }
                  }}
                  label="Kategori"
                >
                  {collections.map((collection) => (
                    <MenuItem key={collection.id} value={collection.title}>
                      {collection.title}
                    </MenuItem>
                  ))}
                  <MenuItem value="add-category">+ Lägg till kategori</MenuItem>
                </Select>
              </FormControl>
            )}
            {isLoading && <div>Laddar in kategorier...</div>}
            {isError && <div>Något gick snett med hämtning av kategorier</div>}

            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              id={`variant-${0}-price`}
              label="Pris"
              value={variants.length === 1 ? variants[0].price : ''}
              onChange={(e) => handlePrice(0, "price", e.target.value)}
              autoComplete="off"
              />
            <FormControlLabel
              control={
                <Checkbox
                  checked={status === "active"}
                  onChange={(e) => setStatus(e.target.checked ? "active" : "draft")}
                />
              }
              label="Publicera?"
            />
          <Typography
            variant="h3"
            sx={{ mt: 2, mb: 1, fontWeight: "500", color: "#444444" }}
          >
            Varianter
          </Typography>
          {variants.map((variant, index) => (
            <Box
              key={index}
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                mt: 2,
              }}
            >
              <TextField
                variant="outlined"
                required
                sx={{ mr: 1.5, flex: "1" }}
                id={`variant-${index}-option1`}
                label="Namn"
                value={variant.option1}
                onChange={(e) => handleVariantChange(index, "option1", e.target.value)}
                autoComplete="off"
              />
              <TextField
                variant="outlined"
                required
                sx={{ mr: 2, flex: "1" }}
                id={`variant-${index}-price`}
                label="Pris"
                value={variant.price}
                onChange={(e) => handleVariantChange(index, "price", e.target.value)}
                autoComplete="off"
              />
              <Button
                onClick={() => removeVariant(index)}
                variant="contained"
                disabled={variants.length === 1}
                sx={{ color: "#fff", backgroundColor: "#C00020", textTransform: "none", fontWeight: "bold" }}
              >
                Radera
              </Button>
            </Box>
          ))}

            <IconButton onClick={addVariant} color="primary">
              <AddCircleOutlineIcon />
            </IconButton>
            <Box {...getRootProps()} className={classes.dropzone}>
              <input {...getInputProps()} />
              <Typography variant="subtitle1" className={classes.dropzoneText}>
                {isDragActive
                  ? "Släpp bilden här..."
                  : image
                  ? `Vald bild: ${image.name}`
                  : "Dra och släpp filer här, eller klicka på knappen för att välja filer"}
              </Typography>
              <Button variant="outlined" component="span" sx={{ textTransform: "none", fontWeight: "bold" }}>
                Välj bild
              </Button>
            </Box>
            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              sx={{ mt: 6, mb: 6, textTransform: "none", fontWeight: "bold" }}
              disabled={isCreateLoading}
            >
              Skapa Produkt
            </Button>

            <Dialog open={open} onClose={handleClose}>
              <DialogTitle>Lägg till en ny kategori</DialogTitle>
              <DialogContent>
                <TextField
                  autoFocus
                  margin="dense"
                  id="newCategory"
                  label="Ny kategori"
                  type="text"
                  fullWidth
                  value={newCategory}
                  onChange={(e) => setNewCategory(e.target.value)}
                  autoComplete="off"
                />
              </DialogContent>
              <DialogActions>
                <Button onClick={handleClose}>Avbryt</Button>
                <Button onClick={handleAddCategory}>Lägg till</Button>{" "}
              </DialogActions>
            </Dialog>
          </Box>
        </Paper>
      </Grid>
      <CustomSnackbar
        open={openSnackbar}
        handleClose={handleSnackbarClose}
        message={ isErrorAny ? "Skapandet av produkt misslyckades!" : "Skapandet av produkt lyckades!"}
        severity={severity}
      />
    </Grid>
  );
};

export default CreateProductForm;
