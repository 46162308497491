import React, { useContext, useState, useEffect } from "react";
import AuthContext from "context/AuthContext";
import useUpdateProfile from "Hooks/Users/UpdateUser";
import {
  Container,
  Typography,
  TextField,
  Button,
  Grid,
  Box,
  Paper,
  Divider,
  useTheme,
} from "@mui/material";
import Header from "components/Header";
import LoadingComponent from "components/LoadingComponent.jsx";
import CustomSnackbar from "components/SnackBar";

const UserProfile = () => {
  const { user, isAuthenticated } = useContext(AuthContext);
  const { updateProfile, changePassword, isLoading, error, passwordError } =
    useUpdateProfile();

  const theme = useTheme();
  const [open, setOpen] = useState(false);

  useEffect(() => {
    if (user) {
      setFormData({
        first_name: user.first_name || "",
        last_name: user.last_name || "",
      });
    }
  }, [user]);

  const errorToString = (errorObj) => {
    if (typeof errorObj === "string") return errorObj;
    return Object.entries(errorObj)
      .map(([key, values]) => values.join(", "))
      .join(", ");
  };

  const [formData, setFormData] = useState({
    first_name: user?.first_name || "",
    last_name: user?.last_name || "",
  });

  const [passwordData, setPasswordData] = useState({
    oldPassword: "",
    newPassword: "",
  });

  const handleSnackbarClose = () => {
    setOpen(false);
  };

  const handleChange = (e) =>
    setFormData((prevData) => ({
      ...prevData,
      [e.target.name]: e.target.value,
    }));
  const handlePasswordChange = (e) =>
    setPasswordData((prevData) => ({
      ...prevData,
      [e.target.name]: e.target.value,
    }));

  const handleSubmit = async () => {
    await updateProfile(formData);
    setOpen(true);
  };

  const handlePasswordSubmit = async () => {
    await changePassword(passwordData.oldPassword, passwordData.newPassword);
    setOpen(true);
  };
  if (isLoading) {
    return <LoadingComponent />;
  }

  if (!isAuthenticated || !user) {
    return <LoadingComponent />;
  }

  return (
    <Container>
      <Box sx={{ p: 4 }}>
        <Box style={{ marginTop: "20px" }}>
          <Header
            title={"Användarprofil"}
            subtitle="Se och ändra din profil"
            style={{ color: theme.palette.textColor.main }}
          />
        </Box>
        <Divider
          sx={{ marginTop: theme.spacing(2), marginBottom: theme.spacing(2) }}
        />
        <Paper
          elevation={1}
          sx={{ boxShadow: "0px 5px 15px rgba(0, 0, 0, 0.1)" }}
        >
          <Box mt={3}>
            <Box padding={3}>
              <Typography variant="h6" gutterBottom>
                <b>Användarnamn:</b> {user?.username}
              </Typography>

              <Divider style={{ margin: "16px 0" }} />

              <Grid container spacing={3} direction="column">
                <Grid item>
                  <TextField
                    fullWidth
                    label="Förnamn"
                    name="first_name"
                    value={formData.first_name}
                    onChange={handleChange}
                    autoComplete="off"
                  />
                </Grid>
                <Grid item>
                  <TextField
                    fullWidth
                    label="Efternamn"
                    name="last_name"
                    value={formData.last_name}
                    onChange={handleChange}
                    autoComplete="off"
                  />
                </Grid>
                {error && (
                  <Grid item>
                    <Typography color="error">
                      {errorToString(error)}
                    </Typography>
                  </Grid>
                )}
                <Grid item>
                  <Button
                    variant="contained"
                    onClick={handleSubmit}
                    disabled={isLoading}
                    style={{
                      textTransform: "none",
                      fontWeight: "bold",
                    }}
                  >
                    Uppdatera Profil
                  </Button>
                </Grid>
              </Grid>
            </Box>
          </Box>
          <Box padding={3}>
            <Typography variant="h5" gutterBottom>
              Ändra lösenord
            </Typography>
            <Divider style={{ margin: "16px 0" }} />
            <Grid container spacing={3} direction="column">
              <Grid item>
                <TextField
                  fullWidth
                  label="Gammalt lösenord"
                  type="password"
                  name="oldPassword"
                  value={passwordData.oldPassword}
                  onChange={handlePasswordChange}
                  autoComplete="off"
                />
              </Grid>
              <Grid item>
                <TextField
                  fullWidth
                  label="Nytt lösenord"
                  type="password"
                  name="newPassword"
                  value={passwordData.newPassword}
                  onChange={handlePasswordChange}
                  autoComplete="off"
                />
              </Grid>
              {passwordError && (
                <Grid item>
                  <Typography color="error">
                    {errorToString(passwordError)}
                  </Typography>
                </Grid>
              )}
              <Grid item>
                <Button
                  variant="contained"
                  onClick={handlePasswordSubmit}
                  disabled={isLoading}
                  style={{
                    textTransform: "none",
                    fontWeight: "bold",
                  }}
                >
                  Ändra lösenord
                </Button>
              </Grid>
            </Grid>
          </Box>
        </Paper>
      </Box>
      <CustomSnackbar
        open={open}
        handleClose={handleSnackbarClose}
        message={
          !(error || passwordError)
            ? "Uppdatering av profil lyckades!"
            : "Uppdatering av profil misslyckades"
        }
        severity={!(error || passwordError) ? "success" : "error"}
      />
    </Container>
  );
};

export default UserProfile;
