import { useMutation } from "react-query";

const createCollection = async (newCollection, authTokens) => {
  const baseUrl = process.env.REACT_APP_BASE_URL
    ? process.env.REACT_APP_BASE_URL
    : "http://localhost:8000";

  const response = await fetch(`${baseUrl}/shopify-api/collections/add/`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    credentials: 'include',  // Include this line
    body: JSON.stringify(newCollection),
  });
  if (!response.ok) {
    throw new Error('Network response was not ok');
  }
  return response.json();
};

const useCreateShopifyCollection = () => {

  const mutation = useMutation((newCollection) => createCollection(newCollection));

  return mutation;
};

export default useCreateShopifyCollection;
