import { Box } from "@mui/material"
import { DataGrid } from "@mui/x-data-grid";
import { styled } from "@mui/system";

export const Container = styled(Box)({
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "90vh",
    width: "100%",
    padding: "20px",
    overflow: "auto", // Enable scroll bars
    maxWidth: "100%", // this will prevent it from going over the screen width
  });
  
 export const StyledDataGrid = styled(DataGrid)({
    width: "100%",
    "& .MuiDataGrid-root": {
      justifySelf: "center",
      overflow: "hidden",
      overflowX: "auto", // or 'scroll' if you want to always show the scrollbar
    },
    "& .MuiDataGrid-cell": {
      color: "#1a3e72",
      textAlign: "center",
      overflow: "hidden",
      textOverflow: "ellipsis", // this will add '...' if the content is too large for the cell
      whiteSpace: "nowrap", // this will prevent the content from wrapping to the next line
    },
    "& .MuiDataGrid-columnsContainer": {
      backgroundColor: "#e0f7fa",
      justifyContent: "center",
    },
    "& .MuiDataGrid-columnHeaderTitle": {
      fontWeight: "bold",
    },
  });