import { Typography, Box, Button } from '@mui/material';
import { styled } from '@mui/system';
import { useNavigate } from 'react-router-dom';

const CenterBox = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  height: '100vh', // full viewport height
  backgroundSize: 'cover',
  fontFamily: 'Arial, sans-serif',
  color: "black"
});

const NotFoundPage = () => {
  const navigate = useNavigate();
  
  const navigateToHome = () => {
    navigate("/home");
  }

  return (
    <CenterBox>
      <Typography variant="h1" sx={{ fontSize: '8em' }}>404</Typography>
      <Typography variant="h4" sx={{ fontSize: '2em', marginBottom: "1rem" }}>Page Not Found</Typography>
      <Button variant="contained" color="primary" onClick={navigateToHome}>
        Gå till hem
      </Button>
    </CenterBox>
  );
};

export default NotFoundPage;
