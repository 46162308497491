import {
  setAuthenticated,
  setUser,
  setLoading,
  checkAuthentication,
} from "actions/authActions.js";
import { createContext, useEffect, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import LoadingScreen from "pages/Login/LoadingScreen";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { isMobileOnly } from "react-device-detect";

const AuthContext = createContext();
export default AuthContext;

const TOAST_IDS = {
  SESSION_EXPIRED: "SESSION_EXPIRED",
  ACCESS_FORBIDDEN: "ACCESS_FORBIDDEN",
};

export const AuthProvider = ({ children }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);
  const user = useSelector((state) => state.auth.user);
  const loading = useSelector((state) => state.auth.loading);
  const TOKEN_REFRESH_INTERVAL = 1000 * 5 * 60;

  const logoutUser = useCallback(async () => {
    try {
      const response = await fetch(
        `${
          process.env.REACT_APP_BASE_URL || "http://localhost:8000"
        }/api/logout/`,
        {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          credentials: "include",
        }
      );

      if (response.ok) {
        dispatch(setAuthenticated(false));
        dispatch(setUser(null));
        localStorage.removeItem('shopName'); // Removing shopName from localStorage
        localStorage.removeItem('openSideBar'); // Removing openSideBar from localStorage

        navigate("/login");
      } else {
        console.error("Fel under utloggning:", await response.json());
      }
    } catch (error) {
      console.error("Fel vid utloggning:", error);
      toast.error("Ett fel inträffade vid utloggning. Vänligen försök igen.");
    }
  }, [dispatch, navigate]);

  const handleTokenRefreshError = useCallback(
    (response) => {
      if (response.status === 401) {
        toast.error("Sessionen har löpt ut. Loggar ut...");
        logoutUser();
      } else {
        console.error("Token uppdateringsfel med statuskod:", response.status);
        toast.error("Ett fel inträffade under tokenuppdatering.");
      }
    },
    [logoutUser]
  );

  const updateToken = useCallback(async () => {
    try {
      const response = await fetch(
        `${
          process.env.REACT_APP_BASE_URL || "http://localhost:8000"
        }/api/token/refresh/`,
        {
          method: "POST",
          credentials: "include",
        }
      );

      if (response.ok) {
        dispatch(setAuthenticated(true));
        return true;
      } else if (response.status === 401 || response.status === 403) {
        dispatch(setAuthenticated(false));
        handleTokenRefreshError(response);
        return false;
      } else {
        handleTokenRefreshError(response);
        return false;
      }
    } catch (error) {
      console.error("Fel vid uppdatering av token:", error);
      toast.error("Ett fel inträffade vid uppdatering av token.");
      return false;
    }
  }, [dispatch, handleTokenRefreshError]);

  // Starting withErrorHandling function
  const withErrorHandling = useCallback(
    async (apiCall, retry = true) => {
      console.log("Påbörjar withErrorHandling funktion");
      try {
        let response = await apiCall();
        console.log("Mottog svar med status:", response.status);

        // Kolla om svaret är 401 (Ej auktoriserad) eller 403 (Förbjudet) och uppdatera sedan token.
        if ((response.status === 401 || response.status === 403) && retry) {
          console.log("Försöker uppdatera token på grund av", response.status);
          const refreshed = await updateToken();
          console.log("refresh", refreshed);
          if (refreshed) {
            console.log("Tokenen uppdaterades inuti withErrorHandling");
            response = await withErrorHandling(apiCall, false);
            return response;
          }
        }

        // Hantera andra statuskoder
        if (!response.ok) {
          if (response.status === 401) {
            if (!toast.isActive(TOAST_IDS.SESSION_EXPIRED)) {
              toast.error("Sessionen har löpt ut. Loggar ut...", {
                toastId: TOAST_IDS.SESSION_EXPIRED,
              });
            }
            logoutUser();
          } else if (response.status === 403) {
            if (!toast.isActive(TOAST_IDS.ACCESS_FORBIDDEN)) {
              toast.error("Åtkomst förbjuden.", {
                toastId: TOAST_IDS.ACCESS_FORBIDDEN,
              });
            }
          } else {
            const errorToastId = `ERROR_${response.status}`;
            if (!toast.isActive(errorToastId)) {
              toast.error(`Fel med statuskod: ${response.status}`, {
                toastId: errorToastId,
              });
            }
          }
        }

        const contentType = response.headers.get("content-type");
        if (contentType && contentType.indexOf("application/json") !== -1) {
          return await response.json();
        } else {
          const invalidContentTypeToastId = "INVALID_CONTENT_TYPE";
          if (!toast.isActive(invalidContentTypeToastId)) {
            toast.error("Ogiltig innehållstyp mottagen.", {
              toastId: invalidContentTypeToastId,
            });
          }
        }
      } catch (error) {
        console.error("Fel i withErrorHandling:", error);
        dispatch(setLoading(false));
        dispatch(setAuthenticated(false));
        throw error;
      }
    },
    [dispatch, updateToken, logoutUser]
  );

  const fetchUserData = useCallback(async () => {
    const data = await withErrorHandling(() =>
      fetch(
        `${
          process.env.REACT_APP_BASE_URL || "http://localhost:8000"
        }/api/user-data/`,
        { credentials: "include" }
      )
    );
    if (data) {
      dispatch(setUser(data));
      return data;  // return the fetched data here
    }
}, [dispatch, withErrorHandling]);

  const checkAuthStatus = useCallback(async () => {
    dispatch(checkAuthentication());
    const data = await withErrorHandling(() =>
      fetch(
        `${
          process.env.REACT_APP_BASE_URL || "http://localhost:8000"
        }/api/check-auth/`,
        { credentials: "include" }
      )
    );

    if (data?.authenticated) {
      dispatch(setAuthenticated(true));
      fetchUserData();
    } else {
      dispatch(setAuthenticated(false));
    }
    dispatch(setLoading(false));
  }, [dispatch, fetchUserData, withErrorHandling]);

  const handleLoginError = useCallback((response) => {
    if (response.status === 401) {
      toast.error(
        "Autentisering misslyckades. Vänligen kontrollera dina uppgifter."
      );
    } else {
      console.error("Inloggningsfel med statuskod:", response.status);
      toast.error("Något gick fel!");
    }
  }, []);

  const loginUser =
    async (e, onSuccess, onError) => {
      e.preventDefault();
      dispatch(setLoading(false));
      try {
        const response = await fetch(
          `${
            process.env.REACT_APP_BASE_URL || "http://localhost:8000"
          }/api/token/`,
          {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({
              username: e.target.username.value,
              password: e.target.password.value,
            }),
            credentials: "include",
          }
        );

        if (response.ok) {
          dispatch(setAuthenticated(true));
          const fetchedUser = await fetchUserData();
          localStorage.setItem('shopName', fetchedUser.shopName); // Storing shopName in localStorage
          localStorage.setItem('first_name', fetchedUser.first_name); // Storing shopName in localStorage
          isMobileOnly ? navigate("/mobile/home") : navigate("/home");
          onSuccess && onSuccess();
        } else {
          handleLoginError(response);
          onError && onError();
        }
      } catch (error) {
        console.error("Fel vid inloggning:", error);
        toast.error("Ett fel inträffade. Vänligen försök igen.");
        onError && onError();
      } finally {
        dispatch(setLoading(false));
      }
    };

  useEffect(() => {
    checkAuthStatus();
    const interval = setInterval(() => {
      console.log("Kollar autentiseringsstatus...");
      checkAuthStatus();
    }, TOKEN_REFRESH_INTERVAL);
    return () => clearInterval(interval);
  }, [checkAuthStatus, TOKEN_REFRESH_INTERVAL]);

  return (
    <AuthContext.Provider
      value={{ isAuthenticated, user, loading, loginUser, logoutUser, updateToken }}
    >
      {loading ? <LoadingScreen /> : children}
    </AuthContext.Provider>
  );
};
