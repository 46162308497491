import React from "react";
import { Grid, Paper, Typography } from "@mui/material";
import MonetizationOnIcon from "@mui/icons-material/MonetizationOn";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import TableChartIcon from "@mui/icons-material/TableChart";
import BarChartIcon from "@mui/icons-material/BarChart";

import { useTheme } from "@mui/material/styles";
import { styled } from "@mui/material/styles";
import { useNavigate } from "react-router-dom";

const IconWrapper = styled("div")({
  display: "flex",
  justifyContent: "flex-start",
  marginBottom: "10px",
  opacity: 1,
});

const ValueText = styled(Typography)(({ theme }) => ({
  fontWeight: "bold",
  fontSize: theme.typography.pxToRem(20),
  opacity: 0.8,
}));

const LabelText = styled(Typography)(({ theme }) => ({
  fontSize: theme.typography.pxToRem(18),
  opacity: 0.8,
}));

const StatsBox = ({ title, value, color, bgColor, icon: Icon, to }) => {
  const theme = useTheme();
  const navigate = useNavigate(); // use navigate

  // Extract RGB values from the hex color
  const rgbColor = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(color);
  const r = parseInt(rgbColor[1], 16);
  const g = parseInt(rgbColor[2], 16);
  const b = parseInt(rgbColor[3], 16);

  // This function will be called when user click on the component
  const handleNavigation = () => {
    if (to) {
      navigate(to);
    }
  };

  return (
    <Grid item xs={12} sm={6} md={3}>
      <Paper
        onClick={handleNavigation}
        sx={{
          backgroundColor: bgColor,
          color: theme.palette.getContrastText(color),
          border: "0px solid rgba(0, 0, 0, 0.1)",
          borderRadius: "8px",
          p: 3,
          minHeight: "120px",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          boxShadow: "0px 5px 15px rgba(0, 0, 0, 0.1)",
          cursor: to ? "pointer" : "default", // change here
          "&:hover": {
            backgroundColor: to // change here
              ? "#FFDB98"
              : bgColor,
          },
        }}
      >
        <IconWrapper>
          <Icon fontSize="large" style={{ fill: `rgb(${r}, ${g}, ${b})` }} />
        </IconWrapper>
        <LabelText>{title}</LabelText>
        <ValueText>{value}</ValueText>
      </Paper>
    </Grid>
  );
};

const ProductStats = ({ products }) => {

  if (!products) {
    return null;
  }

  const totalProducts = Array.isArray(products) ? products.length : 0;

  const averagePrice = totalProducts > 0 ? (
    products.reduce((sum, product) => sum + parseFloat(product.variants?.[0]?.price || 0), 0) /
    totalProducts
  ).toFixed(2) : '0.00';
  
  const numActiveProducts = Array.isArray(products) ? products.reduce((count, product) => {
    if (product.status === 'active') {
      return count + 1;
    }
    return count;
  }, 0) : 0;
  

  return (
    <Grid container spacing={4} style={{ marginBottom: "2rem" }}>
      <StatsBox
        title="Antal produkter"
        value={`${totalProducts} st`}
        color="#DBD1EC"
        icon={BarChartIcon}
      />
      <StatsBox
        title="Medelpriset"
        value={`${averagePrice} kr`}
        color="#BDD4D3"
        icon={MonetizationOnIcon}
      />
      <StatsBox
        title="Antal aktiva produkter"
        value={`${numActiveProducts || 0} st`}
        color="#FFBFBF"
        icon={TableChartIcon}
      />
      <StatsBox
        title="Lägg till produkt"
        value="Klicka här"
        color="#FFA500"
        to="/add-product"
        icon={AddCircleIcon}
      />
    </Grid>
  );
};

export default ProductStats;

// const StatBox = ({ title, value, color, onClick }) => {
//   const theme = useTheme();

//   return (
//     <Grid item xs={12} sm={6} md={3}>
//       <Paper
//         onClick={onClick}
//         sx={{
//           backgroundColor: '#ffffff',
//           color: theme.palette.getContrastText(color),
//           border: '0px solid rgba(0, 0, 0, 0.1)',
//           borderRadius: '8px',
//           p: 3,
//           minHeight: '120px',
//           display: 'flex',
//           flexDirection: 'column',
//           alignItems: 'center',
//           justifyContent: 'center',
//           boxShadow: '0px 5px 15px rgba(0, 0, 0, 0.1)',
//           cursor: onClick ? 'pointer' : 'default', // checks if it has a onclick property, we want to add a cursor pointer
//         }}
//       >
//         <Box mb={1}>
//           <MonetizationOnIcon fontSize="large" style={{ fill: theme.palette.getContrastText(color) }} />
//         </Box>
//         <Box mb={0.5}>
//           <Typography variant="h4" component="div" gutterBottom fontWeight={550}>
//             {title}
//           </Typography>
//         </Box>
//         <Typography variant="h5" color="inherit">
//           {value}
//         </Typography>
//       </Paper>
//     </Grid>
//   );
// };
