import { useState } from 'react';

const useProductUpdate = (productId) => {
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);

  const updateProduct = async (updatedFields) => {

    setIsLoading(true);
    try {
        const baseUrl = process.env.REACT_APP_BASE_URL
        ? process.env.REACT_APP_BASE_URL
        : "http://localhost:8000/";

      const response = await fetch(`${baseUrl}/shopify-api/products/${productId}/update/`, {
        method: 'PUT',
        headers: {
          'Content-Type':'application/json',
        },
        credentials: 'include',  // Include this line
        body: JSON.stringify({
          ...updatedFields,
          price: parseFloat(updatedFields.price),
          compare_at_price: parseFloat(updatedFields.compare_at_price)
        })
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
  
      // Handle the success response if needed
    } catch (error) {
      setIsError(true);
    }
    setIsLoading(false);
  };

  return { updateProduct, isLoading, isError };
};

export default useProductUpdate;
