import React, { useMemo } from "react";
import { Grid, Box, Typography } from "@mui/material";

import moment from "moment";
import { useNavigate } from "react-router-dom";

// ICONS
import KeyboardArrowRightRoundedIcon from "@mui/icons-material/KeyboardArrowRightRounded";
import NewReleasesIcon from "@mui/icons-material/NewReleases";
import HealthAndSafetyIcon from "@mui/icons-material/HealthAndSafety";
import ReceiptLongIcon from "@mui/icons-material/ReceiptLong";
import LibraryAddCheckIcon from "@mui/icons-material/LibraryAddCheck";

//HOOKS
import GetUpcomingOrders from "../../../Hooks/Catering/useGetUpcommingOrders";

// UTILS AND STYLING
import { getStatusFromTags } from "utilities/getStatusFromTags";
import { ViewAllButton } from "./Style/Styles";
import { useTheme } from "@mui/material/styles";

// COMPONENTS
import StatsBox from "./components/statsbox.js"; // Import your StatsBox component
import ErrorComponent from "components/ErrorComponent";
import LoadingComponent from "components/LoadingComponent";
import OrdersChart from "./components/OrdersChart";

function calculateOrderStatusCount(orders, status) {
  return orders.filter((order) => getStatusFromTags(order.tags) === status)
    .length;
}

function MobileHomePage() {
  const navigate = useNavigate();
  const theme = useTheme();

  const storeId = localStorage.getItem("storeId");

  const {
    upcomingOrders: cateringOrder,
    errorOrders,
    isLoading,
    isError,
  } = GetUpcomingOrders(storeId);

  // filter the errored orders depending on their status, dont want to show them if they are Levererade
  const filteredErrorOrders = errorOrders
    ? errorOrders.filter((order) => {
        const status = getStatusFromTags(order.tags);
        return status !== "Levererad";
      })
    : [];

  const ordersPerDay = useMemo(() => {
    if (cateringOrder) {
      return getOrdersPerDay(cateringOrder);
    } else {
      return []; // If `cateringOrder` is falsy, return an empty array.
    }
  }, [cateringOrder]); // The computation will only re-run if `cateringOrder` changes.

  const colorMap = {
    Accepterad: theme.palette.status.accepterad,
    Färdig: theme.palette.status.fardig,
    Levererad: theme.palette.status.levererad,
    Mottagen: theme.palette.status.mottagen,
  };

  function getOrdersPerDay(orders) {
    moment.locale("sv"); // Set the locale to Swedish
    let ordersPerDay = [];

    // Loop through the next 7 days
    for (let day = 0; day < 7; day++) {
      // Calculate the start and end date of the current day
      let dayStartDate = moment().add(day, "days").startOf("day");
      let dayEndDate = moment(dayStartDate).endOf("day");

      // Find orders that are due this day by filtering them based on the delivery date
      let ordersThisDay = orders.filter((order) => {
        // Extract delivery date from the order's note attributes
        const leveransDatum =
          order.note_attributes?.find((attr) => attr.name === "Leveransdatum")
            ?.value || "01/01/1970";
        let deliveryDate = moment(leveransDatum, "DD/MM/YYYY");

        // Check if the delivery date is within the current day
        return (
          deliveryDate.isSameOrAfter(dayStartDate) &&
          deliveryDate.isBefore(dayEndDate)
        );
      });

      let dayStartFormatted = dayStartDate.format("ddd");

      // Add this day's order count to the result array
      ordersPerDay.push({
        dayStart: dayStartFormatted,
        numOrders: ordersThisDay.length,
      });
    }

    return ordersPerDay;
  }

  const handleOrdersNavigate = () => {
    navigate(`/mobile/landing/`);
  };

  if (isLoading) {
    return <LoadingComponent />;
  }

  if (isError) {
    return <ErrorComponent error={isError} />;
  }

  return (
    <Box sx={{ pb: 5 }}>
      <OrdersChart orders={ordersPerDay} />
      <Box m={2} sx={{ mt: 4 }} display="flex" justifyContent="space-between">
        <Typography
          variant="h3"
          component="h3"
          fontWeight="bold"
          gutterBottom
          sx={{ color: "#363636" }}
        >
          Just nu
        </Typography>
        <ViewAllButton
          onClick={handleOrdersNavigate}
          endIcon={<KeyboardArrowRightRoundedIcon />}
          sx={{
            alignSelf: "center",
          }}
        >
          Alla beställningar
        </ViewAllButton>
      </Box>
      <Grid
        container
        spacing={2}
        sx={{ marginBottom: 4, paddingLeft: 2, paddingRight: 2 }}
      >
        <StatsBox
          label="Mottagna"
          value={calculateOrderStatusCount(cateringOrder, "Mottagen")}
          unit=" st"
          color={colorMap["Mottagen"]}
          icon={ReceiptLongIcon}
        />
        <StatsBox
          label="Accepterade"
          value={calculateOrderStatusCount(cateringOrder, "Accepterad")}
          unit=" st"
          color={colorMap["Accepterad"]}
          icon={HealthAndSafetyIcon}
        />
        <StatsBox
          label="Färdiga"
          value={calculateOrderStatusCount(cateringOrder, "Färdig")}
          unit=" st"
          color={colorMap["Färdig"]}
          icon={LibraryAddCheckIcon}
        />
        <StatsBox
          label="Felaktiga beställningar"
          value={filteredErrorOrders.length}
          unit=" st"
          color={"#D0342C"}
          icon={NewReleasesIcon}
          bgColor={filteredErrorOrders.length === 0 ? "#e7e7e7" : "#ffffff"}
          opacity={filteredErrorOrders.length === 0 ? 0.5 : 1}
        />
      </Grid>
    </Box>
  );
}

export default MobileHomePage;
