const getTagColor = (tag) => {
    switch (tag) {
      case "Catering":
        return "#A37A74";
      case "Systemet":
        return "#63B0CD";
      default:
        return "";
    }
  };


  export default getTagColor;
