import React from 'react';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';

const InformationComponent = ({ message, buttonDestination }) => {
  if (!message) message = "";



  const navigate = useNavigate();

  // Function to redirect
  const handleButtonClick = () => {
    navigate(buttonDestination);
  }

  const infoContainerStyle = {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#ddffdd', // light green
    color: '#008800', // dark green
    padding: '20px',
    borderRadius: '10px',
    marginBottom: '20px',
    maxWidth: '80%',
    marginLeft: 'auto',
    marginRight: 'auto',
    marginTop: '50px',
  };

  const infoButtonStyle = {
    marginTop: '20px',
    padding: '10px 20px',
    backgroundColor: '#005500', // darker green
    color: '#fff',
    border: 'none',
    borderRadius: '5px',
    cursor: 'pointer',
    fontSize: '16px',
  };

  return (
    <div style={infoContainerStyle}>
      <h2>Information</h2>
      <p>{message}</p>
      {/* Redirect button */}
      <button style={infoButtonStyle} onClick={handleButtonClick}>Lägg till</button>
    </div>
  );
};

InformationComponent.propTypes = {
  message: PropTypes.string,
  buttonDestination: PropTypes.string,
};

export default InformationComponent;
