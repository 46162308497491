import { useState } from 'react';


const useCreateProduct = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  const createProduct = async (formData) => {
    setIsLoading(true);


    try {
      const baseUrl = process.env.REACT_APP_BASE_URL
      ? process.env.REACT_APP_BASE_URL
      : "http://localhost:8000";

      const response = await fetch(`${baseUrl}/shopify-api/products/add/`, {
        method: 'POST',
        body: formData,
        credentials: 'include',  // Include this line
      });
      
      if (!response.ok) {
        throw new Error('Something went wrong!');
      }

      // Reset error state if successful
      setError(null);
      
      const data = await response.json();
      console.log('Product created', data);
    } catch (error) {
      setError('An error occurred while creating the product.');
    } finally {
      setIsLoading(false);
    }
  };

  return { createProduct, isLoading, error };
};

export default useCreateProduct;
