// store.js

import { configureStore } from '@reduxjs/toolkit';
import authReducer from 'reducers/authReducer';
import globalReducer from 'reducers/globalReducer';

const store = configureStore({
    reducer: {
        global: globalReducer,
        auth: authReducer
    }
});

export default store;
