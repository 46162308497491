// color design tokens export
export const colorTokens = {
    grey: {
      0: "#ffffff", // manually adjusted
      10: "#f6f6f6", // manually adjusted
      50: "#f0f0f0", // manually adjusted
      100: "#e0e0e0",
      200: "#c2c2c2",
      300: "#a3a3a3",
      400: "#858585",
      500: "#666666",
      600: "#525252",
      700: "#3d3d3d",
      800: "#292929",
      900: "#141414",
      1000: "#000000", // manually adjusted
    },
    primary: {
      // blue
      100: "#d3d4de",
      200: "#a6a9be",
      300: "#7a7f9d",
      400: "#4d547d",
      500: "#27294A",
      600: "#191F45", // manually adjusted
      700: "#141937",
      800: "#0d1025",
      900: "#070812",
    },
    secondary: {
      // yellow
      50: "#f0f0f0", // manually adjusted
      100: "#fff6e0",
      200: "#ffedc2",
      300: "#ffe3a3",
      400: "#ffda85",
      500: "#ffd166",
      600: "#2B555A",
      700: "#444444",
      800: "#444444",
      900: "#332a14",
    },

    textColor: {
      "mainLight": "#444444",
      "mainDark": "#ffffff",
    },

    background: {
      "mainLightBackground": "#FAFAFA",
      "secondaryLightBackground": "#F0F0F0",
      "thirdLightBackground": "#FBFBFB",
      "mainDarkBackground": "#191B1F",
      "secondaryDarkBackground": "#191B1F",
      "thirdDarkBackground": "#1F1E1F"
    },

    green: {
      "mainGreen":  "#C00020", // manually adjusted
      // 2B555A
      "lightGreen": "#79A8A9",
      // 79A8A9
    },

    greens: {
      "main": "#80BEBF",
      // 79A8A9
    },

    warning: {
      "warningRed":  "#F44336", // manually adjusted
    },

    status: {
      "mottagen": "#636E71",
      "accepterad": "#3498DB",
      "fardig": "#25AF60",
      "levererad": "#E67E23"
    },
    severity: {
      "success": "#59B259",
      "error": "#FA3E3E",
    }
  };

  //6377FF OLD BLUE


  
  
  
  // mui theme settings
  export const themeSettings = (mode) => {
    return {
      palette: {
        mode: mode,
        ...(mode === "light"
        ? {
          success: {
            main: colorTokens.severity["success"],
            contrastText: '#ffffff',
          },
          error: {
            main: colorTokens.severity["error"],
            contrastText: '#ffffff'
          },
          // palette values for light mode
          primary: {
            main: colorTokens.primary[400],
            light: colorTokens.primary[400],
          },
          secondary: {
                ...colorTokens.secondary,
                main: colorTokens.secondary[300],
              },
              neutral: {
                ...colorTokens.grey,
                main: colorTokens.grey[500],
              },
              background: {
                main: colorTokens.background["mainLightBackground"],
                secondary: colorTokens.background["secondaryLightBackground"],
                third: colorTokens.background["thirdLightBackground"],
              },
              green: {
                main: colorTokens.green["mainGreen"],
                light: colorTokens.green["lightGreen"],
              },
              textColor: {
                main: colorTokens.textColor["mainLight"],
              },
              status: {
                mottagen: colorTokens.status["mottagen"],
                accepterad: colorTokens.status["accepterad"],
                fardig: colorTokens.status["fardig"],
                levererad: colorTokens.status["levererad"],
              },
              greens: {
                main: colorTokens.greens["main"],
              }
            }
          : {
              // palette values for light mode
              primary: {
                main: colorTokens.grey[50],
                light: colorTokens.grey[100],
              },
              secondary: {
                main: colorTokens.secondary[600],
                light: colorTokens.secondary[700],
              },
              neutral: {
                main: colorTokens.grey[500],
              },
              background: {
                main: colorTokens.background["mainDarkBackground"],
                secondary: colorTokens.background["secondaryDarkBackground"],
                third: colorTokens.background["thirdDarkBackground"],
              },
              green: {
                main: colorTokens.green["mainGreen"],
                light: colorTokens.green["lightGreen"],
              },
              textColor: {
                main: colorTokens.textColor["mainDark"],
              },
              status: {
                mottagen: colorTokens.status["mottagen"],
                accepterad: colorTokens.status["accepterad"],
                fardig: colorTokens.status["fardig"],
                levererad: colorTokens.status["levererad"],
              },
              greens: {
                main: colorTokens.greens["main"],
              }
            }),
      },
      typography: {
        fontFamily: ["Manrope", "Inter", "sans-serif"].join(","),
        fontSize: 12,
        h1: {
          fontFamily: ["Manrope", "Inter", "sans-serif"].join(","),
          fontSize: 40,
        },
        h2: {
          fontFamily: ["Manrope", "Inter", "sans-serif"].join(","),
          fontSize: 32,
        },
        h3: {
          fontFamily: ["Manrope", "Inter", "sans-serif"].join(","),
          fontSize: 24,
        },
        h4: {
          fontFamily: ["Manrope", "Inter", "sans-serif"].join(","),
          fontSize: 20,
        },
        h5: {
          fontFamily: ["Manrope", "Inter", "sans-serif"].join(","),
          fontSize: 16,
        },
        h6: {
          fontFamily: ["Manrope", "Inter", "sans-serif"].join(","),
          fontSize: 14,
        },
      },
    };
  };