export function getDeliveryDate(order){
    if (!order || !order.note_attributes || !Array.isArray(order.note_attributes)) {
        return "";
    }
    const date = order.note_attributes.find(attr => attr.name === 'Leveransdatum')?.value;
    return date || "";
}

export function getDeliveryTimeRange(order){
    if (!order || !order.note_attributes || !Array.isArray(order.note_attributes)) {
        return "";
    }
    const timeRange = order.note_attributes.find(attr => attr.name === 'Leveranstid')?.value;
    return timeRange || "";
}


    export function getDeliveryDay(order) {
        if (!order || !order.note_attributes || !Array.isArray(order.note_attributes)) {
            return "";
        }

        // Find the Leveransdatum from the note_attributes
        const leveransdatum = order.note_attributes.find(attr => attr.name === 'Leveransdatum')?.value;
        if (!leveransdatum) {
            return "";
        }

        // Assuming the date is in dd/mm/yyyy format, we need to split it and rearrange to mm/dd/yyyy
        const parts = leveransdatum.split('/');
        if (parts.length !== 3) {
            return ""; // Return empty string if date format is not correct
        }
        const formattedDate = `${parts[1]}/${parts[0]}/${parts[2]}`; // rearrange to mm/dd/yyyy

        // Parse the date string to a Date object
        const date = new Date(formattedDate);

        // Check if the date is an Invalid Date
        if (isNaN(date)) {
            return ""; // Return empty string if date is invalid
        }

        // Use toLocaleDateString to get the weekday in Swedish
        const options = { weekday: 'long' };
        const dayOfWeek = date.toLocaleDateString('sv-SE', options);

        return dayOfWeek.charAt(0).toUpperCase() + dayOfWeek.slice(1); // Capitalize the first letter
    }


