import React, { useState } from "react";
import {
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  Box,
  TextField,
  Divider,
  Button,
} from "@mui/material";
import {
  StyledTable,
  LoadingContainer,
  ErrorText,
  StyledTableContainer,
  TableHeaderCell,
  Root,
  DatePickerContainer
} from './Style/Styles';

import Pulse from "react-spinners/PulseLoader";

import { useTheme } from "@mui/material";
import Header from "components/Header";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import moment from "moment";
import "moment/locale/sv";
import useOrdersAndProductsBetweenDates from "Hooks/Catering/GetOrderBetween";

const today = moment();
const twoWeeksFromNow = moment().add(14, "days");

function OrderDataComponent() {
  moment.locale("sv");
  // sets the start of the week to monday
  moment.updateLocale("sv", {
    week: {
      dow: 1,
    },
  });
  const theme = useTheme();
  const [startDate, setStartDate] = useState(today);
  const [endDate, setEndDate] = useState(twoWeeksFromNow);

  // used as temporary before the actual search (removes having to load multiple times for changing dates)
  const [tempStartDate, setTempStartDate] = useState(today);
  const [tempEndDate, setTempEndDate] = useState(twoWeeksFromNow);

  const {
    orders: ingredientQuantities,
    isLoading,
    isError,
  } = useOrdersAndProductsBetweenDates(startDate.toDate(), endDate.toDate());

  
  const handleSetTempStartDate = (date) => {
    setTempStartDate(date);
  };

  const handleSetTempEndDate = (date) => {
    setTempEndDate(date);
  };

  const handleSearch = () => {
    setStartDate(tempStartDate);
    setEndDate(tempEndDate);
  };

  if (isLoading) {
    return (
      <LoadingContainer>
        <Box
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Pulse color="#C00020" speedMultiplier={0.65}/>
          <Typography style={{ marginTop: "20px", fontWeight: "normal" }}>
            Detta kan ta lite tid
          </Typography>
        </Box>
      </LoadingContainer>
    );
  }

  if (isError) {
    return <ErrorText variant="h6">Error: {isError}</ErrorText>;
  }


  return (
    <Box sx={{ p: 4 }}>
      <Box style={{ marginTop: "20px", marginLeft: "20px" }}>
        <Header
          title={"Råvaror"}
          subtitle="Se mängden råvaror som förväntas gå åt"
          style={{ color: theme.palette.textColor.main }}
        />
        <DatePickerContainer>
          <LocalizationProvider dateAdapter={AdapterMoment} locale={moment}>
            <Box flexDirection="column" display="flex" alignItems="center">
              <Box display="flex" flexDirection="row" alignItems="center">
                <Box>
                  <Typography variant="subtitle1" mr={1}>
                    Från:
                  </Typography>
                  <DatePicker
                    value={startDate}
                    onChange={(date) => handleSetTempStartDate(date)}
                    TextField={(params) => <TextField {...params} />}
                  />
                </Box>
                <Box>
                  <Typography variant="subtitle1" mr={1}>
                    Till:
                  </Typography>
                  <DatePicker
                    value={endDate}
                    onChange={(date) => handleSetTempEndDate(date)}
                    TextField={(params) => <TextField {...params} />}
                  />
                </Box>
              </Box>
              <Box display="flex" justifyContent="center" mt={2}>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleSearch}
                  style={{
                    fontWeight: "bold",
                    textTransform: "none",
                  }}
                >
                  Sök
                </Button>
              </Box>
            </Box>
          </LocalizationProvider>
        </DatePickerContainer>
      </Box>
      <Divider
        sx={{ marginTop: theme.spacing(2), marginBottom: theme.spacing(2) }}
      />
      <Root>
        <StyledTableContainer>
          <StyledTable aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableHeaderCell> Råvara </TableHeaderCell>
                <TableHeaderCell align="right"> Kvantitet </TableHeaderCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {ingredientQuantities &&
                Object.entries(ingredientQuantities).map(
                  ([ingredientName, quantityData]) => {
                    return (
                      <TableRow key={ingredientName}>
                        <TableCell component="th" scope="row">
                          {ingredientName}
                        </TableCell>
                        <TableCell align="right">
                          {Number(quantityData.quantity).toFixed(1) + ' ' + quantityData.entity}
                        </TableCell>
                      </TableRow>
                    );
                  }
                )}
            </TableBody>
          </StyledTable>
        </StyledTableContainer>
      </Root>
    </Box>
  );
}

export default OrderDataComponent;
