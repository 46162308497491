// AuthRoutes.jsx
import { Route, Routes, Navigate} from 'react-router-dom';
import QuickView from "./pages/Login/quickView.jsx"
import { AuthProvider } from './context/AuthContext'

import LoginPage from "./pages/Users/Login";
import UserProfile from "pages/Users/UserAccount";

import HomePage from "./pages/Home/HomePage.jsx";

import CurrentCateringOrders from 'pages/Orders/OrdersTable/index.jsx';
import CateringOrdersCalendar from "pages/Orders/OrdersCalendar/index.jsx";
import EditCateringOrder from "./pages/Orders/OrderEdit/index.jsx";
import SinglePostPage from "./pages/Posts/DetailPosts/index.jsx"
import ProductDetails from "./pages/Products_Folder/ProductDetails/index.jsx"
import OrderSummaryView from './pages/Statistics/Product/index.jsx';


import SearchPage from "./pages/Search/index.jsx";


import ProductList from "./pages/Products_Folder/Products/index.jsx"
import CreateProductForm from 'pages/Products_Folder/NewProduct';

import AllPostsPage from "./pages/Posts/AllPosts/index.jsx"
import NewPostForm from "./pages/Posts/AddPost";


import RightNow from 'pages/RightNow/index.jsx';

import PrivateRoute from './pages/Login/ProtectedRoute'

import Layout from "./pages/Layout/Layout";

import MobileLayout from 'pages/Layout/MobileLayout.jsx';

import CateringChart from "./pages/Statistics/Order/index.jsx";

import CommoditiesStats from './pages/Statistics/Ingridients/index.jsx';

import BookedPage from "./pages/Orders/OrdersBooked/index.jsx";

import MobileLandingPage from "./pages/Mobile/LandingPage/index";
import MobileUpcommingOrders from "./pages/Mobile/UpcommingOrders/index"

import MobileEditPage from "./pages/Mobile/EditOrder";
import MobileSearch from "./pages/Mobile/SearchMobile";
import MobileCreateProductForm from "./pages/Mobile/NewProduct";
import MobileHomePage from "./pages/Mobile/Home";



const AuthRoutes = () => {
  return (
    <AuthProvider>
      <Routes>
        <Route path="" element={<Navigate to="/home" replace />} />

        <Route path="/login" element={<LoginPage />} />

        <Route element={<Layout />}>

            <Route path="/QuickView" element={<PrivateRoute><QuickView /></PrivateRoute>} />
            <Route path="/home" element={<PrivateRoute><HomePage /></PrivateRoute>} />

            <Route path="/catering" element={<PrivateRoute><CurrentCateringOrders /></PrivateRoute>} />
            <Route path="/catering/:id/edit" element={<PrivateRoute><EditCateringOrder /></PrivateRoute>} />

            <Route path="/catering-stats" element={<PrivateRoute><CateringChart /></PrivateRoute>} />
            <Route path="/count" element={<PrivateRoute><OrderSummaryView /></PrivateRoute>} />
            <Route path="/commodities-stats" element={<PrivateRoute><CommoditiesStats /></PrivateRoute>} />

            


            <Route path="/search" element={<PrivateRoute><SearchPage /></PrivateRoute>} />
            <Route path="/bokforing" element={<PrivateRoute><BookedPage /></PrivateRoute>} />



            <Route path="/calanader" element={<PrivateRoute><CateringOrdersCalendar /></PrivateRoute>} />


            <Route path="/products" element={<PrivateRoute><ProductList /></PrivateRoute>} />
            <Route path="/product/:id" element={<PrivateRoute><ProductDetails /></PrivateRoute>} />
            <Route path="add-product" element={<PrivateRoute><CreateProductForm /></PrivateRoute>} />

            <Route path="/posts" element={<PrivateRoute><AllPostsPage /></PrivateRoute>} />
            <Route path="/add-post" element={<PrivateRoute><NewPostForm /></PrivateRoute>} />
            <Route path="/posts/:postId" element={<PrivateRoute><SinglePostPage /></PrivateRoute>} />
        
            <Route path="/profile" element={<PrivateRoute><UserProfile /></PrivateRoute>} />


            <Route path="/RightNow" element={<PrivateRoute><RightNow /></PrivateRoute>} />


          
    
        
        </Route>

            <Route path="mobile/*" element={<MobileLayout />}>
                <Route path="landing" element={<PrivateRoute><MobileLandingPage/></PrivateRoute>}/>
                <Route path="edit/:id" element={<PrivateRoute><MobileEditPage/></PrivateRoute>}/>
                <Route path="search" element={<PrivateRoute><MobileSearch/></PrivateRoute>} />
                <Route path="new-product" element={<PrivateRoute><MobileCreateProductForm/></PrivateRoute>}/>
                <Route path="home" element={<PrivateRoute><MobileHomePage/></PrivateRoute>} />

                <Route path="UpcommingOrders" element={<PrivateRoute><MobileUpcommingOrders/></PrivateRoute>} />

            </Route>




      </Routes>
    </AuthProvider>
  );
};

export default AuthRoutes;
