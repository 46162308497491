import React, { useEffect, useState } from "react";
import ReactQuill from "react-quill";
import {
  Card,
  CardContent,
  CardMedia,
  Typography,
  Button,
  Box,
  Chip,
  Divider,
  TextField,
  Grid,
  Modal,
  IconButton
} from "@mui/material";

import { useParams } from "react-router-dom";
// import useProductDetails from "../../../Hooks/Products/useProductDetails"; // Update the path as needed
import useProductUpdate from "../../../Hooks/Products/useProductUpdate";
import useProductDetails from "../../../Hooks/Products/getProductWithMetafieldsById";
import useSaveNote from "Hooks/Products/useSaveNote";
import useRemoveNote from "Hooks/Products/useRemoveNote";

import CustomSnackbar from "components/SnackBar";
import LoadingComponent from "components/LoadingComponent";
import ErrorComponent from "components/ErrorComponent";

import DeleteIcon from "@mui/icons-material/Delete";
import InsertDriveFileIcon from "@mui/icons-material/InsertDriveFile";
import ArrowOutwardIcon from "@mui/icons-material/ArrowOutward";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import { ArrowBackIos } from "@mui/icons-material";
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';

import "./Style/customQuill.css";

const ProductDetails = () => {
  const { id: productId } = useParams();

  // HOOKS
  const {
    updateProduct,
    isLoading: isUpdateLoading,
    isError: isUpdateError,
  } = useProductUpdate(productId);

  const { product, storeName, isLoading, isError } =
    useProductDetails(productId);

    
  // Combine all error flags
  // used for the snackbar

  const { saveNote, error: saveError, message: saveMessage } = useSaveNote();
  const { removeNote, removeError, message: removeMessage } = useRemoveNote();

  const isErrorAny = isUpdateError || isError || removeError;
  const severitySave = isUpdateError || saveError ? "error" : "success";
  const severityRemove = isUpdateError || removeError ? "error" : "success";
  const saveMessageDefault = !saveMessage ? (isErrorAny ? "Uppdatering av produkt misslyckas" : "Uppdatering av produkt lyckades!") : saveMessage;
  // CONSTS
  const imageField = product?.metafields?.find(
    (metafield) => metafield.key === "recipe"
  );
  const imageUrlRecepie = imageField?.value || "";

  const [openSnackbarSave, setOpenSnackbarSave] = useState(false);
  const [openSnackbarRemove, setOpenSnackbarRemove] = useState(false);
  const [showVariants, setShowVariants] = useState(false);
  const [hasChanges, setHasChanges] = useState(false);
  const [isHelpModalOpen, setIsHelpModalOpen] = useState(false);
  const [notes, setNotes] = useState([]);

  const [updatedFields, setUpdatedFields] = useState({
    title: "",
    body_html: "",
    variants: [],
    status: product?.status,
  });

  
  useEffect(() => {
    if (product) {
      setUpdatedFields({
        title: product.title || "",
        body_html: product.body_html || "",
        variants: product.variants || [],
        status: product.status || "",
      });
    }
    const noteMetafield = product?.metafields?.find(
      (metafield) => metafield.key === "note"
      );
      const noteValue = noteMetafield?.value || "";
      
      setNotes(noteValue.split(/\r?\n/));
    }, [product]);
    
  const handleOpenShopfiy = (productName) => {
    window.open(
      `https://${storeName}.myshopify.com/products/${productName}`,
      "_blank"
    );
  };

  const handleNoteChange = (index, value) => {
    const updatedNotes = [...notes];
    updatedNotes[index] = value;
    setNotes(updatedNotes);
  };

  const handleSnackbarClose = () => {
    setOpenSnackbarSave(false);
    setOpenSnackbarRemove(false);
  };

  const handleAddNote = () => {
      setNotes([...notes, ""]);
    
  };

  const handleDraft = async () => {
    handleChangeField("status", "draft");
  };

  const handleActive = async () => {
    handleChangeField("status", "active");
  };

  const handleToggleVariants = () => {
    setShowVariants(!showVariants);
  };

  const handleUpdateFields = () => {
    const success = updateProduct(updatedFields);
    if (success) {
      setOpenSnackbarSave(true);
      setHasChanges(false);
    }
  };

  const handleQuillChange = (field, value, source) => {
    if (source === 'user') {
      handleChangeField(field, value);
    }
  }
  // function to handle the updatedFields
  // checks if the index is valid and checks if the previous variants is an array
  const handleChangeField = (field, value, variantIndex) => {
    setHasChanges(true);
    if (variantIndex !== undefined) {
      setUpdatedFields((prev) => {
        const updatedVariants = Array.isArray(prev.variants)
          ? [...prev.variants]
          : [];
        updatedVariants[variantIndex] = {
          ...updatedVariants[variantIndex],
          [field]: value,
        };
        return {
          ...prev,
          variants: updatedVariants,
        };
      });
    } else {
      setUpdatedFields((prevFields) => ({
        ...prevFields,
        [field]: value,
      }));
    }
  };

  const handleRemoveNote = async (index) => {
    const updatedNotes = [...notes];
    updatedNotes.splice(index, 1);
    setNotes(updatedNotes);

    try {
      await removeNote(notes, index, productId);
      setOpenSnackbarRemove(true);
    } catch (error) {
      console.log(error);
    }
  };

  const handleSaveNote = async () => {
    try {
      // if (notes) {
      //   const hasInvalidNoteLength = notes.some(note => {
      //     const splitNote = note.split(" ");
      //     return splitNote.length < 3 || splitNote.length > 3;
      //   });
  
      //   if (hasInvalidNoteLength) {
      //     console.error("One or more notes have an invalid word count!");
      //     return; // Exit the function early
      //   }
  
      //   for (const note of notes) {
      //     console.log(note.split(" ")); // This will print an array of words for each note
      //   }
      // }
      await saveNote(notes, productId);
      setOpenSnackbarSave(true);
    } catch (error) {
      console.log(error);
    }
  };

  if (isLoading) {
    return <LoadingComponent />;
  }

  if (isErrorAny || !product) {
    return <ErrorComponent error={isErrorAny} />;
  }
  
  const renderNoteItems = () => (
    <Box>
      {notes.map((note, index) => (
        <Box key={index} sx={{ display: "flex", alignItems: "center", mt: 2 }}>
          <TextField
            fullWidth
            value={note}
            onChange={(e) => handleNoteChange(index, e.target.value)}
            label={`Råvara ${index + 1}`}
            variant="outlined"
            helperText={
              index === notes.length - 1 ? "Exempelvis: 0.5 kg kalkon" : ""
            }
            autoComplete="off"
          />
          <Button
            sx={{
              ml: 2,
              color: "#95a5a6", // Main color
              border: "1px solid #C4C4C4", // Border color
              "&:hover": {
                backgroundColor: "#f44336", // Change color to red when hovering
                color: "#ffffff", // Text (and icon) color when hovering
              },
              padding: "15px 16px", // Adjust padding as needed
            }}
            onClick={() => handleRemoveNote(index)}
            variant="outlined"
          >
            <DeleteIcon fontSize="small" /> {/* Change the size here */}
          </Button>
        </Box>
      ))}

      <Box sx={{ display: "flex", flexDirection: "column", mt: 3 }}>
        <Button
          sx={{
            mt: 0,
            fontsize: "14px",
            color: "#2B555A", // Main color
            border: "1px solid #C4C4C4", // Border color
            "&:hover": {
              color: "#2B555A", // Text (and icon) color when hovering
              boxShadow: "inset 0 0 0 1px #C4C4C4", // Create an inset shadow effect
            },
            padding: "10px 20px", // Adjust padding as needed to increase button size
          }}
          onClick={handleAddNote}
          variant="outlined"
          startIcon={<AddIcon />}
        >
          Lägg till råvara
        </Button>

        <Button
          variant="contained"
          color="primary"
          onClick={handleSaveNote}
          sx={{
            mt: 2,
            mb: 2,
            backgroundColor: "#2B555A", // Main color
            color: "white", // Text color
            padding: "10px 20px", // Adjust padding as needed to increase button size
            fontSize: "12px", // Adjust font size as needed
            "&:hover": {
              backgroundColor: "#2B555A", // Change hover color to match
            },
            fontWeight: "bold",
            textTransform: "none",
          }}
        >
          Spara råvaror
        </Button>
      </Box>
    </Box>
  );

  const tags = product?.tags.split(","); // Split tags string into an array
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        mt: 3,
      }}
    >
      <Box
        sx={{
          width: "100%",
          maxWidth: 1000,
          display: "flex",
          alignItems: "center",
          justifyContent: "flex-start",
        }}
      >
        <Button
          variant="outlined"
          color="primary"
          onClick={() => window.history.back()}
          startIcon={<ArrowBackIos />}
          sx={{
            mb: 2,
            textTransform: "none",
          }}
        >
          Gå tillbaka
        </Button>
      </Box>
      <Card sx={{ maxWidth: 1000, width: "100%" }}>
        <CardMedia
          component="img"
          height="350"
          image={product.images?.[0]?.src || "https://via.placeholder.com/150"}
          alt={product.title}
        />
        <CardContent>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-start",
              mt: 3,
            }}
          >
            <Button
              variant="contained"
              color="primary"
              onClick={() => {
                const imageUrl = imageUrlRecepie;
                window.open(imageUrl, "_blank");
              }}
              disabled={!imageUrlRecepie} // Disable the button if imageUrlRecepie is falsy
              sx={{
                ml: "auto",
                bgcolor: "#79A8A9",
                textTransform: "none",
                fontWeight: "bold",
              }}
              startIcon={<InsertDriveFileIcon />}
            >
              Recept
            </Button>
            <Button
              onClick={() => handleOpenShopfiy(product.handle)}
              variant="contained"
              endIcon={<ArrowOutwardIcon />}
              sx={{
                textTransform: "none",
                fontWeight: "bold",
                m: "10px",
              }}
            >
              Visa på hemsidan
            </Button>
          </Box>

          <Box sx={{ display: "flex", alignItems: "center" }}>
            <Typography variant="h4" component="div" gutterBottom>
              {product.title}
            </Typography>
          </Box>

          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography
                variant="subtitle1"
                color="text.secondary"
                gutterBottom
              >
                Leverantör: <b>{product.vendor}</b>
              </Typography>
              <Typography
                variant="subtitle1"
                color="text.secondary"
                gutterBottom
              >
                Produkt ID: <b>{product.id}</b>
              </Typography>
              <Typography
                variant="subtitle1"
                color="text.secondary"
                gutterBottom
              >
                Kategori: <b>{product.tags}</b>
              </Typography>
            </Grid>

            <Grid item xs={12}>
              <Typography
                variant="subtitle1"
                color="text.secondary"
                gutterBottom
              >
                Titel:
              </Typography>
              <TextField
                value={updatedFields.title}
                onChange={(e) => handleChangeField("title", e.target.value)}
                fullWidth
                autoComplete="off"
              />
            </Grid>
            <Grid item xs={12}>
              <Typography
                variant="subtitle1"
                color="text.secondary"
                gutterBottom
              >
                Beskrivning:
              </Typography>
              <ReactQuill
                className="my-quill-editor"
                value={updatedFields.body_html}
                onChange={(value, delta, source) => handleQuillChange("body_html", value, source)}
                modules={{
                  toolbar: [["bold", "italic", "underline"]],
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <Button
                variant="outlined"
                onClick={handleToggleVariants}
                endIcon={showVariants ? <RemoveIcon /> : <AddIcon />}
                sx={{
                  textTransform: "none",
                  fontWeight: "bold",
                  minWidth: "150px",
                }}
              >
                {showVariants ? "Göm varianter" : "Visa varianter"}
              </Button>
            </Grid>
            {showVariants &&
              (updatedFields.variants.length > 1 ? (
                updatedFields.variants.map((variant, index) => (
                  <React.Fragment key={index}>
                    <Grid item xs={12}>
                      <Typography
                        variant="subtitle1"
                        color="text.secondary"
                        gutterBottom
                      >
                        <b>{variant.title}</b> Pris (kampanjpris om ett <b>Struket pris</b> är satt högre):
                      </Typography>
                      <TextField
                        value={variant?.price ? variant.price : ""}
                        onChange={(e) => {
                          const value = e.target.value;
                          if (/^[0-9]*\.?[0-9]*$/.test(value)) {
                            handleChangeField("price", value, index);
                          }
                        }}
                        fullWidth
                        autoComplete="off"
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <Typography
                        variant="subtitle1"
                        color="text.secondary"
                        gutterBottom
                      >
                        <b>{variant.title}</b> Struket pris:
                        <IconButton onClick={() => setIsHelpModalOpen(true)}>
                        <HelpOutlineIcon />
                      </IconButton>
                      </Typography>
                      <TextField
                        value={
                          variant?.compare_at_price
                            ? variant.compare_at_price
                            : ""
                        }
                        onChange={(e) => {
                          const value = e.target.value;
                          if (/^[0-9]*\.?[0-9]*$/.test(value)) {
                            handleChangeField("compare_at_price", value, index);
                          }
                        }}
                        fullWidth
                        autoComplete="off"
                      />
                    </Grid>
                  </React.Fragment>
                ))
              ) : (
                <React.Fragment>
                  <Grid item xs={12}>
                    <Typography
                      variant="subtitle1"
                      color="text.secondary"
                      gutterBottom
                    >
                      Pris (kampanjpris om ett <b>Struket pris</b> är satt högre):
                    </Typography>
                    <TextField
                      value={
                        updatedFields?.variants[0]?.price
                          ? updatedFields.variants[0].price
                          : ""
                      }
                      onChange={(e) =>
                        handleChangeField("price", e.target.value, 0)
                      }
                      fullWidth
                      autoComplete="off"
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <Typography
                      variant="subtitle1"
                      color="text.secondary"
                      gutterBottom
                    >
                      Struket pris:
                      <IconButton onClick={() => setIsHelpModalOpen(true)}>
                        <HelpOutlineIcon />
                      </IconButton>
                    </Typography>
                    <TextField
                      value={
                        updatedFields?.variants[0]?.compare_at_price
                          ? updatedFields.variants[0].compare_at_price
                          : ""
                      }
                      onChange={(e) =>
                        handleChangeField("compare_at_price", e.target.value, 0)
                      }
                      fullWidth
                      autoComplete="off"
                    />
                  </Grid>
                </React.Fragment>
              ))}
            <Grid item xs={12}>
              <Box>
                {updatedFields.status === "draft" ? (
                  <Button
                    onClick={handleActive}
                    disabled={isUpdateLoading}
                    sx={{
                      mb: 2,
                      backgroundColor: "#0088D1", // Main color
                      color: "white", // Text color
                      "&:hover": {
                        backgroundColor: "#006DA8", // Change hover color to match
                      },
                      textTransform: "none",
                      fontWeight: "bold",
                      minWidth: "150px",
                    }}
                  >
                    Sätt som aktiv
                  </Button>
                ) : (
                  <Button
                    onClick={handleDraft}
                    disabled={isUpdateLoading}
                    sx={{
                      mb: 2,
                      backgroundColor: "#0088D1", // Main color
                      color: "white", // Text color
                      "&:hover": {
                        backgroundColor: "#006DA8", // Change hover color to match
                      },
                      textTransform: "none",
                      fontWeight: "bold",
                      minWidth: "150px",
                    }}
                  >
                    Sätt som inaktiv
                  </Button>
                )}
              </Box>

              <Box>
                {hasChanges && (
                  <Button
                    onClick={handleUpdateFields}
                    disabled={isLoading}
                    variant="contained"
                    sx={{
                      textTransform: "none",
                      fontWeight: "bold",
                      minWidth: "150px",
                    }}
                  >
                    {isLoading ? "Uppdaterar..." : "Uppdatera produkt"}
                  </Button>
                )}
              </Box>
            </Grid>
          </Grid>

          <Divider sx={{ mt: 2, mb: 2 }} />

          <CardContent>
            <Typography variant="h5" component="div" gutterBottom>
              Råvaror:
            </Typography>
            {renderNoteItems()}
          </CardContent>
          <Divider sx={{ mt: 2, mb: 2 }} />

          <Box sx={{ mt: 2, mb: 2 }}>
            {tags.map((tag, index) => (
              <Chip
                key={index}
                label={tag}
                sx={{ mr: 1, mb: 1 }}
                color="primary"
              />
            ))}
          </Box>
        </CardContent>
      </Card>
      <Divider sx={{ mt: 2, mb: 2 }} />
      <CustomSnackbar
        open={openSnackbarSave}
        handleClose={handleSnackbarClose}
        message={
          saveMessageDefault
        }
        severity={severitySave}
      />
      <CustomSnackbar
        open={openSnackbarRemove}
        handleClose={handleSnackbarClose}
        message={
          removeMessage
        }
        severity={severityRemove}
      />
       {/* Modal */}
        <Modal
        open={isHelpModalOpen}
        onClose={() => setIsHelpModalOpen(false)} // Close modal on close button click or outside click
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            bgcolor: "background.paper",
            boxShadow: 24,
            p: 5,
            borderRadius: "10px",
          }}
        >
          {/* Modal content */}
          <Typography variant="h4" sx={{fontWeight: "bold", mb: "25px"}} gutterBottom>
            Vad är struket pris?
          </Typography>
          <Typography variant="body1" gutterBottom>

          För att sätta ett överstruket pris, sätt <b>Struket pris</b> högre än <b>Pris</b>. Det vill säga kommer <b>Pris</b> i så fall bli det nya <b>kampanjpriset</b>!
          </Typography>
          <Button 
            onClick={() => setIsHelpModalOpen(false)}
            variant="outlined"
            sx={{
                mt: "25px",
                textTransform: "none",
                fontWeight: "bold",
            }}
            >
              Stäng
          </Button>
        </Box>
      </Modal>
    </Box>
  );
};

export default ProductDetails;
