import React from "react";
import { Box, useMediaQuery } from "@mui/material";
import { Outlet } from "react-router-dom";
import { useSelector } from "react-redux";
import Navbar from "components/Navbar";
import Sidebar from "components/Sidebar";
import { useNavigate, useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";
import { logout } from "state/authSlice";


const Layout = () => {
  const isNonMobile = useMediaQuery("(min-width: 600px)");
  const { user } = useSelector((state) => state.auth);
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();

  const isUnprotectedRoute = () => {
    return ["/login"].includes(location.pathname);
  };

  const handleLogout = () => {
    localStorage.removeItem("token");
    localStorage.removeItem("storeId");
    localStorage.removeItem("user");

    dispatch(logout());

    navigate("/login");

    window.location.reload();
  };

  return (
    <Box display={isNonMobile ? "flex" : "block"} width="100%" height="100%">
      {!isUnprotectedRoute() && (
        <Sidebar
          user={user || {}}
          isNonMobile={isNonMobile}
          drawerWidth="250px"
        />
      )}
      <Box flexGrow={1}>
        {!isUnprotectedRoute() && (
          <Navbar user={user || {}} onLogout={handleLogout} />
        )}
        <Outlet />
      </Box>
    </Box>
  );
  
};  

export default Layout;