import pdfMake from "pdfmake/build/pdfmake";
import translations from "translations.js";
import pdfFonts from "pdfmake/build/vfs_fonts";
import { getTotalPrice } from "./getTotalPrice.js";
import { getDeliveryDate, getDeliveryDay, getDeliveryTimeRange } from "./getNoteAttributes.js";
import data from "data/data.json" 
pdfMake.vfs = pdfFonts.pdfMake.vfs;

function getDeliveryCost(order) {
  var shopName = localStorage.getItem("shopName");
  const shopData = data.find(entry => entry.store === shopName);

  if (!shopData){
    return 0;
  }

  if (order.financial_status !== "paid"){
    return shopData.price;
  }
  return 0;
}

function dateFormat(dateString) {
  const date = new Date(dateString);
  const options = {
    year: "numeric",
    month: "long",
    day: "numeric",
    hour: "2-digit",
    minute: "2-digit",
  };

  return date.toLocaleString("sv-SE", options);
}

function colorForFinancialStatus(status) {
  switch (status) {
    case "paid":
      return "#D4EDCC";
    case "pending":
      return "#F4EAB8";
    default:
      return "#E6E6E6";
  }
}

function textColorForFinancialStatus(status) {
  switch (status) {
    case "paid":
      return "#2B555A";
    case "pending":
      return "#F45B00";
    default:
      return "#E6E6E6";
  }
}

function generatePDF(id, order, storeName) {
  const docDefinition = {
    content: [
      {
        columns: [
          {
            text: `${storeName}`,
            fontSize: 18,
            bold: true,
            marginBottom: 20,
            color: "#C00020"
          },
          {
            text: [
            {text: `Ordernummer: ${id}\n`,
            fontSize: 18,
            bold: true,
            alignment: "right",
            marginBottom: 0,},
            { text: "Skapad: ", fontSize: 11, bold: true, alignment: "right", },
            { text: `${dateFormat(order.created_at)}`, fontSize: 11, bold: false, alignment: "right", },
            ]
          },
        ],
      },
      {
        columns: [
          {
            text: [
              { text: "Namn: ", bold: true },
              {
                text: `${order.billing_address?.first_name} ${order.billing_address?.last_name} \n`,
                bold: false,
              },
              { text: "Email: ", bold: true },
              { text: `${order.customer?.email}\n`, bold: false },
              { text: "Telefon: ", bold: true },
              {
                text: `${
                  order.billing_address?.phone
                    ? order.billing_address?.phone
                    : "Ej angivet"
                }\n`,
                bold: false,
              },
            ],
            fontSize: 14,
            lineHeight: 1,
          },
          {
            text: [
              { text: "Leverans:\n", fontSize: 20, bold: true }, 
              { text: `${getDeliveryDate(order)}\n`, fontSize: 20, bold: false }, 
              { text: `${getDeliveryDay(order)}\n`, fontSize: 20, bold: false }, 
              { text: `${getDeliveryTimeRange(order)}\n`, fontSize: 20, bold: false }, 
              { text: "Status: ", fontSize: 20, bold: true },
              {
                text: `${
                  translations.financialStatus[order.financial_status]
                }\n`,
                color: textColorForFinancialStatus(order.financial_status),
                bold: true,
                background: colorForFinancialStatus(order.financial_status),
                fontSize: 20,
              },
              { text: "Moms: ", bold: true },
              {
                text: `${Math.round(getTotalPrice(order) - (getTotalPrice(order) / 1.12))} kr\n`,
                bold: false,
              },
              { text: "Tillägsavgift: ", bold: true },
              { text: `${getDeliveryCost(order)} kr\n`,
                bold: false,
              },
              { text: "Totalt: ", bold: true },
              {
                text: `${getTotalPrice(order)} kr\n`,
                bold: false,
              },
            ],
            fontSize: 14,
            lineHeight: 1,
            alignment: "right",
            marginLeft: 20,
          },
        ],
      },
      {
        text: `Beställningen`,
        fontSize: 16,
        bold: true,
        margin: [0, 20, 0, 10],
      },
      {
        table: {
          headerRows: 1,
          widths: ["*", "*", "*", "*", "*"],
          body: [
            [
              {
                text: "Vara",
                bold: true,
                fillColor: "#C00020",
                color: "white",
              },
              {
                text: "Antal",
                bold: true,
                fillColor: "#C00020",
                color: "white",
              },
              {
                text: "Pris",
                bold: true,
                fillColor: "#C00020",
                color: "white",
              },
              {
                text: "Leverantör",
                bold: true,
                fillColor: "#C00020",
                color: "white",
              },
              {
                text: "Totalt Pris",
                bold: true,
                fillColor: "#C00020",
                color: "white",
              },
            ],
            ...order.lineItems.map((item) => [
              item.title,
              item.quantity,
              (item.price - item?.total_discount / item.quantity),
              item.vendor,
              Math.round((item.price * item.quantity * 100) / 100) - item?.total_discount + ".00",
            ]),
          ],
        },
        layout: {
          fillColor: (rowIndex, node, columnIndex) => {
            return rowIndex % 2 === 0 ? "#F0F0F0" : null;
          },
          hLineColor: () => {
            return "#ccc";
          },
          vLineColor: () => {
            return "#ccc";
          },
          paddingTop: () => {
            return 6;
          },
          paddingBottom: () => {
            return 6;
          },
        },
      },
    ],
  };

  pdfMake.createPdf(docDefinition).download(`order-${id}.pdf`);
}

export default generatePDF;
