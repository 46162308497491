import {
  Grid,
  Box,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Pagination,
} from "@mui/material";
import * as React from "react";

export class TableFilterSort extends React.Component {
  render() {
    const {
      rowsPerPage,
      handleChangeRowsPerPage,
      selectedStatusTag,
      setSelectedStatusTag,
      sortedAndFilteredOrders,
      page,
      handleChangePage,
      selectedTypeDate,
      setSelectedTypeDate,
    } = this.props;

    return (
      <Grid item xs={12}>
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          mt={2}
        >
          <Box>
            <FormControl
              variant="outlined"
              sx={{ minWidth: 120, marginRight: "10px" }}
            >
              <InputLabel id="rows-per-page-label">Rader per sida</InputLabel>
              <Select
                labelId="rows-per-page-label"
                value={rowsPerPage}
                onChange={handleChangeRowsPerPage}
                label="Rows per page"
              >
                <MenuItem value={25}>25</MenuItem>
                <MenuItem value={50}>50</MenuItem>
                <MenuItem value={100}>100</MenuItem>
              </Select>
            </FormControl>

            <FormControl variant="outlined" sx={{ minWidth: 120, marginRight: "10px" }}>
              <InputLabel id="Status">Status</InputLabel>
              <Select
                labelId="Status"
                value={selectedStatusTag}
                onChange={(event) => {
                  const value = event.target.value;
                  const tag = value ? `${value}` : "";
                  setSelectedStatusTag(tag);
                }}
                variant="outlined"
                color="primary"
                label="Status"
              >
                <MenuItem value="" disabled>
                    <em>Välj status</em>
                </MenuItem>
                <MenuItem value="status: Mottagen">Mottagen</MenuItem>
                <MenuItem value="status: Accepterad">Accepterad</MenuItem>
                <MenuItem value="status: Färdig">Färdig</MenuItem>
                <MenuItem value="status: Levererad">Levererad</MenuItem>
                <MenuItem value="Alla">Alla</MenuItem>
              </Select>
            </FormControl>

            <FormControl variant="outlined" sx={{ minWidth: 140 }}>
              <InputLabel id="type-of-date">Sortera på</InputLabel>
              <Select
                value={selectedTypeDate}
                onChange={(event) => {
                  const value = event.target.value;
                  const sorting = value ? `${value}` : "Leveransdatum";
                  setSelectedTypeDate(sorting);
                }}
                variant="outlined"
                color="primary"
                label="Sortera på"
              >
                <MenuItem value="" disabled>
                    <em>Välj sortering</em>
                </MenuItem>
                <MenuItem value="Leveransdatum">Leveransdatum</MenuItem>
                <MenuItem value="Senaste">Senaste</MenuItem>
              </Select>
            </FormControl>
          </Box>

          <Pagination
            count={Math.ceil(sortedAndFilteredOrders.length / rowsPerPage)}
            page={page}
            onChange={handleChangePage}
            sx={{ color: "#ffffff" }}
            color="greens"
            showFirstButton
            showLastButton
            size="large"
          />
        </Box>
      </Grid>
    );
  }
}
