import { useState } from 'react';

const usePopover = () => {
    const [anchorEl, setAnchorEl] = useState(null);
  
    const openPopover = (event) => {
      setAnchorEl(event.currentTarget);
    };
  
    const closePopover = () => {
      setAnchorEl(null);
    };
  
    const isOpen = Boolean(anchorEl);
  
    return { anchorEl, openPopover, closePopover, isOpen };
  };
  
  export default usePopover;