import React from "react";
import { useGetUsersByStoreQuery } from "state/api.js";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Typography,
  Box,
  Grid,
  IconButton,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import { Link } from "react-router-dom";
import { styled } from "@mui/system";

const StyledTableHeader = styled(TableHead)(({ theme }) => ({
  backgroundColor: theme.palette.green.light,
}));

const StyledTableHeaderCell = styled(TableCell)(({ theme }) => ({
  color: "white",
  fontWeight: "bold",
}));

const StyledEditButton = styled(IconButton)(({ theme }) => ({
  color: theme.palette.primary.main,
}));

const UsersByStore = ({ storeId }) => {
  const idToUse = storeId || localStorage.getItem("storeId");
  const { data, error, isLoading } = useGetUsersByStoreQuery(idToUse);

  React.useEffect(() => {
    if (data) {
    }
  }, [data]);

  if (isLoading) return <div>Laddar...</div>;
  if (error) return <div>Fel: {error.message}</div>;

  return (
    <Box sx={{ flexGrow: 1, p: 4 }}>
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <Typography variant="h4" gutterBottom>
            Användare per butiks-ID {storeId}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <TableContainer component={Paper}>
            <Table>
              <StyledTableHeader>
                <TableRow
                  sx={{ backgroundColor: (theme) => theme.palette.green.dark }}
                >
                  <StyledTableHeaderCell>Namn</StyledTableHeaderCell>
                  <StyledTableHeaderCell>Email</StyledTableHeaderCell>
                  <StyledTableHeaderCell>Telefonnummer</StyledTableHeaderCell>
                  <StyledTableHeaderCell>Användartyp</StyledTableHeaderCell>
                  <StyledTableHeaderCell>Redigera</StyledTableHeaderCell>
                </TableRow>
              </StyledTableHeader>
              <TableBody>
                {data.map((user) => (
                  <TableRow key={user._id}>
                    <TableCell>
                      {user.firstName} {user.lastName}
                    </TableCell>
                    <TableCell>{user.email}</TableCell>
                    <TableCell>{user.phoneNumber}</TableCell>
                    <TableCell>{user.userType}</TableCell>
                    <TableCell>
                      <StyledEditButton
                        component={Link}
                        to={`/user/${user._id}/edit`}
                      >
                        <EditIcon />
                      </StyledEditButton>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      </Grid>
    </Box>
  );
};

export default UsersByStore;
