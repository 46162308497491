// authReducer.js

import { SET_AUTHENTICATED, SET_USER, SET_LOADING, CHECK_AUTHENTICATION } from "actions/authActions";

const initialState = {
    isAuthenticated: localStorage.getItem('isAuthenticated') === 'true',
    user: null,
    mode: 'defaultModeValue', 
    loading: true
};

const authReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_AUTHENTICATED:
            return { ...state, isAuthenticated: action.payload };
        case SET_USER:
            return { ...state, user: action.payload };
        case SET_LOADING:
            return { ...state, loading: action.payload };
        case CHECK_AUTHENTICATION:
            return { ...state }; // Here, you could perform any state changes as needed
        default:
            return state;
    }
};

export default authReducer;
