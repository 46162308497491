import React from 'react';
import { Grid, Paper, Typography, ButtonBase } from '@mui/material';
import { useTheme } from '@mui/system';
import { styled } from '@mui/system';

const IconWrapper = styled("div")({
    display: "flex",
    justifyContent: "flex-start",
    marginBottom: "10px",
    opacity: 1,
});

const ValueText = styled(Typography)(({ theme }) => ({
    fontWeight: "bold",
    fontSize: theme.typography.pxToRem(32),
    opacity: 0.8,
}));

const LabelText = styled(Typography)(({ theme }) => ({
    fontSize: theme.typography.pxToRem(18),
    opacity: 0.8,
}));

const StyledPaper = styled(Paper)(({ theme }) => ({
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: theme.palette.grey[200],
    },
}));

const numberWithSpaces = (x) => {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
};


const StatsBox = ({ label, value, color, unit, icon: Icon, bgColor, opacity }) => {
  const theme = useTheme();

  // Extract RGB values from the hex color
  const rgbColor = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(color);
  const r = parseInt(rgbColor[1], 16);
  const g = parseInt(rgbColor[2], 16);
  const b = parseInt(rgbColor[3], 16);

  const PaperComponent = label === "Mottagna" ? StyledPaper : Paper;

  const scrollToLatestOrders = () => {
    setTimeout(() => {
      const element = document.getElementById("latestOrders");
      if (element) {
        element.scrollIntoView({behavior: "smooth"});
      } else {
        console.error("Element with id 'latestOrders' not found");
      }
    }, 0);
  };

  return (
    <Grid item xs={12} sm={6} md={3}>
      <ButtonBase component={PaperComponent}
        onClick={label === "Mottagna" ? scrollToLatestOrders : undefined}
        sx={{
          color: theme.palette.textColor.main,
          border: "0px solid rgba(0, 0, 0, 0.1)",
          borderRadius: "8px",
          p: 2,
          minHeight: "150px",
          height: "100%",
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-start",
          boxShadow: "0px 5px 15px rgba(0, 0, 0, 0.1)",
          backgroundColor: bgColor,
          opacity: opacity,
        }}
      >
        <IconWrapper>
          <Icon
            fontSize="large"
            style={{ fill: `rgb(${r}, ${g}, ${b})` }}
          />
        </IconWrapper>
        <ValueText>
          {numberWithSpaces(value)}
          {unit}
        </ValueText>
        <LabelText>{String(label)}</LabelText>
      </ButtonBase>
    </Grid>
  );
};

export default StatsBox;