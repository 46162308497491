import React, { useContext, useState } from 'react';
import { Link } from 'react-router-dom';
import AuthContext from 'context/AuthContext';

export const QuickView = () => {
  let {authTokens} = useContext(AuthContext)
  let {user} = useContext(AuthContext)

  const [loading, setLoading] = useState(false);


  const getAllOrders = () => {
    setLoading(true);
    const url = 'http://127.0.0.1:8000/shopify-api/orders/';

    fetch(url, {
      headers: {
        'Content-Type':'application/json',
      },
      credentials: 'include',  // Include this line
    })
      .then((response) => response.json())
      .then((orders) => {
        console.log(orders);
        setLoading(false);
      })
      .catch((error) => {
        console.error('An error occurred:', error);
        setLoading(false);
      });
  };

  const getOrderById = (orderId) => {
    setLoading(true);
    const url = `http://127.0.0.1:8000/shopify-api/orders/${orderId}/`;
  
    fetch(url, {
      headers: {
        'Content-Type':'application/json',
        'Authorization':'Bearer ' + String(authTokens.access)
      },
    })
      .then((response) => response.json())
      .then((order) => {
        console.log(order);
        setLoading(false);
      })
      .catch((error) => {
        console.error('An error occurred:', error);
        setLoading(false);
      });
  };

  const getUpcomingOrders = () => {
    setLoading(true);
    const url = 'http://127.0.0.1:8000/shopify-api/orders/upcoming/';

    fetch(url, {
      headers: {
        'Content-Type':'application/json',
      },
      credentials: 'include',  // Include this line
    })
      .then((response) => response.json())
      .then((orders) => {
        console.log(orders);
        setLoading(false);
      })
      .catch((error) => {
        console.error('An error occurred:', error);
        setLoading(false);
      });
  };


  const getAllProducts = () => {
    setLoading(true);
    const url = 'http://127.0.0.1:8000/shopify-api/products/';

    fetch(url, {
      headers: {
        'Content-Type':'application/json',
        'Authorization':'Bearer ' + String(authTokens.access)
      },
    })
      .then((response) => response.json())
      .then((products) => {
        console.log(products);
        setLoading(false);
      })
      .catch((error) => {
        console.error('An error occurred:', error);
        setLoading(false);
      });
  };

  const getProductById = (productId) => {
    setLoading(true);
    const url = `http://127.0.0.1:8000/shopify-api/products/${productId}/`;
  
    fetch(url, {
      headers: {
        'Content-Type':'application/json',
      },
      credentials: 'include',  // Include this line
    })
      .then((response) => response.json())
      .then((product) => {
        console.log(product);
        setLoading(false);
      })
      .catch((error) => {
        console.error('An error occurred:', error);
        setLoading(false);
      });
  };


  const getProductAndMetafieldById = (productId) => {
    setLoading(true);
    const url = `http://127.0.0.1:8000/shopify-api/products/metafield/${productId}/`;
  
    fetch(url, {
      headers: {
        'Content-Type':'application/json',
        'Authorization':'Bearer ' + String(authTokens.access)
      },
    })
      .then((response) => response.json())
      .then((product) => {
        console.log(product);
        setLoading(false);
      })
      .catch((error) => {
        console.error('An error occurred:', error);
        setLoading(false);
      });
  };


  const getAllPostsForStore = () => {
    setLoading(true);
    const url = 'http://127.0.0.1:8000/store/store-posts/';

    fetch(url, {
      headers: {
        'Content-Type':'application/json',
      },
      credentials: 'include',  // Include this line

    })
      .then((response) => response.json())
      .then((products) => {
        console.log(products);
        setLoading(false);
      })
      .catch((error) => {
        console.error('An error occurred:', error);
        setLoading(false);
      });
  };

  const getPostById = (postId) => {
    setLoading(true);
    const url = `http://127.0.0.1:8000/store/posts/${postId}/`;
  
    fetch(url, {
      headers: {
        'Content-Type':'application/json',
      },
      credentials: 'include',  // Include this line

    })
      .then((response) => response.json())
      .then((post) => {
        console.log(post);
        setLoading(false);
      })
      .catch((error) => {
        console.error('An error occurred:', error);
        setLoading(false);
      });
  };
  

  const getUserStore = () => {
    setLoading(true);
    const url = `http://127.0.0.1:8000/store/user-store/`;
  
    fetch(url, {
      headers: {
        'Content-Type':'application/json',
      },
      credentials: 'include',  // Include this line

    })
      .then((response) => response.json())
      .then((post) => {
        console.log(post);
        setLoading(false);
      })
      .catch((error) => {
        console.error('An error occurred:', error);
        setLoading(false);
      });
  };
  

  const getCollections= () => {
    setLoading(true);
    const url = `http://127.0.0.1:8000/shopify-api/collections/`;
  
    fetch(url, {
      headers: {
        'Content-Type':'application/json',
        'Authorization':'Bearer ' + String(authTokens.access)
      },
    })
      .then((response) => response.json())
      .then((post) => {
        console.log(post);
        setLoading(false);
      })
      .catch((error) => {
        console.error('An error occurred:', error);
        setLoading(false);
      });
  };





  const onClickProduct = () => getProductById(8446658707764); // put some product id here
  const onClick = () => getOrderById(5422260420916);
  const onClickPost = () => getPostById(1); // replace 1 with a valid post ID for testing

  const onClickProductMetafield = () => getProductAndMetafieldById(8446658707764); // put some product id here



  return (
    <div>
      <Link to="/">Home</Link>

      {authTokens && <p>Hello {user.username}</p>}
      
      {authTokens && (
        <button onClick={getAllOrders} disabled={loading}>
          {loading ? 'Loading...' : 'Get All Orders'}
        </button>
      )}

      {authTokens && (
        <button onClick={onClick} disabled={loading}>
          {loading ? 'Loading...' : 'Get Order 5422260420916'}
        </button>
      )}

      {authTokens && (
        <button onClick={getUpcomingOrders} disabled={loading}>
          {loading ? 'Loading...' : 'Get Upcoming Orders'}
        </button>
      )}

      {authTokens && (
        <button onClick={getAllProducts} disabled={loading}>
          {loading ? 'Loading...' : 'Get All Products'}
        </button>
      )}

      {authTokens && (
        <button onClick={onClickProduct} disabled={loading}>
          {loading ? 'Loading...' : 'Get Product by ID'}
        </button>
      )}
      {authTokens && (
        <button onClick={getAllPostsForStore} disabled={loading}>
          {loading ? 'Loading...' : 'Get all posts for store'}
        </button>
      )}

    {authTokens && (
        <button onClick={onClickPost} disabled={loading}>
            {loading ? 'Loading...' : 'Get Post by ID'}
        </button>
    )}

    {authTokens && (
        <button onClick={onClickProductMetafield} disabled={loading}>
            {loading ? 'Loading...' : 'Get Product and metafiled by ID'}
        </button>
    )}

    {authTokens && (
        <button onClick={getUserStore} disabled={loading}>
            {loading ? 'Loading...' : 'Get user store'}
        </button>
    )}


    {authTokens && (
        <button onClick={getCollections} disabled={loading}>
            {loading ? 'Loading...' : 'Get collections'}
        </button>
    )}


    </div>
  );
};

export default QuickView;