import React, { useState } from "react";
import {
  Box,
  Grid,
  TextField,
  Typography,
  Button
} from "@mui/material";

import { Autocomplete } from "@mui/material";
import useCreatePost from "Hooks/Posts/useCreatePost";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import CustomSnackbar from "../../../components/SnackBar";
import {
  Root,
  Container,
  InputField,
} from "./Style/Styles";

import "../../Products_Folder/ProductDetails/Style/customQuill.css";



const NewPostForm = () => {
  const [title, setTitle] = useState("");
  const [content, setContent] = useState("");
  const [tags, setTags] = useState("");
  const [category, setCategory] = useState("");
  const [coverImageUrl, setCoverImageUrl] = useState("");
  const { createPost, error } = useCreatePost();
  const [openSnackbar, setOpenSnackbar] = useState(false);

  const categories = ["Catering", "Systemet"]; // Customize this array with your own categories
  const severity = !error ? "success" : "error";

  const handleSnackbarClose = () => {
    setOpenSnackbar(false);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
  
    // Convert tags array to a comma-separated string
    const tagsString = tags;
  
    // Form validation and data preparation
    const postData = {
      title,
      content,
      tags: tagsString,   // Use the converted tagsString here
      category,           // Ensure category is a string if the backend expects a single category
      coverImageUrl,
    };
  
    try {
      await createPost(postData);
      setOpenSnackbar(true);
      // Reset form data
      setTitle('');
      setContent('');
      setTags('');
      setCategory('');
      setCoverImageUrl('');
    } catch (error) {
      console.log(error)  
    }
  };
  


  return (
    <Root>
      <Container>
      <Typography 
        variant="h3"
        mt={4}
        align="left"
        >
          Skapa ett inlägg
        </Typography>
        <Box mt={4}> {/* Increased top margin */}
          <form onSubmit={handleSubmit}>
            <Grid container direction="column" spacing={4}> {/* Increased spacing */}
              <Grid item>
                <TextField
                  required
                  label="Titel"
                  variant="outlined"
                  placeholder="Titel"
                  fullWidth
                  value={title}
                  onChange={(e) => setTitle(e.target.value)}
                  autoComplete="off" // Disables autocomplete/suggestions
                />
              </Grid>
              <Grid item>
                <ReactQuill
                  className="my-quill-editor"
                  value={content}
                  onChange={setContent}
                  style={{ height: '250px', marginBottom: '16px' }}
                  placeholder="Skriv ditt meddelande här"
                  modules={{
                    toolbar: [
                      ['bold', 'italic', 'underline'],
                    ],
                  }}
                />
              </Grid>
              <Grid item>
                <Autocomplete
                  component={InputField}
                  options={categories}
                  freeSolo
                  onChange={(event, value) => setTags(value || '')} // value will be a single string, or if not selected, we default to an empty string
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="standard"
                      label="Kategori"
                    />
                  )}
                />
              </Grid>
              <Grid item>
                <Button
                  variant="contained"
                  type="submit"
                  sx={{
                    textTransform: "none",
                    fontWeight: "bold",
                    backgroundColor: '#C00020',
                    "&:hover": {
                      backgroundColor: '#C00020',
                    }
                  }}
                >
                  Publicera
                </Button>
              </Grid>
            </Grid>
          </form>
        </Box>
      </Container>
      <CustomSnackbar
        open={openSnackbar}
        handleClose={handleSnackbarClose}
        message= {!error ? "Skapandet av inlägget lyckades!" : "Skapandet av inlägget misslyckades"}
        severity={severity}
      />
    </Root>
  );
};

export default NewPostForm;