import { useQuery } from "react-query";
import { useContext } from 'react';
import AuthContext from 'context/AuthContext';  // Ensure the path to AuthContext is correct.

const fetchProductWithMetafields = async (productId, updateToken, logoutUser, retryCount = 0) => {
  const baseUrl = process.env.REACT_APP_BASE_URL
      ? process.env.REACT_APP_BASE_URL
      : "http://localhost:8000/";

  const response = await fetch(`${baseUrl}/shopify-api/products/metafield/${productId}/`, {
    headers: {
      'Content-Type':'application/json',
    },
    credentials: 'include',
  });

  if ((response.status === 401 || response.status === 403) && retryCount < 1) {
    await updateToken();
    return fetchProductWithMetafields(productId, updateToken, logoutUser, retryCount + 1);  // Retry after refreshing token
  } else if ((response.status === 401 || response.status === 403) && retryCount >= 1) {
    logoutUser();
    throw new Error('Authentication failed. Logging out.');
  }

  const { product: productData, filteredMetafields, storeName } = await response.json();

  productData.metafields = filteredMetafields;

  return { product: productData, storeName };
};

const useProductDetails = (productId) => {
  const { updateToken, logoutUser } = useContext(AuthContext);
  
  const { data, isLoading, isError } = useQuery(
    ["product", productId],
    () => fetchProductWithMetafields(productId, updateToken, logoutUser, 0),
    {
      enabled: !!productId
    },
  );

  return {
    product: data?.product,
    storeName: data?.storeName,
    isLoading,
    isError
  };
};

export default useProductDetails;
