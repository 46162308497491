import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import RadioButtonCheckedIcon from "@mui/icons-material/RadioButtonChecked";


export function getStatusFromTags(tags) {
    if (!tags){
      return "Mottagen"; 
    }

    if (tags.includes("status:")) {
      const status = tags.split("status:")[1].split(",")[0].trim();
      return status === "mottagen" ? "Mottagen" : status;
    }
    return "Mottagen"; // return "Mottagen" if no status tag found
  }

export function getBookedFromTags(tags) {
  if (!tags){
    return false; 
  }

  if (tags.includes("booked:")) {
    const booked = tags.split("booked:")[1].split(",")[0].trim();
    return booked.toLowerCase() === "true" ? true : false;
  }
  return false; 
}


export function getUpdatedBy(order, tags){
    const tagsArray = tags.split(", ");
    const bookedIndex = tagsArray.findIndex((tag) =>
      tag.startsWith("updated_by:")
    );
    if (bookedIndex > -1) {
      return "Senast updaterad av " + tagsArray[bookedIndex].split(":")[1];
    }
  return "";
};



export function updateTag(tagsArray, tag, index, startsWith){
  if (index > -1) {
    tagsArray[index] = `${startsWith}: ${tag}`;
  } else {
    tagsArray.push(`${startsWith}: ${tag}`);
  }
}


export function iconForFinancialStatus(status) {
  switch (status) {
    case "paid":
      return <CheckCircleOutlineIcon style={{ color: "#52963C" }} />;
    case "pending":
      return <ErrorOutlineIcon style={{ color: "#B27900" }} />;
    default:
      return <RadioButtonCheckedIcon />;
  }
}