import { useQuery } from 'react-query';
import { useContext } from 'react';
import AuthContext from 'context/AuthContext';  // Ensure the path to AuthContext is correct.

const fetchPosts = async (updateToken, logoutUser, retryCount = 0) => {
  const baseUrl = process.env.REACT_APP_BASE_URL ? `${process.env.REACT_APP_BASE_URL}` : 'http://localhost:8000/';
  const response = await fetch(`${baseUrl}/store/store-posts/`, {
    headers: {
      'Content-Type': 'application/json',
    },
    credentials: 'include',
  });

  if ((response.status === 401 || response.status === 403) && retryCount < 1) {
    await updateToken();
    return fetchPosts(updateToken, logoutUser, retryCount + 1);  // Retry after refreshing token
  } else if ((response.status === 401 || response.status === 403) && retryCount >= 1) {
    logoutUser();
    throw new Error('Authentication failed. Logging out.');
  }

  if (!response.ok) {
    const responseText = await response.text();
    console.error(`HTTP error! status: ${response.status}, body: ${responseText}`);
    throw new Error(`HTTP error! status: ${response.status}`);
  }

  const data = await response.json();
  return data;
};

const useStorePosts = () => {  
  const { updateToken, logoutUser } = useContext(AuthContext);
  
  const { data: posts, isLoading, isError } = useQuery(
    ['posts'],
    () => fetchPosts(updateToken, logoutUser, 0)
  );
  
  
  return { posts, isLoading, isError };
};

export default useStorePosts;
