import { getStatusFromTags } from "./getStatusFromTags";

export function getStatusColor(tags){

    const status = getStatusFromTags(tags);
    
    switch (status) {
      case "Mottagen":
        return "#D3D7D9";
      case "Färdig":
        return "#9AEABC";
      case "Accepterad":
        return "#A8D3F0";
      case "Levererad":
        return "#F3BF91";
      default:
        return "#C0F7CF";
    }
  };

  export function colorForFinancialStatus(status){
    switch (status) {
      case "paid":
        return "#D4EDCC";
      case "pending":
        return "#F4EAB8";
      default:
        return "#E6E6E6";
    }
  };