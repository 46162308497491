import React from "react";
import {
  Box,
  Typography,
  Card,
  CardContent,
  CardActions,
  Button,
  Divider,
  useTheme,
} from "@mui/material";
import useStorePosts from "../../../Hooks/Posts/useStorePosts.js"; // Import the useStorePosts hook
import { Link } from "react-router-dom";

import InformationComponent from "../../../components/InfoComponent";
import ErrorComponent from "components/ErrorComponent.jsx";
import LoadingComponent from "components/LoadingComponent.jsx";
import Header from "components/Header.jsx";

import getTagColor from "utilities/getTagColor";

function stripHtml(html) {
  let doc = new DOMParser().parseFromString(html, "text/html");
  return doc.body.textContent || "";
}

function Post({ post }) {
  // GET THE COLOR OF THE TAG, getTagColor IN UTILITIES
  const tagColor = getTagColor(post.tags ? post.tags : null);

  const previewTextLength = 150; // set your desired length here

  const strippedContent = stripHtml(post.content);
  const displayText =
    strippedContent.length > previewTextLength
      ? strippedContent.substring(0, previewTextLength) + "..."
      : strippedContent;

  let postDate = "";
  if (post.created_at) {
    postDate = new Date(post.created_at).toLocaleDateString();
  }

  return (
    <Card
      key={post?.id}
      sx={{
        flex: "1 0 200px",
        maxWidth: "25%",
        m: 2,
        p: 2,
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        backgroundColor: "white",
        boxShadow: 3,
        transition: "0.3s",
        "&:hover": {
          transform: "scale(1.03)",
        },
        position: "relative",
      }}
    >
      <Box
        sx={{
          position: "absolute",
          top: 0,
          bottom: 0,
          left: 0,
          width: "10px",
          backgroundColor: tagColor,
        }}
      />

      <CardContent>
        <Typography variant="h6" component="div" gutterBottom>
          {post.title}
        </Typography>
        <Typography variant="body2" color="text.secondary" gutterBottom>
          {displayText}
        </Typography>
        <Typography variant="overline" color="text.secondary">
          {postDate}
        </Typography>
      </CardContent>
      <CardActions sx={{ justifyContent: "space-between" }}>
        <Typography variant="subtitle2" color="text.secondary" sx={{ p: 1 }}>
          {Array.isArray(post.tags) ? post.tags.join(", ") : post.tags}
        </Typography>
        <Button
          size="small"
          component={Link}
          to={`/posts/${post?.id}`}
          sx={{
            backgroundColor: "#C00020",
            color: "white",
            "&:hover": {
              backgroundColor: "#A3001B",
            },
            textTransform: "none",
            fontWeight: "bold",
          }}
        >
          Läs mer
        </Button>
      </CardActions>
    </Card>
  );
}

function PostList({ posts }) {
  if (!posts || posts.length === 0) {
    return (
      <InformationComponent
        message="Det finns inga posts, klicka på knappen för att skapa en post"
        buttonDestination="/add-post"
      />
    );
  }

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "row",
        flexWrap: "wrap",
        justifyContent: "center",
        px: 3,
      }}
    >
      {posts
        .slice()
        .reverse()
        .map((post) => (
          <Post key={post.id} post={post} />
        ))}
    </Box>
  );
}

function AllPostsPage() {
  const theme = useTheme();
  //const storeId = "your-store-id";  // Replace with your actual store ID

  const { posts, isLoading, isError } = useStorePosts();

  if (isLoading) {
    return <LoadingComponent />;
  }

  if (isError) return <ErrorComponent error={isError} />;

  return (
    <Box sx={{ width: "100%", p: 4 }}>
      <Box style={{ marginTop: "20px", marginLeft: "20px" }}>
        <Header
          title={"Meddelanden"}
          subtitle="Klicka in på ett meddelande för att läsa mer"
          style={{ color: theme.palette.textColor.main }}
        />
      </Box>
      <Divider
        sx={{ marginTop: theme.spacing(2), marginBottom: theme.spacing(2) }}
      />
      <PostList posts={posts} />
    </Box>
  );
}

export default AllPostsPage;
