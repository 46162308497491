import React, { useState, useEffect } from "react";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import moment from "moment";
import "moment/locale/sv";
import { Link as RouterLink } from "react-router-dom";

import {
  TextField,
  Box,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Typography,
  Divider,
  Container,
  Paper,
  Button,
} from "@mui/material";

import {
  StyledTableHeaderCell,
  StyledTableRow
} from './Style/Styles';

import "react-datepicker/dist/react-datepicker.css";
import { useTheme } from "@mui/material";
import Header from "components/Header";

import ErrorComponent from "components/ErrorComponent";
import LoadingComponent from "components/LoadingComponent";

import GetAllOrders from "Hooks/Catering/GetAllOrders";

import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";

import { getDeliveryDate } from "utilities/getNoteAttributes";

import { parseDate } from "utilities/dateUtility";

const OrderSummaryView = () => {
  moment.locale("sv");
  // sets the start of the week to monday
  moment.updateLocale("sv", {
    week: {
      dow: 1,
    },
  });
  const theme = useTheme();
  const initialStartDate = moment().subtract(7, "days");
  const [startDate, setStartDate] = useState(initialStartDate);
  const [endDate, setEndDate] = useState(moment());
  const [filteredOrders, setFilteredOrders] = useState([]);

  const [tempStartDate, setTempStartDate] = useState(initialStartDate);
  const [tempEndDate, setTempEndDate] = useState(moment());

  const {
    orders: orderData,
    isLoading: isOrderLoading,
    error,
  } = GetAllOrders();

  // Filter the orders based on the selected date range
  useEffect(() => {
    if (orderData) {
      setFilteredOrders(
        orderData.filter((order) => {
          const orderDate = moment(parseDate(getDeliveryDate(order)));
          return (
            orderDate.isSameOrAfter(startDate) &&
            orderDate.isSameOrBefore(endDate)
          );
        })
      );
    }
  }, [startDate, endDate, orderData]);

  const handleSetTempStartDate = (date) => {
    setTempStartDate(date);
  };

  const handleSetTempEndDate = (date) => {
    setTempEndDate(date);
  };

  const handleSearch = () => {
    setStartDate(tempStartDate);
    setEndDate(tempEndDate);
  };

  if (isOrderLoading) {
    return <LoadingComponent />;
  }

  if (error) {
    return <ErrorComponent error={error} />;
  }

  const itemQuantities = {};
  filteredOrders?.forEach((order) => {
    order?.line_items?.forEach((item) => {
      if (itemQuantities[item.title]) {
        itemQuantities[item.title].quantity += item.quantity;
      } else {
        itemQuantities[item.title] = {
          quantity: item.quantity,
          id: item.product_id,
        };
      }
    });
  });

  const customlineItems = Object.entries(itemQuantities)
    .map(([title, { quantity, id }]) => {
      const productId = id;

      if (!productId) {
        console.error(`ingen produkt hittades för : ${title}`);
        return null;
      }

      return (
        <StyledTableRow key={title}>
          <TableCell component="th" scope="row">
            {title}
          </TableCell>
          <TableCell>{quantity}</TableCell>
          <TableCell>
            <RouterLink to={`/product/${productId}`}>
              <Button
                variant="outlined"
                sx={{
                  textTransform: "none",
                }}
              >
                Se mer
              </Button>
            </RouterLink>
          </TableCell>
        </StyledTableRow>
      );
    })
    .filter(Boolean);

  return (
    <Box sx={{ p: 4 }}>
      <Box style={{ marginTop: "20px", marginLeft: "20px" }}>
        <Header
          title={"Produkter"}
          subtitle="Se antalet produkter som förväntas levereras"
          style={{ color: theme.palette.textColor.main }}
        />
      </Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          marginBottom: theme.spacing(2),
        }}
      >
          <LocalizationProvider dateAdapter={AdapterMoment} locale={moment}>
            <Box flexDirection="column" display="flex" alignItems="center">
              <Box display="flex" flexDirection="row" alignItems="center">
                <Box>
                  <Typography variant="subtitle1" mr={1}>
                    Från:
                  </Typography>
                  <DatePicker
                    value={startDate}
                    onChange={(date) => handleSetTempStartDate(date)}
                    TextField={(params) => <TextField {...params} />}
                  />
                </Box>
                <Box>
                  <Typography variant="subtitle1" mr={1}>
                    Till:
                  </Typography>
                  <DatePicker
                    value={endDate}
                    onChange={(date) => handleSetTempEndDate(date)}
                    TextField={(params) => <TextField {...params} />}
                  />
                </Box>
              </Box>
              <Box display="flex" justifyContent="center" mt={2}>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleSearch}
                  style={{
                    fontWeight: "bold",
                    textTransform: "none",
                  }}
                >
                  Sök
                </Button>
              </Box>
            </Box>
          </LocalizationProvider>
      </Box>
      <Divider
        sx={{ marginTop: theme.spacing(2), marginBottom: theme.spacing(2) }}
      />
      <Container maxWidth="md">
        <Paper elevation={1}>
          <Table>
            <TableHead>
              <TableRow>
                <StyledTableHeaderCell>Produktnamn</StyledTableHeaderCell>
                <StyledTableHeaderCell>Kvantitet</StyledTableHeaderCell>
                <StyledTableHeaderCell>Produkt</StyledTableHeaderCell>
              </TableRow>
            </TableHead>
            <TableBody>{customlineItems}</TableBody>
          </Table>
        </Paper>
      </Container>
    </Box>
  );
};

export default OrderSummaryView;
