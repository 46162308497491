import { useState, useContext } from 'react';
import axios from 'axios';
import AuthContext from 'context/AuthContext';

const useUpdateProfile = () => {
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(null);
    const [passwordError, setPasswordError] = useState(null);
    const { updateToken, logoutUser } = useContext(AuthContext);

    const baseUrl = process.env.REACT_APP_BASE_URL
        ? process.env.REACT_APP_BASE_URL
        : 'http://localhost:8000/';

    const config = {
        headers: {
            'Content-Type': 'application/json',
        },
        withCredentials: true,
    };

    const updateProfile = async (profileData, retryCount = 0) => {
        setIsLoading(true);
        try {
            await axios.put(`${baseUrl}/user/update/`, profileData, config);
            setError(null);
        } catch (err) {
            if ((err.response?.status === 401 || err.response?.status === 403) && retryCount < 1) {
                await updateToken();
                return updateProfile(profileData, retryCount + 1);
            } else if ((err.response?.status === 401 || err.response?.status === 403) && retryCount >= 1) {
                logoutUser();
                setError('Authentication failed. Logging out.');
            } else {
                setError('An error occurred while updating the profile.');
            }
        } finally {
            setIsLoading(false);
        }
    };

    const changePassword = async (oldPassword, newPassword) => {
        setIsLoading(true);
        try {
            await axios.put(`${baseUrl}/user/change-password/`, { old_password: oldPassword, new_password: newPassword }, config);
            setPasswordError(null);
        } catch (err) {
            setPasswordError(err.response?.data || 'An error occurred while changing the password.');
        } finally {
            setIsLoading(false);
        }
    };

    return { 
        updateProfile, 
        changePassword, 
        isLoading, 
        error, 
        passwordError 
    };
};

export default useUpdateProfile;
