import { useContext } from 'react'
import AuthContext from '../../context/AuthContext'

import { Navigate } from 'react-router-dom';



const PrivateRoute = ({ children }) => {
    let { isAuthenticated } = useContext(AuthContext);
    return !isAuthenticated ? <Navigate to="/login" /> : children;
};
    

export default PrivateRoute;
 