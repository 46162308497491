import * as React from 'react';
import {
    Typography,
    Box,
    Grid,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Popover,
  } from "@mui/material";
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import LaunchIcon from '@mui/icons-material/Launch';
import { Link as RouterLink } from "react-router-dom";

export class OrderDetailsTable extends React.Component {
    render() {

        const { theme, order, handlePopoverOpen, handlePopoverClose, isPopoverOpen, popoverAnchorEl } = this.props;

        return (
            <Grid item xs={10}>
              <Box mt={theme.spacing(5)} mb={theme.spacing(5)}>
              <TableContainer component={Paper}>
              <Table>
                <TableHead>
                  <TableRow style={{ backgroundColor: theme.palette.green.main }}>
                    <TableCell style={{ fontWeight: 'bold', color: "white", fontSize: "0.9rem" }}>Produkt</TableCell>
                    <TableCell align="right" style={{ fontWeight: 'bold', color: "white", fontSize: "0.9rem" }}>Antal</TableCell>
                    <TableCell align="right" style={{ fontWeight: 'bold', color: "white", fontSize: "0.9rem" }}>Pris</TableCell>
                    <TableCell align="right" style={{ fontWeight: 'bold', color: "white", fontSize: "0.9rem" }}>Totalt Pris</TableCell>
                    <TableCell align="right" style={{ fontWeight: 'bold', color: "white", fontSize: "0.9rem" }}>Leverantör</TableCell>
                    <TableCell align="right" style={{ fontWeight: 'bold', color: "white", fontSize: "0.9rem" }}>Viktig info</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {order.lineItems.map((item) => (
                    <TableRow key={item.product_id} sx={{ '&:nth-of-type(odd)': { backgroundColor: '#f7f7f7' } }}>
                      <TableCell 
                        component="th" 
                        scope="row" 
                        style={{ 
                          fontSize: '0.8rem', 
                          display: 'flex', 
                          alignItems: 'center' 
                        }}
                      >
                      <RouterLink 
                        to={`/product/${item.product_id}`}
                        style={{
                          color: "#393939",
                          alignItems: "center",
                          display: "flex",
                          textDecoration: "none"
                        }}
                      >
                        {item.name}
                        <LaunchIcon sx={{ ml: "10px", color: "#6F6F6F", fontSize: "1.3em" }}/>
                      </RouterLink>
                      </TableCell>
                      <TableCell style={{ fontSize: '0.8rem' }} align="right">{item.quantity}</TableCell>
                      <TableCell style={{ fontSize: '0.8rem' }} align="right">{(item.price - item?.total_discount / item.quantity) + "kr"}</TableCell>
                      <TableCell style={{ fontSize: '0.8rem' }} align="right">{Math.round((item.price * item.quantity * 100) / 100) - item?.total_discount + "kr"}</TableCell>
                      <TableCell style={{ fontSize: '0.8rem' }} align="right">{item.vendor}</TableCell>
                      <TableCell style={{ fontSize: '0.8rem' }} align="right">
                        {item.properties && item.properties.find(attr => attr.name === 'Note' && attr.value !== '') && (
                          <>
                            <InfoOutlinedIcon
                              style={{ marginRight: '0.5rem', cursor: 'pointer' }}
                              onClick={handlePopoverOpen}
                              aria-owns={isPopoverOpen ? 'note-popover' : undefined}
                              aria-haspopup="true"
                            />
                            <Popover
                              id="note-popover"
                              open={isPopoverOpen}
                              anchorEl={popoverAnchorEl}
                              onClose={handlePopoverClose}
                              anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'center',
                              }}
                              transformOrigin={{
                                vertical: 'top',
                                horizontal: 'center',
                              }}
                            >
                              <Typography style={{ padding: '1rem' }}>
                                {item.properties.find(attr => attr.name === 'Note' && attr.value !== '').value}
                              </Typography>
                            </Popover>
                          </>
                        )}
                      </TableCell>

                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
              </Box>
            </Grid>
        );
    };
}
