import React from 'react';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import { styled } from '@mui/system';

// Defining CustomTooltip component
const CustomTooltip = styled((props) => <Tooltip {...props} />)(({ theme }) => ({
  '& .MuiTooltip-tooltip': {
    backgroundColor: '#333',
    color: '#fff',
    borderRadius: '4px',
    padding: '8px 12px',
    fontSize: "13px"
  },
}));

// Defining TooltipIcon component
const TooltipIcon = ({ tooltipText, onClick, children }) => (
  <CustomTooltip title={tooltipText} arrow>
    <IconButton onClick={onClick}>
      {children}
    </IconButton>
  </CustomTooltip>
);

export default TooltipIcon;