import {
  Typography,
  Box,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Popover,
} from "@mui/material";
import SwipeOutlinedIcon from "@mui/icons-material/SwipeOutlined";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";

function OrderDetailsTable({
  theme,
  order,
  handlePopoverClose,
  isPopoverOpen,
  popoverAnchorEl,
  handlePopoverOpen,
}) {
  return (
    <Grid item xs={12}>
      <Box mt={theme.spacing(4)} mb={theme.spacing(4)}>
        <Typography>
          {" "}
          <SwipeOutlinedIcon style={{ color: theme.palette.green.main }} />{" "}
        </Typography>
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow style={{ backgroundColor: theme.palette.green.main }}>
                <TableCell
                  style={{
                    fontWeight: "bold",
                    color: "white",
                    fontSize: "12px",
                  }}
                >
                  Namn
                </TableCell>
                <TableCell
                  align="right"
                  style={{
                    fontWeight: "bold",
                    color: "white",
                    fontSize: "12px",
                  }}
                >
                  Antal
                </TableCell>
                <TableCell
                  align="right"
                  style={{
                    fontWeight: "bold",
                    color: "white",
                    fontSize: "12px",
                  }}
                >
                  Pris
                </TableCell>
                <TableCell
                  align="right"
                  style={{
                    fontWeight: "bold",
                    color: "white",
                    fontSize: "12px",
                  }}
                >
                  Leverantör
                </TableCell>
                <TableCell
                  align="right"
                  style={{
                    fontWeight: "bold",
                    color: "white",
                    fontSize: "12px",
                  }}
                >
                  Viktig Info
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {order.lineItems.map((item, index) => (
                <TableRow
                  key={index}
                  sx={{
                    "&:nth-of-type(odd)": {
                      backgroundColor: "white",
                    },
                  }}
                >
                  <TableCell
                    component="th"
                    scope="row"
                    style={{
                      fontSize: "0.8rem",
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    {item.name}
                  </TableCell>
                  <TableCell style={{ fontSize: "13px" }} align="right">
                    {item.quantity}
                  </TableCell>
                  <TableCell style={{ fontSize: "13px" }} align="right">
                    {Math.round((item.price * item.quantity * 100) / 100) - item?.total_discount + "kr"}
                  </TableCell>
                  <TableCell style={{ fontSize: "13px" }} align="right">
                    {item.vendor}
                  </TableCell>
                  <TableCell style={{ fontSize: "13px" }} align="right">
                    {item.properties &&
                      item.properties.find(
                        (attr) => attr.name === "Note" && attr.value !== ""
                      ) && (
                        <>
                          <InfoOutlinedIcon
                            style={{
                              marginRight: "0.5rem",
                              cursor: "pointer",
                            }}
                            onClick={handlePopoverOpen}
                            aria-owns={
                              isPopoverOpen ? "note-popover" : undefined
                            }
                            aria-haspopup="true"
                          />
                          <Popover
                            id="note-popover"
                            open={isPopoverOpen}
                            anchorEl={popoverAnchorEl}
                            onClose={handlePopoverClose}
                            anchorOrigin={{
                              vertical: "bottom",
                              horizontal: "center",
                            }}
                            transformOrigin={{
                              vertical: "top",
                              horizontal: "center",
                            }}
                          >
                            <Typography style={{ fontSize: "12x" }}>
                              {
                                item.properties.find(
                                  (attr) =>
                                    attr.name === "Note" && attr.value !== ""
                                ).value
                              }
                            </Typography>
                          </Popover>
                        </>
                      )}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    </Grid>
  );
}

export default OrderDetailsTable;
