import React, { useState } from "react";
import {
  Box,
  AppBar,
  Toolbar,
  IconButton,
  Typography,
  styled,
  ListItemIcon,
  ListItemText,
  Drawer,
  List,
  ListItem,
  ButtonBase,
  Divider
} from "@mui/material";
import { Outlet, useNavigate } from "react-router-dom";
import AuthContext from "context/AuthContext";
import MenuIcon from "@mui/icons-material/Menu";
import SearchIcon from "@mui/icons-material/Search";
import LogoutIcon from "@mui/icons-material/Logout";
import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";
import FormatListBulletedRoundedIcon from '@mui/icons-material/FormatListBulletedRounded';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import AddBoxIcon from '@mui/icons-material/AddBox';
import { useContext } from "react";

const getAppBarHeight = () => {
  const screenHeight = window.innerHeight || 0;
  if (screenHeight === 812 || screenHeight === 896) { // iPhone X, XS, XR, XS Max, 11, 11 Pro
    return 110;
  } else if (screenHeight > 896) { // iPhone 13, 14 or later
    return 80;
  }
  return 100; // default
};

const getToolBarPadding = () => {
  const screenHeight = window.innerHeight || 0;
  if (screenHeight === 812 || screenHeight === 896) { // iPhone X, XS, XR, XS Max, 11, 11 Pro
    return "50px";
  } else if (screenHeight > 896) { // iPhone 13, 14 or later
    return "40px";
  }
  return "45px"; // default
}


const StyledAppBar = styled(AppBar)(({ theme }) => ({
  backgroundColor: "#C00020",
  height: getAppBarHeight(),
}));

const MobileLayout = ({ children }) => {
  const navigate = useNavigate();
  let { logoutUser } = useContext(AuthContext);
  // Added this state for toggling the drawer
  const [drawerOpen, setDrawerOpen] = useState(false);

  const handleHomeClick = () => {
    navigate("/mobile/home");
  };

  const handleOrdersClick = () => {
    navigate("/mobile/landing");
  };

  const handleUpcommingOrdersClick = () => {
    navigate("/mobile/UpcommingOrders");
  };

  const handleSearchClick = () => {
    navigate("/mobile/search");
  };

  const handleNewProductClick = () => {
    navigate("/mobile/new-product");
  };

  const handleLogoutClick = () => {
    // logout
    logoutUser();
  };

  const toggleDrawer = (open) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }

    setDrawerOpen(open);
  };

  const list = () => (
    <Box
        sx={{ 
          width: 250,
        }}
        role="presentation"
        onClick={toggleDrawer(false)}
        onKeyDown={toggleDrawer(false)}
    >
      <Box sx={{ px: 2, height: '120px', display: 'flex', alignItems: 'center', pt: "50px"}}>
        <>
          <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 319 329" fill="none" style={{ marginLeft: '10px' }}>
            <rect y="10" width="319" height="319" rx="60" fill="#41666A"/>
            <path d="M156.504 260.072C141.827 260.072 127.661 258.365 114.008 254.952C100.525 251.539 89.3467 246.931 80.472 241.128L95.576 198.632C101.037 201.875 107.181 204.776 114.008 207.336C120.835 209.725 127.917 211.688 135.256 213.224C142.595 214.589 149.848 215.272 157.016 215.272C167.085 215.272 173.827 214.248 177.24 212.2C180.653 209.981 182.36 207.336 182.36 204.264C182.36 201.363 181.251 199.059 179.032 197.352C176.984 195.645 172.632 194.109 165.976 192.744L133.464 186.088C116.909 182.675 104.451 176.616 96.088 167.912C87.896 159.208 83.8 147.944 83.8 134.12C83.8 121.832 87.128 111.165 93.784 102.12C100.44 93.0747 109.827 86.0773 121.944 81.128C134.061 76.008 148.227 73.448 164.44 73.448C177.24 73.448 189.357 75.1547 200.792 78.568C212.227 81.9813 221.528 86.5893 228.696 92.392L213.592 132.328C207.107 127.891 199.597 124.477 191.064 122.088C182.701 119.528 173.4 118.248 163.16 118.248C154.627 118.248 148.227 119.272 143.96 121.32C139.864 123.368 137.816 126.355 137.816 130.28C137.816 132.669 139.011 134.888 141.4 136.936C143.789 138.984 148.397 140.691 155.224 142.056L187.224 148.456C203.437 151.699 215.64 157.672 223.832 166.376C232.195 175.08 236.376 186.259 236.376 199.912C236.376 212.029 233.048 222.611 226.392 231.656C219.736 240.701 210.435 247.699 198.488 252.648C186.541 257.597 172.547 260.072 156.504 260.072Z" fill="white"/>
          </svg>
          <Typography variant="h3" fontWeight="bold" sx={{ color: "#41666A", marginLeft: "10px", letterSpacing: "-0.5px" }}>
            Shopflow
          </Typography>
        </>
      </Box>
        <Divider />
      <List>
      <ButtonBase onClick={handleHomeClick}>
          <ListItem sx={{ marginBottom: '0.5rem', marginTop: "4rem" }}>
            <ListItemIcon><HomeOutlinedIcon /></ListItemIcon>
            <ListItemText 
              primary='Hem'
              primaryTypographyProps={{style: {fontWeight: "bold"}}} 
            />
          </ListItem>
        </ButtonBase>
        <ButtonBase onClick={handleOrdersClick}>
          <ListItem sx={{ marginBottom: '0.5rem', marginTop: "0.5rem" }}>
            <ListItemIcon><AccessTimeIcon /></ListItemIcon>
            <ListItemText 
              primary='Senaste'
              primaryTypographyProps={{style: {fontWeight: "bold"}}} 
            />
          </ListItem>
        </ButtonBase>

        <ButtonBase onClick={handleUpcommingOrdersClick}>
          <ListItem sx={{ marginBottom: '0.5rem', marginTop: "0.5rem" }}>
            <ListItemIcon><FormatListBulletedRoundedIcon /></ListItemIcon>
            <ListItemText 
              primary='Beställningar'
              primaryTypographyProps={{style: {fontWeight: "bold"}}} 
            />
          </ListItem>
        </ButtonBase>
        

        <ButtonBase onClick={handleSearchClick}>
          <ListItem sx={{ marginBottom: '0.5rem', marginTop: "0.5rem" }}>
            <ListItemIcon><SearchIcon /></ListItemIcon>
            <ListItemText 
              primary='Sök'
              primaryTypographyProps={{style: {fontWeight: "bold"}}}
            />
          </ListItem>
        </ButtonBase>

        <ButtonBase onClick={handleNewProductClick}>
          <ListItem sx={{ marginBottom: '0.5rem', marginTop: "0.5rem" }}>
            <ListItemIcon><AddBoxIcon /></ListItemIcon>
            <ListItemText 
              primary='Ny produkt'
              primaryTypographyProps={{style: {fontWeight: "bold"}}}
            />
          </ListItem>
        </ButtonBase>

        <ButtonBase onClick={handleLogoutClick}>
          <ListItem sx={{ marginBottom: '0.5rem', marginTop: "0.5rem" }}>
            <ListItemIcon><LogoutIcon /></ListItemIcon>
            <ListItemText 
              primary='Logga ut'
              primaryTypographyProps={{style: {fontWeight: "bold"}}} 
            />
          </ListItem>
      </ButtonBase>
      </List>
    </Box>
);
return (
<Box>
  <StyledAppBar position="sticky">
    <Toolbar 
      sx={{ 
        display: 'flex',  // Make it a flex container
        alignItems: "center",
        pt: getToolBarPadding(),
      }}
    >
      <IconButton
        color="inherit"
        aria-label="menu"
        onClick={toggleDrawer(true)}
        edge="start"
      >
        <MenuIcon fontSize="large"/>
      </IconButton>
      <Box sx={{ flexGrow: 1 }}></Box> {/* This will push the Typography to the right */}
      <Typography 
        variant="h4" 
        sx={{ 
          fontWeight: "bold", 
          letterSpacing: "-0.5px",
        }}>
        {"Shopflow"}
      </Typography>
    </Toolbar>
  </StyledAppBar>
  <Drawer open={drawerOpen} onClose={toggleDrawer(false)}>
    {list()}
  </Drawer>
  <Box mt={1} pt={2}>
    <Outlet />
  </Box>
</Box>


);
};

export default MobileLayout;