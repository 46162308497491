import { styled } from "@mui/system";
import {
    Typography,
    Container,
    Box,
    StepConnector,
    stepConnectorClasses,
    Stepper,
    Accordion,
  } from "@mui/material";

import Check from "@mui/icons-material/Check";


export const StyledContainer = styled(Container)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  minHeight: "100vh",
  padding: theme.spacing(2),
  backgroundColor: "#FFFFFF", // explicitly setting the background color to white
  // backgroundColor: "#F7F7F7",
}));

export const StyledHeader = styled(Typography)(({ theme }) => ({
  marginBottom: theme.spacing(4),
  textAlign: "center",
}));

export const StyledOrderItem = styled(Box)(({ theme }) => ({
  position: "relative",
  display: "flex",
  flexDirection: "column",
  alignItems: "flex-start",
  padding: theme.spacing(2),
  marginBottom: theme.spacing(4),
  backgroundColor: "#FFF",
  boxShadow: "0px 3px 5px 2px rgba(0, 0, 0, 0.1)",
  borderRadius: "8px",
  width: "90%", // Adjust the width as needed
  maxWidth: "400px", // Set a maximum width if desired
}));

export const StyledOverlay = styled(Box)(({ theme }) => ({
  position: "absolute",
  top: 0,
  bottom: 0,
  left: 0,
  right: 0,
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  color: theme.palette.common.white,
  backgroundColor: "#41666A",
  zIndex: 1,
}));

export const StyledIconWrapper = styled(Box)(({ theme }) => ({
  position: "absolute", // Change to absolute positioning
  top: theme.spacing(1.5), // Adjust as needed
  right: theme.spacing(1), // Adjust as needed
  color: "#DF0025",
  opacity: "0.5",
}));

export const StyledOrderDetail = styled(Typography)(({ theme }) => ({
  marginBottom: theme.spacing(1),
}));

export const CustomConnector = styled(StepConnector)(({ theme }) => ({
  [`&.${stepConnectorClasses.alternativeLabel}`]: {
    top: 10,
    left: "calc(-50% + 16px)",
    right: "calc(50% + 16px)",
  },
  [`&.${stepConnectorClasses.active}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: "#C00020",
    },
  },
  [`&.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: "#C00020",
    },
  },
  [`& .${stepConnectorClasses.line}`]: {
    borderColor:
      theme.palette.mode === "dark" ? theme.palette.grey[800] : "#eaeaf0",
    borderTopWidth: 3,
    borderRadius: 1,
  },
}));


export const CustomStepIconCircle = styled(Box)(({ theme, ownerState }) => ({
    width: 16,
    height: 16,
    backgroundColor: ownerState.active
      ? "transparent"
      : ownerState.completed
      ? "#C00020"
      : theme.palette.grey[400],
    borderColor: ownerState.active ? "#C00020" : "transparent", // Add a border color for the active step
    borderWidth: ownerState.active ? "2px" : "0", // Set border width for the active step
    borderStyle: "solid", // Define the border style
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: "50%",
    "& .MuiSvgIcon-root": {
      color: "#fff",
    },
  }));
  
 export const StyledStepperContainer = styled(Box)(({ theme }) => ({
    display: "flex",
    flexDirection: "column", // Change to column to stack items vertically
    alignItems: "stretch", // Align items to stretch full width
    margin: theme.spacing(2, 0),
    width: "90%", // Adjust the width as needed
  }));
  
 export const StyledStepper = styled(Stepper)(({ theme }) => ({
    flexGrow: 1, // This makes Stepper take up more width
  }));
  
 export const StyledAccordion = styled(Accordion)(({ theme }) => ({
    width: "90%", // Set width to 100%
    boxShadow: "none", // Remove the default boxShadow
    borderRadius: "8px", // Set the border-radius
    "&:before": {
      // Target pseudo element which gives the default border top
      content: "none",
    },
  
    // Add any other styles you need here
  }));
  
 export const CustomStepIconCheck = styled(Check)(({ theme }) => ({
    fontSize: 14,
    color: "#fff",
  }));
  
 export const StyledRow = styled(Box)(({ theme }) => ({
    display: "flex",
    justifyContent: "space-between", // to spread items evenly in the container
    alignItems: "center", // to align items vertically in the middle
    marginBottom: theme.spacing(1), // add some margin-bottom if needed
  }));