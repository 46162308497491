import { styled } from "@mui/system";
import {
  Table,
  Box,
  Typography,
  TableCell as MuiTableCell,
  TableContainer as MuiTableContainer
} from "@mui/material";

export const StyledTable = styled(Table)({ minWidth: 650 });

export const LoadingContainer = styled(Box)({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  height: "100vh",
});

export const ErrorText = styled(Typography)({ color: "red" });

export const StyledTableContainer = styled(MuiTableContainer)({
  borderRadius: 15,
  margin: "10px 10px",
  maxWidth: 900,
  boxShadow:
    "0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12)",
});

export const TableHeaderCell = styled(MuiTableCell)({
  fontWeight: "bold",
  backgroundColor: "#C00020",
  color: "#FFFFFF",
});

export const Root = styled(Box)({
  display: "flex",
  justifyContent: "center",
  alignItems: "flex-start",
});

export const DatePickerContainer = styled(Box)({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
});
