import { useQuery } from "react-query";
import { useContext } from 'react';
import AuthContext from 'context/AuthContext';  // Ensure the path to AuthContext is correct.

const fetchProducts = async (updateToken, logoutUser, retryCount = 0) => {
  const baseUrl = process.env.REACT_APP_BASE_URL
      ? process.env.REACT_APP_BASE_URL
      : "http://localhost:8000/";

  const response = await fetch(`${baseUrl}/shopify-api/products/`, {
    headers: {
      'Content-Type':'application/json',
    },
    credentials: 'include',
  });

  if ((response.status === 401 || response.status === 403) && retryCount < 1) {
    await updateToken();
    return fetchProducts(updateToken, logoutUser, retryCount + 1);
  } else if ((response.status === 401 || response.status === 403) && retryCount >= 1) {
    logoutUser();
    throw new Error('Authentication failed. Logging out.');
  }

  return response.json();
};

const useShopifyProducts = () => {
  const { updateToken, logoutUser } = useContext(AuthContext);
  
  const {
    data: products,
    isLoading,
    isError,
  } = useQuery(["products"], () => fetchProducts(updateToken, logoutUser, 0));

  return { products, isLoading, isError };
  
};

export default useShopifyProducts;
