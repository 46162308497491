import React, { useState, useMemo } from "react";
import { DataGrid, svSE } from "@mui/x-data-grid";
import { Box } from "@mui/system";
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography,
  ListItem,
  ListItemText,
} from "@mui/material";
import { Link } from "react-router-dom";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { useTheme } from "@mui/material/styles";
import { getStatusFromTags } from "utilities/getStatusFromTags";
import OrderDropdown from "pages/Orders/OrdersTable/components/OrdersDropdown";
import { getDeliveryDate } from "utilities/getNoteAttributes";
import { getTotalPrice } from "utilities/getTotalPrice";
import { parseDate } from "utilities/dateUtility.js";

function OrderTable({ orders }) {
  const theme = useTheme();
  const [open, setOpen] = useState(false);
  const [lineItems, setLineItems] = useState([]);
  const [selectedOrderId, setSelectedOrderId] = useState(null);
  const [selectedOrderPrice, setSelectedOrderPrice] = useState(null);
  const [selectedDate, setSelectedDate] = useState(null);
  const [selectedName, setSelectedName] = useState(null);
  const [selectedStatus, setSelectedStatus] = useState(null);
  
  
  const getFormattedDate = (date) => {
    return `${date.getDate()}/${date.getMonth() + 1}/${date.getFullYear()}`;
  };
  const handleOpen = (items, orderId, price, name, date, status) => {
    setLineItems(items || []);
    setSelectedOrderId(orderId);
    setSelectedOrderPrice(price);
    setSelectedDate(getFormattedDate(date));
    setSelectedName(name);
    setSelectedStatus(status)
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setSelectedOrderId(null);
    setSelectedOrderPrice(null);
    setSelectedDate(null);
    setSelectedName(null);
    setSelectedStatus(null);
  };
  const dataGridTheme = createTheme(
    {
      components: {
        MuiDataGrid: {
          styleOverrides: {
            root: {
              fontFamily: theme.typography.fontFamily,
              fontSize: "13px",
              "& *": {  // This will ensure all descendants (no matter how deep) inherit the fontFamily
                fontFamily: "inherit",
                fontSize: "inherit"
              },

              "& .MuiDataGrid-colCellTitle": {
                fontWeight: "bold", // Make column headers bold
                color: theme.palette.textColor.main, // Change color of column headers
              },
              "& .MuiDataGrid-cell": {
                backgroundColor: theme.palette.background.main, // Change cell color
                color: theme.palette.textColor.main, // Change text color of cells
              },
            },
          },
        },
      },
    },
    svSE
    );
    
    const rows = useMemo(
      () =>
      orders
      .map((order) => {
        const deliveryDate = getDeliveryDate(order)
            ? getDeliveryDate(order)
            : "N/A";
          return {
            id: order.id,
            orderId: order.id,
            date: new Date(parseDate(deliveryDate)),
            name: `${order.billing_address?.first_name} ${order.billing_address?.last_name}`,
            status: getStatusFromTags(order.tags),
            statusTags: order.tags,
            lineItems: order.lineItems,
            price: getTotalPrice(order),
          };
        }),
    [orders]
  );

  const columns = [
    {
      field: "date",
      headerName: "Leveransdatum",
      flex: 1,
      valueGetter: (params) => params.value,
      valueFormatter: (params) => {
        const date = params.value;
        return `${date.getDate()}/${date.getMonth() + 1}/${date.getFullYear()}`;
      },
    },
    { field: "name", headerName: "Kund", flex: 1 },
    {
      field: "status",
      headerName: "Status",
      flex: 1.3,
      renderCell: (params) => (
        <OrderDropdown
          id={params.row.orderId}
          ordersTags={params.row.statusTags}
          theme={theme}
          disableSnackbar={true}
        />
      ),
    },
    {
      field: "lineItems",
      headerName: "Order",
      flex: 0.7,
      renderCell: (params) => (
        <Button
          variant="outlined"
          onClick={() =>
            handleOpen(
              params.row.lineItems,
              params.row.orderId,
              params.row.price,
              params.row.name,
              params.row.date,
              params.row.status,
            )
          }
          style={{
            textTransform: "none",
            fontSize: "12px",
            fontWeight: "bold",
          }}
        >
          Se mer
        </Button>
      ),
    },
  ];

  const rowsPerPageOptions = useMemo(() => {
    const options = [5, 10, 20];
    return options.filter((option) => option < orders.length);
  }, [orders]);

  return (
    <React.Fragment>
      <ThemeProvider theme={dataGridTheme}>
        <Box
          sx={{
            height: 340,
            width: "100%",
            "& .MuiDataGrid-root": { border: "none" },
            "& .MuiDataGrid-row": { borderBottom: "none" },
          }}
          >
          <DataGrid
            rows={rows}
            columns={columns}
            pageSize={5}
            disableSelectionOnClick
            disableRowSelectionOnClick
            rowsPerPageOptions={rowsPerPageOptions}
            hideFooterPagination // Remove the toolbar
            initialState={{
              sorting: {
                sortModel: [{ field: "date", sort: "asc" }],
              },
            }}
            />
        </Box>
      </ThemeProvider>
      <Dialog
        open={open}
        onClose={handleClose}
        sx={{ "& .MuiDialog-paper": { minWidth: "400px", padding: "10px" } }}
      >
        <DialogTitle sx={{ fontSize: "20px" }}>
          <strong>Beställning #{selectedOrderId}</strong>
        </DialogTitle>
        <DialogContent>
          <Box sx={{ mb: 2 }}>
            <Typography>
              Kund: <strong>{selectedName}</strong>
            </Typography>

            <Typography>
              Leverans: <strong>{selectedDate}</strong>
            </Typography>

            <Typography>
              Totalt pris: <strong>{selectedOrderPrice} kr</strong>
            </Typography>
          </Box>
          {lineItems.map((item) => (
            <ListItem key={item.id}>
              <ListItemText
                disableTypography={false}
                primary={
                  <Typography sx={{ fontWeight: "bold" }}>
                    {item.name}
                  </Typography>
                }
                secondary={<Typography>{`Antal: ${item.quantity}`}</Typography>}
              />
            </ListItem>
          ))}
          <Box sx={{mt: 2}}>
            <Typography>
              Status: <strong>{selectedStatus}</strong>
            </Typography>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button
            variant="outlined"
            onClick={handleClose}
            style={{
              fontWeight: "bold",
              textTransform: "none",
              margin: "5px",
            }}
          >
            Stäng
          </Button>
          <Button
            variant="contained"
            component={Link}
            to={`/catering/${selectedOrderId}/edit`}
            style={{
              fontWeight: "bold",
              textTransform: "none",
              margin: "5px",
            }}
          >
            Se hela beställning
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}

export default React.memo(OrderTable);
