import { useQuery } from 'react-query';
import { useContext } from 'react'; // Import useContext from React
import AuthContext from 'context/AuthContext';  // Make sure to provide the correct path to your AuthContext

const fetchUpcomingOrders = async (updateToken, logoutUser, retryCount = 0) => {
  const baseUrl = process.env.REACT_APP_BASE_URL ? process.env.REACT_APP_BASE_URL : 'http://localhost:8000/';

  const response = await fetch(`${baseUrl}/shopify-api/orders/upcoming/`, {
    headers: {
      'Content-Type':'application/json',
    },
    credentials: 'include',
  });

  if ((response.status === 401 || response.status === 403) && retryCount < 1) {
    await updateToken();
    return fetchUpcomingOrders(updateToken, logoutUser, retryCount + 1);
  } else if ((response.status === 401 || response.status === 403) && retryCount >= 1) {
    logoutUser();
    throw new Error('Authentication failed. Logging out.');
  }

  if (!response.ok) {
    throw new Error(`HTTP error! status: ${response.status}`);
  }

  var { upcomingOrders, errorOrders } = await response.json();

  const modifyOrders = (orders) =>
    orders.map((order) => {
      // Extract refund line item IDs
      const refundedLineItemIds = order.refunds?.flatMap(refund => refund.refund_line_items.map(item => item.line_item_id)) || [];
      
      // Filter out line items that are refunded
      const lineItems = order.line_items
        .filter(item => !refundedLineItemIds.includes(item.id))

      return {
        ...order,
        lineItems,
      };
    });

  return {
    upcomingOrders: modifyOrders(upcomingOrders),
    errorOrders: modifyOrders(errorOrders),
  };
};

export const GetUpcomingOrders = () => {
  const { updateToken, logoutUser } = useContext(AuthContext);
  
  const { data, isLoading, isError, refetch } = useQuery(
    ['upcomingOrders'],
    () => fetchUpcomingOrders(updateToken, logoutUser),
    {
      refetchInterval: 60000,
      staleTime: 20000,
    }
  );

  const { upcomingOrders, errorOrders } = data || {};

  return { upcomingOrders, errorOrders, isLoading, isError, refetch };
};

export default GetUpcomingOrders;
