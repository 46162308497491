import React from "react";
import {
  TableCell,
  TableRow,
  Checkbox,
  Chip,
  Popover,
  Typography,
  Box,
} from "@mui/material";
import { Link } from "react-router-dom";
import DescriptionIcon from "@mui/icons-material/Description"; // Assuming you're using this icon, replace with correct one if not.
import CircleNotificationsIcon from "@mui/icons-material/Notifications"; // Assuming you're using this icon, replace with correct one if not.
import EyeIcon from "@mui/icons-material/RemoveRedEye"; // Assuming you're using this icon, replace with correct one if not.
import AccessTimeIcon from '@mui/icons-material/AccessTime';

// UTILS AND THEME
import { colorForFinancialStatus } from "utilities/getStatusColor";
import { getDeliveryDate } from "utilities/getNoteAttributes";
import { iconForFinancialStatus } from "utilities/getStatusFromTags";
import translations from "translations";
import { getTotalPrice } from "utilities/getTotalPrice";
import usePopover from "utilities/popoverUtility";

function OrdersTableRow({
  orders,
  theme,
  handleClick,
  isSelected,
  getVendorsLabel,
  updateOrder,
  getAddressUrl,
  OrderDropdown,
}) {
    function getDaysToDelivery(order) {
      const formatDate = (date) => {
        const day = String(date.getDate()).padStart(2, '0');
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const year = date.getFullYear();
        return `${day}/${month}/${year}`;
      };
    
      const todayRaw = new Date();
      const today = formatDate(todayRaw);
    
      const tomorrowRaw = new Date(todayRaw);
      tomorrowRaw.setDate(todayRaw.getDate() + 1);
      const tomorrow = formatDate(tomorrowRaw);
    
      const dayAfterTomorrowRaw = new Date(todayRaw);
      dayAfterTomorrowRaw.setDate(todayRaw.getDate() + 2);
      const dayAfterTomorrow = formatDate(dayAfterTomorrowRaw);
    
      const orderDeliveryDate = getDeliveryDate(order);
    
      if (orderDeliveryDate === today) {
        return "Idag";
      } else if (orderDeliveryDate === tomorrow) {
        return "Imorgon";
      } else if (orderDeliveryDate === dayAfterTomorrow) {
        return "I övermorgon";
      } else {
        const deliveryDateParts = orderDeliveryDate.split('/').map(Number); // [day, month, year]
        const deliveryDateRaw = new Date(deliveryDateParts[2], deliveryDateParts[1] - 1, deliveryDateParts[0]);
        const diffDays = Math.ceil((deliveryDateRaw - todayRaw) / (1000 * 60 * 60 * 24));
        return `om ${diffDays} dagar`;
      }
    }

    const {
      anchorEl: datePopoverAnchorEl,
      openPopover: handleDatePopoverOpen,
      closePopover: handleDatePopoverClose,
      isOpen: isDatePopoverOpen
    } = usePopover();
    const {
      anchorEl: foodPopoverAnchorEl,
      openPopover: handleFoodPopoverOpen,
      closePopover: handleFoodPopoverClose,
      isOpen: isFoodPopoverOpen
    } = usePopover();
  
    const {
      anchorEl: notePopoverAnchorEl,
      openPopover: handleNotePopoverOpen,
      closePopover: handleNotePopoverClose,
      isOpen: isNotePopoverOpen
    } = usePopover();
  

    return (
      <TableRow
        key={orders._id}
        sx={{
          "&:nth-of-type(odd)": {
            backgroundColor: theme.palette.background.main,
          },
        }}
      >
        {/* ----------------
             Checkbox
             ---------------- */}
        <TableCell>
          <Checkbox
            color="primary"
            checked={isSelected(orders.id)}
            onChange={(event) => handleClick(event, orders.id)}
          />
        </TableCell>

        {/* ----------------
             ID - hide until further re-consideration.
             ---------------- */}
        {/* <TableCell sx={{ fontSize: 14,  color: theme.palette.textColor.main}}>{orders.id}</TableCell> */}

        {/* ----------------
             Namn
             ---------------- */}
        <TableCell
          sx={{ fontSize: 14, color: theme.palette.textColor.main }} //, cursor: "pointer" }}
          //onClick={() => handleEmailDialogOpen(orders.customer?.email)}
        >
          {orders.billing_address?.first_name}{" "}
          {orders.billing_address?.last_name}
        </TableCell>
        {/* ----------------
             Datum
             ---------------- */}
        <TableCell sx={{ fontSize: 14, color: theme.palette.textColor.main }}>
        {getDaysToDelivery(orders) && (
        <React.Fragment>
          <Chip
            size="medium"
            label={getDaysToDelivery(orders)}
            icon={<AccessTimeIcon style={{ color: "#6F79C3" }} />}
            sx={{ minWidth: "80px", mr: "5px" }}
            style={{ backgroundColor: "#E2E4F3" }}
            onClick={(event) =>
              handleDatePopoverOpen(
                event,
              )
            }
          />
          <Popover
              id="delivery-date-popover"
              open={isDatePopoverOpen}
              anchorEl={datePopoverAnchorEl}
              onClose={handleDatePopoverClose}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
              PaperProps={{
                sx: {
                  boxShadow: "0px 2px 5px rgb(220, 220, 220)", // Customize the box shadow here
                },
              }}
          >
            <Box sx={{ p: 2 }}>
            {getDeliveryDate(orders)}
            </Box>
          </Popover>
        </React.Fragment>
      )}
        </TableCell>

        {/* ----------------
         Pris
         ---------------- */}
        <TableCell sx={{ fontSize: 14, color: theme.palette.textColor.main }}>
          {getTotalPrice(orders)} SEK
        </TableCell>

        {/* ---------------
         Finansiell status
         --------------- */}
        <TableCell align="center">
          <Chip
            size="medium"
            label={translations.financialStatus[orders.financial_status]}
            icon={iconForFinancialStatus(orders.financial_status)}
            sx={{
              minWidth: "100px",
            }}
            style={{
              backgroundColor: colorForFinancialStatus(orders.financial_status),
            }}
          />
        </TableCell>
        {/* ----------------
             Adress
             ---------------- */}

        {/* <TableCell sx={{ fontSize: 14}}>
               <Box display="flex" alignItems="center">
               <Typography
                 component="span"
                 onClick={(event) => handleAddressPopoverOpen(event, orders.billing_address?.address1 + " " + orders.billing_address?.city)}
                 sx={{
                   textDecoration: "underline",
                   cursor: "pointer",
                   color: theme.palette.textColor.main
                 }}
                 >
                 {orders.billing_address?.city}
               </Typography>
               </Box>
               <Popover
                 open={Boolean(addressPopoverAnchorEl)}
                 anchorEl={addressPopoverAnchorEl}
                 onClose={handleAddressPopoverClose}
                 anchorOrigin={{
                   vertical: "bottom",
                   horizontal: "left",
                 }}
                 transformOrigin={{
                   vertical: "top",
                   horizontal: "left",
                 }}
                 PaperProps={{
                   sx: {
                     boxShadow: "0px 2px 5px rgba(220, 220, 220, 0.1)", // Customize the box shadow here
                   },
                 }}
               >
           <Box sx={{ p: 2, bgcolor: 'background.paper', borderRadius: 1 }}> 
             <Typography sx={{ mt: 1, fontWeight: "bold", color: theme.palette.textColor.main }}>Address:</Typography>
             <Typography sx={{ mt: 1, color: 'text.secondary' }}>{selectedAddress}</Typography>
             <Button
               variant="contained"
               sx={{ mt: 2 }}
               onClick={() => {
                 window.open(getAddressUrl(selectedAddress), '_blank');
               }}
             >
               Öppna in Google Maps
             </Button>

           </Box>
       </Popover>
             </TableCell> */}

        {/* ----------------
             Beställning
             ---------------- */}
        <TableCell>
          <Chip
            onClick={(event) =>
              handleFoodPopoverOpen(
                event,
              )
            }
            size="medium"
            variant={"filled"}
            label={getVendorsLabel(orders)}
            icon={<DescriptionIcon />}
            sx={{
              pl: "5px",
            }}
          />

          <Popover
            open={isFoodPopoverOpen}
            anchorEl={foodPopoverAnchorEl}
            onClose={handleFoodPopoverClose}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "left",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "left",
            }}
            PaperProps={{
              sx: {
                boxShadow: "0px 2px 5px rgb(220, 220, 220)", // Customize the box shadow here
              },
            }}
          >
            <Box sx={{ p: 2 }}>
              {orders?.lineItems?.map((item, index) => (
                <Typography key={index} sx={{ pb: "3px", pt: "3px" }}>
                  {item.title} - {item.quantity}st | Tillagas av:{" "}
                  <b>{item.vendor}</b>
                </Typography>
              ))}
            </Box>
          </Popover>
        </TableCell>

        {/* Payment status hidden for now */}
        {/* <TableCell sx={{ fontSize: 14,  color: theme.palette.textColor.main, width: "12.5%" }}>{getFriendlyPaymentStatus(orders.financial_status)}</TableCell> */}

        {/* 
             ------------
             Anteckning - logik för att visa en anteckning om den finns ( !== '') finns nedan
             ------------
             */}

        <TableCell>
          <Typography
            component="span"
            onClick={(event) => handleNotePopoverOpen(event)}
            sx={{
              textDecoration: orders.note !== "" ? "underline" : "none",
              cursor: "pointer",
              color: theme.palette.textColor.main,
            }}
          >
            <Chip
              onClick={
                orders.note !== "" && orders.note != null
                  ? (event) => handleNotePopoverOpen(event, orders.note)
                  : undefined
              }
              size="medium"
              variant={
                orders.note !== "" && orders.note != null
                  ? "filled"
                  : "outlined"
              }
              label={
                orders.note !== "" && orders.note != null
                  ? "Anteckning"
                  : "Ingen anteckning"
              }
              icon={
                orders.note !== "" && orders.note != null ? (
                  <CircleNotificationsIcon />
                ) : null
              }
              sx={{
                pl: orders.note !== "" && orders.note != null ? "5px" : "0px",
                pr: orders.note !== "" && orders.note != null ? "5px" : "0px",
              }}
            />
          </Typography>
          <Popover
            open={isNotePopoverOpen && orders.note !== ""}
            anchorEl={notePopoverAnchorEl}
            onClose={handleNotePopoverClose}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "left",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "left",
            }}
            PaperProps={{
              sx: {
                boxShadow: "0px 2px 5px rgb(220, 220, 220)", // Customize the box shadow here
                maxWidth: "280px",
              },
            }}
          >
            <Box sx={{ p: 2 }}>
              <Typography>{orders.note}</Typography>
            </Box>
          </Popover>
        </TableCell>
        {/* ----------------
             Status
             ---------------- */}
        <TableCell sx={{ fontSize: 14 }}>
          {orders && (
            <OrderDropdown
              ordersTags={orders.tags}
              updateOrder={updateOrder}
              id={orders.id}
              theme={theme}
            />
          )}
        </TableCell>
        {/* ----------------
             Edit
             ---------------- */}
        <TableCell align="center">
          <Box
            component={Link}
            to={`/catering/${orders.id}/edit`}
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              width: "32px", // adjust as needed
              height: "32px", // adjust as needed
              borderRadius: "16px", // half of width/height for a circle
              backgroundColor: "#E6E6E6",
              margin: "0 0 0 15px", // top, right, bottom, left. 'auto' on the left pushes the box to the right
              color: "#616161",
            }}
          >
            <EyeIcon color="#616161" />
          </Box>
        </TableCell>
      </TableRow>
    );
  }

  export default OrdersTableRow;
